import {
  DatePicker,
  FormMargin,
  Input,
  MUIDropdown,
  Table,
  TruncateText,
} from "Components";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import {
  withMedReportsPage,
  withMedReportsPageHistory,
  withMedReportsSort,
} from "../Recoil/atom";
import { useMedReportHistoryTable } from "./MedReportTableHistory.hooks";
import { useAmbulances, useGetPatients, useHospitals } from "Hooks/useLookup";
import { useFilter } from "Hooks/useFilter";
import { MedReportFilter } from "Models/Api/MedReport";
import { MedReportPrintButton } from "../MedReportPrintButton/MedReportPrintButton";
import dayjs from "dayjs";
import { FilterSection } from "Components/Table/FilterSection/FilterSection";
import { PrintSection } from "Components/Table/PrintSection/PrintSection";

import "./MedReportTableHistory.scss";

export const MedReportTableHistory = () => {
  const [filter, setFilter, filterChanged, clearFilter] = useFilter({
    patientId: {},
  } as MedReportFilter);

  const [patientSearch, setPatientSearch] = useState<string>("");

  const [pageParams, setPageParams] = useRecoilState(withMedReportsPageHistory);
  const [sortParams, setSortParams] = useRecoilState(withMedReportsSort);
  const [patients] = useGetPatients(patientSearch);
  const [hospitals] = useHospitals();
  const [ambulance] = useAmbulances();

  const [medReportsTable, fetchData, isLoaded] = useMedReportHistoryTable(
    pageParams,
    sortParams,
    filter,
  );

  useEffect(() => {
    if (!filter.fromDateCreated) return;

    setFilter({
      toDateCreated: dayjs(filter.fromDateCreated).add(1, "day").toDate(),
    });
  }, [filter.fromDateCreated]);

  useEffect(() => {
    if (!filter.ambulanceId && !filter.hospitalId) return;

    setFilter({ fromDateCreated: dayjs().toDate() });
  }, [filter.ambulanceId, filter.hospitalId]);

  return (
    <React.Fragment>
      <Table
        title="Popis pacijenata"
        data={medReportsTable}
        columns={[
          { name: "patient", label: "Pacijent" },
          { name: "dateCreated", label: "Datum pregleda" },
          { name: "date", label: "Datum" },
          { name: "hospital", label: "Bolnica" },
          {
            element: (x) => {
              return (
                <TruncateText text={x?.examinations ?? ""} maxLength={15} />
              );
            },
            label: "Pregled",
          },
          { name: "doctor", label: "Radiolog" },
        ]}
        fill
        isLoaded={isLoaded}
        pageState={withMedReportsPageHistory}
        showActions={["PRINT"]}
        onRefresh={fetchData}
        filterChanged={filterChanged}
        onFilterClear={clearFilter}
      >
        <FilterSection>
          <FormMargin>
            <Input
              placeholder="Pacijent"
              value={filter.patient}
              onChange={(x) => setFilter({ patient: x.target.value })}
            />
          </FormMargin>

          <FormMargin>
            <MUIDropdown
              type="editable"
              options={ambulance.items}
              value={filter.ambulanceId}
              onSelect={(x) => setFilter({ ambulanceId: x })}
              label="Ambulanta"
            />
          </FormMargin>

          <FormMargin>
            <MUIDropdown
              type="editable"
              options={hospitals.items}
              value={filter.hospitalId}
              onSelect={(x) => setFilter({ hospitalId: x })}
              label="Bolnice"
            />
          </FormMargin>

          <FormMargin>
            <DatePicker
              label="Datum pregleda"
              value={filter.fromDateCreated}
              onChange={(x) =>
                setFilter({ fromDateCreated: dayjs(x).toDate() })}
              maxDate={filter.toDateCreated}
            />
          </FormMargin>
        </FilterSection>

        <PrintSection>
          <FormMargin>
            <MUIDropdown
              type="editable"
              options={ambulance.items}
              value={filter.ambulanceId}
              onSelect={(x) => setFilter({ ambulanceId: x })}
              label="Ambulanta"
            />
          </FormMargin>

          <FormMargin>
            <MUIDropdown
              type="editable"
              options={hospitals.items}
              value={filter.hospitalId}
              onSelect={(x) => setFilter({ hospitalId: x })}
              label="Bolnice"
            />
          </FormMargin>

          <FormMargin>
            <DatePicker
              label="Datum pregleda"
              value={filter.fromDateCreated}
              onChange={(x) =>
                setFilter({ fromDateCreated: dayjs(x).toDate() })}
              maxDate={dayjs().toDate()}
            />
          </FormMargin>

          <FormMargin>
            <MedReportPrintButton
              form="HistoryMedReport"
              ambulanceId={filter.ambulanceId}
              hospitalId={filter.hospitalId}
              fromDateCreated={filter.fromDateCreated}
            />
          </FormMargin>
        </PrintSection>
      </Table>
    </React.Fragment>
  );
};
