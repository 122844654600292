import { injectable } from "inversify";
import { ExaminationPost, ExaminationPut } from "Models/Api/Examination";
import { Examination } from "Models/Api/Examination/Examination";
import { ExaminationBlock } from "Models/Api/Examination/ExaminationBlock";
import { ExaminationFilter } from "Models/Api/Examination/ExaminationFilter";
import { PageParam, Response, PageList } from "Models/Common";
import { SortParam } from "Models/Common/SortParam";
import { IExaminationService } from "Services.Common/api/IExaminationService";
import { BaseService } from "./BaseService";

@injectable()
export class ExaminationService extends BaseService<Examination> implements IExaminationService {
    baseUrl = "/api/examination"

    async getAsync(pageParam: PageParam, sortParam?: SortParam, filter?: ExaminationFilter): Promise<Response<PageList<Examination>>> {
        return this.fetchAsync(pageParam, sortParam, filter);
    }

    async getIdAsync(id: string): Promise<Response<Examination>> {
        return this.fetchByIdAsync(id);
    }

    async blockAsync(examinationBlock: ExaminationBlock): Promise<Response<Examination>> {
        return this.postAndReturnAsync<ExaminationBlock, Examination>(examinationBlock, "/block");
    }

    async createAsync(form: ExaminationPost): Promise<Response<Examination>> {
        return this.postAndReturnAsync(form);
    }

    async updateAsync(exeminationPut: ExaminationPut): Promise<Response<Examination>> {
        return this.putAsync(exeminationPut);
    }
}