import React, { useEffect, useState } from "react";
import Chat, { Bubble, MessageProps, useMessages } from "@chatui/core";
import { v4 } from "uuid";
import { useAssistantChat, useClearAssigned } from "./AssistantChat.hook";
import {
  withGreedeAlgorithmMessage,
  withHospitalPriorityMessage,
} from "../Recoil/atom";
import {
  addResponseMessage,
  addUserMessage,
  deleteMessages,
  renderCustomComponent,
  setBadgeCount,
  setQuickButtons,
  toggleMsgLoader,
  Widget,
} from "react-chat-widget";

import Logo from "../../../assets/logo.png";
import { InitialMessage } from "../InitialMessage/InitialMessage";
import { useRecoilValue } from "recoil";

import "react-chat-widget/lib/styles.css";
import "./AssistantChat.scss";

type Props = {
  onMessageReceived?: (message: string) => void;
};

export const AssistantChat = ({ onMessageReceived }: Props) => {
  const [sessionId, setSessionId] = useState(v4());
  const [response, send] = useAssistantChat();
  const [isAssigmentClear, clearAssigment] = useClearAssigned();
  const greedeAlgorithmMessage = useRecoilValue(withGreedeAlgorithmMessage);
  const hospitalPriorityMessage = useRecoilValue(withHospitalPriorityMessage);

  useEffect(() => {
    deleteMessages(100);
    renderCustomComponent(InitialMessage, {
      onButtonClicked: onInitialButtonClicked,
    });
    setQuickButtons([{ label: "Obriši poruke", value: "DeleteMessages" }, {
      label: "Obriši dodjeljene radiologe",
      value: "DeleteAssignedDoctors",
    }]);
  }, []);

  useEffect(() => {
    if (response) {
      addResponseMessage(response?.model ?? "");
      onMessageReceived?.(response?.model ?? "");
      toggleMsgLoader();
    }
  }, [response]);

  const onInitialButtonClicked = (value: string) => {
    if (value === "greede") {
      send({
        sessionId,
        message:
          "Dodjeli pacijente radiolozima tako da ne mjenjas nikakve opcije.",
      });
      addUserMessage(greedeAlgorithmMessage);
      toggleMsgLoader();
    } else if (value === "hospitalPriority") {
      send({
        sessionId,
        message:
          "Dodjeli pacijente radiolozima tako da prioritiziras podjelu po bolnicama.",
      });
      addUserMessage(hospitalPriorityMessage);
      toggleMsgLoader();
    }
  };

  const handleNewUserMessage = (message: string) => {
    send({
      sessionId,
      message,
    });
    toggleMsgLoader();
  };

  const handleQuickButtonClicked = (value: string) => {
    if (value === "DeleteMessages") {
      deleteMessages(100);
      renderCustomComponent(InitialMessage, {
        onButtonClicked: onInitialButtonClicked,
      });
      setSessionId(v4());
    } else if (value === "DeleteAssignedDoctors") {
      onMessageReceived?.("clearAssigment");
      clearAssigment();
    }
  };

  //TODO: Send button is on chinese, change it to "Pošalji" later
  return (
    <React.Fragment>
      <div className="assistant-chat-container">
        <Widget
          handleNewUserMessage={handleNewUserMessage}
          handleQuickButtonClicked={handleQuickButtonClicked}
          launcherOpenImg={Logo}
          title="Admin. asistent"
          subtitle=""
          senderPlaceHolder="Unesite poruku..."
          emojis={false}
          showBadge={false}
        />
      </div>
    </React.Fragment>
  );
};
