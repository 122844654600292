import { injectable } from "inversify";
import { BaseService } from "./BaseService";
import { IProfilePictureService } from "Services.Common/api";
import { ProfilePictureFileUpload } from "Models/Api/ProfilePicture/ProfilePictureFileUpload";
import { Response } from "Models/Common";
import { container } from "inversify.config";
import { IResponseFactory } from "Services.Common/factory";
import { TYPES } from "inversify.types";
import axios from "axios";

@injectable()
export class ProfilePictureService implements IProfilePictureService {
    baseUrl = "/api/profileImage";

    async uploadProfilePicture(model: ProfilePictureFileUpload): Promise<Response<boolean>> {
        var responseService = container.get<IResponseFactory>(TYPES.IResponseFactory);
        var response = responseService.create<boolean>();

        if (model.file === undefined || model.file.size === 0) {
            response.model = false;

            return response;
        }

        const formData = new FormData();
        formData.append('userId', model.userId);
        formData.append('file', model.file);

        console.log(formData, model, "formData model");

        return await axios.post<Response<boolean>>(this.baseUrl, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }).then(x => x.data);
    }

    async deleteAsync(id: string): Promise<Response<boolean>> {
        return await axios.delete<Response<boolean>>(`${this.baseUrl}/${id}`).then(x => x.data);
    }
}