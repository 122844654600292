import { useEffect, useState } from "react";
import { RecoilState, useRecoilState } from "recoil";

export const useFilter = <T>(model: T) => {
const [filter, setFilterState] = useState(model);
    const [initialFilter] = useState(model);

    const [filterChanged, setFilterChanged] = useState(false);

    useEffect(() => {
        if (JSON.stringify(filter) === JSON.stringify(initialFilter)) {
            setFilterChanged(false);
        }
        else {
            setFilterChanged(true);
        }
    }, [filter]);

    const setFilter = (value: Partial<T>) => {
        setFilterState(val => ({ ...val, ...value }))
    }

    const clearFilter = () => {
        setFilterState(initialFilter);
    }

    return [filter, setFilter, filterChanged, clearFilter] as const;
}