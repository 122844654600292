import axios from "axios";
import { injectable } from "inversify";
import { Patient, PatientPost } from "Models/Api/Patient";
import { PatientFilter } from "Models/Api/Patient/PatientFilter";
import { PageParam, Response, PageList } from "Models/Common";
import { SortParam } from "Models/Common/SortParam";
import { IPatientService } from "Services.Common/api/IPatientService";
import { BaseService } from "./BaseService";

@injectable()
export class PatientService extends BaseService<Patient> implements IPatientService {
    baseUrl = "/api/patient";

    createAsync(userPost: PatientPost) {
        return this.postAsync<PatientPost>(userPost)
    }

    getAsync(pageParam: PageParam, sortParam: SortParam, filter: PatientFilter): Promise<Response<PageList<Patient>>> {
        return this.fetchAsync(pageParam, sortParam, filter);
    }

    updateAsync(user: Patient) {
        return this.putAsync<Patient>(user);
    }

    getIdAsync(id: string): Promise<Response<Patient>> {
        return this.fetchByIdAsync(id);
    }
}