import React, { useState, useEffect } from "react";
import { Breadcrumbs as BCumbs } from "@mui/material";
import { useBreadcrumbs } from "./Breadcrumbs.hook";
import { Link, NavLink } from "react-router-dom";

import "./Breadcrumbs.scss";

export const Breadcrumbs = () => {
  const matches = useBreadcrumbs();

  return (
    <React.Fragment>
      <div className="breadcrumbs-container">
        <BCumbs separator="/" aria-label="breadcrumb">
          {matches.map((x, i) => (
            <Link
              key={i}
              to={x.to}
              className={i + 1 === matches.length ? "active" : undefined}
            >
              {x.crumb}
            </Link>
          ))}
        </BCumbs>
      </div>
    </React.Fragment>
  );
};
