import { Admin } from "Models/Api/Admin";
import { Engineer } from "Models/Api/Engineer";
import { User } from "Models/Api/User";
import { atom } from "recoil";

export const withUser = atom({
    key: 'withUser',
    default: {
        firstName: "",
        lastName: "",
        email: ""
    } as User
});

export const withAdmin = atom({
    key: 'withAdmin',
    default: {
        firstName: "",
        lastName: "",
        email: ""
    } as Admin
})

export const withEngineer = atom({
    key: 'withEngineer',
    default: {
        firstName: "",
        lastName: "",
        email: "",
        hospitalId: "",
    } as Engineer
})

export const withIsDoctor = atom({
    key: "withIsDoctor",
    default: false
})