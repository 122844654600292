import "reflect-metadata";

import axios from "axios";
import { InitialLoad } from "Components";
import { Provider } from "inversify-react";
import { container } from "inversify.config";
import React, { ReactNode } from "react";
import { ToastContainer } from "react-toastify";
import { RecoilRoot } from "recoil";
import { v4 } from "uuid";
import RoutesConfig from "RoutesConfig";
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  useNavigate,
} from "react-router-dom";
import { useConfig } from "Hooks/useConfig";

import "App.scss";
import "react-toastify/dist/ReactToastify.css";
import { GuideManager } from "Features/Guides";

const App = () => {
  const config = useConfig();

  axios.defaults.baseURL = config.apiURL;

  let token = `Bearer ${localStorage.getItem("token") ?? ""}`;

  axios.defaults.headers.common = {
    Authorization: token,
  };

  // console.log(config.stage);
  // console.log(process.env.REACT_APP_ENVIRONMENT);

  axios.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function (error) {
      if (error.response?.status === 403) {
        localStorage.removeItem("token");
        window.location.replace("/login");
      }
      return Promise.reject(error);
    }
  );

  const routes = createRoutesFromElements(RoutesConfig());

  const router = createBrowserRouter(routes);

  return (
    <React.Fragment>
      <RecoilRoot>
        <InitialLoad />
        <Provider container={container}>
          <RouterProvider router={router} />
        </Provider>
        {/* <GuideManager /> */}
      </RecoilRoot>
      <ToastContainer />
    </React.Fragment>
  );
};

export default App;
