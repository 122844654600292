import { usePost } from "Hooks/usePost";
import { container } from "inversify.config";
import { TYPES } from "inversify.types";
import { MedReportFilePost } from "Models/Api/MedReport/MedReportFilePost";
import { filesMessage } from "Models/Common/NotificationMessages";
import { IFileStoreageService } from "Services.Common/api";

export const usePostMedReportFile = (form: MedReportFilePost) => {
    let fileStoreageService = container.get<IFileStoreageService>(TYPES.IFileStoreageService);

    return usePost(() => fileStoreageService.uploadMedReportFileAsync(form), filesMessage);
}