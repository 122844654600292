import { Dev } from "Components";
import { Layout } from "Components/Layout/Layout";
import React, { lazy, Suspense } from "react";
import { Navigate, Route } from "react-router-dom";
import { MedReportAssignUser } from "Views/MedReportAssignUser/MedReportAssignUser";

const Root = lazy(() => import("Views/Root/Root"));
const NotFound = lazy(() => import("Views/NotFound/NotFound"));
const Login = lazy(() => import("Views/Login/Login"));
const Register = lazy(() => import("Views/Register/Register"));
const Admins = lazy(() => import("Views/Admins/Admins"));
const AdminRegister = lazy(() => import("Views/AdminRegister/AdminRegister"));
const AdminEdit = lazy(() => import("Views/AdminEdit/AdminEdit"));
const Engineers = lazy(() => import("Views/Engineers/Engineers"));
const EngineerEdit = lazy(() => import("Views/EngineerEdit/EngineerEdit"));
const Settings = lazy(() => import("Views/Settings/Settings"));
const EngineerRegister = lazy(
  () => import("Views/EngineerRegister/EngineerRegister"),
);
const MedReports = lazy(() => import("Views/MedReports/MedReports"));
const MedReportUnassigned = lazy(() =>
  import("Views/MedReportUnassigned/MedReportUnassigned")
);
const MedReportHistory = lazy(
  () => import("Views/MedReportHistory/MedReportHistory"),
);
const MedReportAdd = lazy(() => import("Views/MedReportAdd/MedReportAdd"));
const MedReportEngineerAdd = lazy(
  () => import("Views/MedReportEngineerAdd/MedReportEngineerAdd"),
);
const MedReportsPrint = lazy(
  () => import("Views/MedReportsPrint/MedReportsPrint"),
);
const MedReportPrint = lazy(
  () => import("Views/MedReportPrint/MedReportPrint"),
);
const EngineerListPrint = lazy(
  () => import("Views/EngineerListPrint/EngineerListPrint"),
);
const EngineerMedReportPrint = lazy(
  () => import("Views/EngineerMedReportPrint/EngineerMedReportPrint"),
);
const MedReportEdit = lazy(() => import("Views/MedReportEdit/MedReportEdit"));
const MedReportEngineerEdit = lazy(
  () => import("Views/MedReportEngineerEdit/MedReportEngineerEdit"),
);
const MedReportFinish = lazy(
  () => import("Views/MedReportFinish/MedReportFinish"),
);
const MedReportView = lazy(() => import("Views/MedReportView/MedReportView"));
const MedReportAttachments = lazy(
  () => import("Views/MedReportAttachments/MedReportAttachments"),
);
const Users = lazy(() => import("Views/Users/Users"));
const UserRegister = lazy(() => import("Views/UserRegister/UserRegister"));
const UserProfileEdit = lazy(
  () => import("Views/UserProfileEdit/UserProfileEdit"),
);
const UserEdit = lazy(() => import("Views/UserEdit/UserEdit"));
const UserBlock = lazy(() => import("Views/UserBlock/UserBlock"));
const Hospitals = lazy(() => import("Views/Hospitals/Hospitals"));
const HospitalAdd = lazy(() => import("Views/HospitalAdd/HospitalAdd"));
const HospitalEdit = lazy(() => import("Views/HospitalEdit/HospitalEdit"));
const Examinations = lazy(() => import("Views/Examinations/Examinations"));
const ExaminationAdd = lazy(
  () => import("Views/ExaminationAdd/ExaminationAdd"),
);
const ExaminationEdit = lazy(
  () => import("Views/ExaminationEdit/ExaminationEdit"),
);
const Archive = lazy(() => import("Views/Archive/Archive"));
const Test = lazy(() => import("Views/Test/Test"));
const Messages = lazy(() => import("Views/Messages/Messages"));
const MessageAdd = lazy(() => import("Views/MessageAdd/MessageAdd"));
const MalfunctionReportAdd = lazy(
  () => import("Views/MalfunctionReportAdd/MalfunctionReportAdd"),
);

const RoutesConfig = (): React.ReactNode => {
  return (
    <React.Fragment>
      <Route
        path="/"
        element={
          <Suspense>
            <Root />
          </Suspense>
        }
      />

      <Route
        path="register"
        element={
          <Suspense>
            <Register />
          </Suspense>
        }
      />

      <Route
        path="login"
        element={
          <Suspense>
            <Login />
          </Suspense>
        }
      />

      <Route element={<Layout />}>
        // Users
        <Route
          path="users"
          handle={{
            crumb: "Radiolozi",
          }}
        >
          <Route
            index
            element={
              <Suspense>
                <Users />
              </Suspense>
            }
          />

          <Route
            path="register"
            element={
              <Suspense>
                <UserRegister />
              </Suspense>
            }
            handle={{
              crumb: "Registriraj",
            }}
          />

          <Route
            path="edit/:id"
            element={
              <Suspense>
                <UserEdit />
              </Suspense>
            }
            handle={{
              crumb: "Uredi",
            }}
          />

          <Route
            path="block/:id"
            element={
              <Suspense>
                <UserBlock />
              </Suspense>
            }
            handle={{
              crumb: "Dostupnost",
            }}
          />
        </Route>
        // Profile
        <Route
          path="profile"
          element={
            <Suspense>
              <UserProfileEdit />
            </Suspense>
          }
          handle={{
            crumb: "Profil",
          }}
        />
        // Hospitals
        <Route
          path="hospitals"
          handle={{
            crumb: "Bolnice",
          }}
        >
          <Route
            index
            element={
              <Suspense>
                <Hospitals />
              </Suspense>
            }
          />

          <Route
            path="add"
            element={
              <Suspense>
                <HospitalAdd />
              </Suspense>
            }
            handle={{
              crumb: "Dodaj",
            }}
          />

          <Route
            path="edit/:id"
            element={
              <Suspense>
                <HospitalEdit />
              </Suspense>
            }
            handle={{
              crumb: "Uredi",
            }}
          />
        </Route>
        // Examinations
        <Route
          path="examinations"
          handle={{
            crumb: "Pregledi",
          }}
        >
          <Route
            index
            element={
              <Suspense>
                <Examinations />
              </Suspense>
            }
          />

          <Route
            path="add"
            element={
              <Suspense>
                <ExaminationAdd />
              </Suspense>
            }
            handle={{
              crumb: "Dodaj",
            }}
          />

          <Route
            path="edit/:id"
            element={
              <Suspense>
                <ExaminationEdit />
              </Suspense>
            }
            handle={{
              crumb: "Uredi",
            }}
          />
        </Route>
        // Admins
        <Route
          path="admins"
          handle={{
            crumb: "Administratori",
          }}
        >
          <Route
            index
            element={
              <Suspense>
                <Admins />
              </Suspense>
            }
          />

          <Route
            path="register"
            element={
              <Suspense>
                <AdminRegister />
              </Suspense>
            }
            handle={{
              crumb: "Registriraj",
            }}
          />

          <Route
            path="edit/:id"
            element={
              <Suspense>
                <AdminEdit />
              </Suspense>
            }
            handle={{
              crumb: "Uredi",
            }}
          />
        </Route>
        // Engineers
        <Route
          path="engineers"
          handle={{
            crumb: "ing.med. radiologije",
          }}
        >
          <Route
            index
            element={
              <Suspense>
                <Engineers />
              </Suspense>
            }
          />

          <Route
            path="register"
            element={
              <Suspense>
                <EngineerRegister />
              </Suspense>
            }
            handle={{
              crumb: "Registriraj",
            }}
          />

          <Route
            path="edit/:id"
            element={
              <Suspense>
                <EngineerEdit />
              </Suspense>
            }
            handle={{
              crumb: "Uredi",
            }}
          />
        </Route>
        // Engineers
        <Route
          path="messages"
          handle={{
            crumb: "Obavijesti",
          }}
        >
          <Route
            index
            element={
              <Suspense>
                <Messages />
              </Suspense>
            }
          />

          <Route
            path="add"
            element={
              <Suspense>
                <MessageAdd />
              </Suspense>
            }
            handle={{
              crumb: "Pošalji obavijest",
            }}
          />
        </Route>
        // History
        <Route
          path="history"
          element={
            <Suspense>
              <MedReportHistory />
            </Suspense>
          }
          handle={{
            crumb: "Povijest",
          }}
        />
        // Settings
        <Route
          path="settings"
          element={
            <Suspense>
              <Settings />
            </Suspense>
          }
        />
        // Archive
        <Route
          path="archive"
          handle={{
            crumb: "Arhiva",
          }}
        >
          <Route
            index
            element={
              <Suspense>
                <Archive />
              </Suspense>
            }
          />

          <Route
            path="view/:id"
            element={
              <Suspense>
                <MedReportView />
              </Suspense>
            }
            handle={{
              crumb: "Pregled nalaza",
            }}
          />
        </Route>
        // Unassigned
        <Route
          path="unassigned-med-reports"
          handle={{
            crumb: "Admin. asist.",
          }}
        >
          <Route
            index
            element={
              <Suspense>
                <MedReportUnassigned />
              </Suspense>
            }
          />

          <Route
            path="assign/:id"
            element={
              <Suspense>
                <MedReportAssignUser />
              </Suspense>
            }
            handle={{
              crumb: "Dodijeli pacijenta",
            }}
          />
        </Route>

        // MedReports
        <Route
          path="med-reports"
          handle={{
            crumb: "Pacijenti",
          }}
        >
          <Route
            index
            element={
              <Suspense>
                <MedReports />
              </Suspense>
            }
          />

          <Route
            path="add"
            element={
              <Suspense>
                <MedReportAdd />
              </Suspense>
            }
            handle={{
              crumb: "Dodaj pacijenta",
            }}
          />

          <Route
            path="engineer-add"
            element={
              <Suspense>
                <MedReportEngineerAdd />
              </Suspense>
            }
            handle={{
              crumb: "Dodaj pacijenta",
            }}
          />

          <Route
            path="assign/:id"
            element={
              <Suspense>
                <MedReportAssignUser />
              </Suspense>
            }
            handle={{
              crumb: "Dodijeli pacijenta",
            }}
          />

          <Route
            path="edit/:id"
            element={
              <Suspense>
                <MedReportEdit />
              </Suspense>
            }
            handle={{
              crumb: "Uredi",
            }}
          />

          <Route
            path="engineer-edit/:id"
            element={
              <Suspense>
                <MedReportEngineerEdit />
              </Suspense>
            }
            handle={{
              crumb: "Uredi",
            }}
          />

          <Route
            path="finish/:id"
            element={
              <Suspense>
                <MedReportFinish />
              </Suspense>
            }
            handle={{
              crumb: "Napiši nalaz",
            }}
          />

          <Route
            path="view/:id"
            element={
              <Suspense>
                <MedReportView />
              </Suspense>
            }
            handle={{
              crumb: "Pregled nalaza",
            }}
          />

          <Route
            path="files/:id"
            element={
              <Suspense>
                <MedReportAttachments />
              </Suspense>
            }
            handle={{
              crumb: "Pregled nalaza",
            }}
          />
        </Route>
        <Route
          path="malfunction-report"
          element={
            <Suspense>
              <MalfunctionReportAdd />
            </Suspense>
          }
          handle={{
            crumb: "Tehnička podrška",
          }}
        />
        <Route
          path="/print-engineer-list"
          element={
            <Suspense>
              <EngineerListPrint />
            </Suspense>
          }
          handle={{
            crumb: "Isprintati radni nalog",
          }}
        />
      </Route>

      <Route
        path="print-med-report/:id"
        element={
          <Suspense>
            <MedReportPrint />
          </Suspense>
        }
        handle={{
          crumb: "Isprintati radni nalog",
        }}
      />

      <Route
        path="print-med-reports"
        element={
          <Suspense>
            <MedReportsPrint />
          </Suspense>
        }
        handle={{
          crumb: "Isprintati radni nalog",
        }}
      />

      <Route
        path="/print-engineer-med-reports"
        element={
          <Suspense>
            <EngineerMedReportPrint />
          </Suspense>
        }
        handle={{
          crumb: "Isprintati radni nalog",
        }}
      />

      <Route
        path="test"
        element={
          <Suspense>
            <Test />
          </Suspense>
        }
      />

      <Route
        path="*"
        element={
          <Suspense>
            <NotFound />
          </Suspense>
        }
      />
    </React.Fragment>
  );
};

export default RoutesConfig;
