import React, { useState, useEffect } from "react";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker as DP } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import { useFormat } from "./DatePicker.hook";
import utc from "dayjs/plugin/utc";

import "./DatePicker.scss";

dayjs.extend(utc);

type Props = {
  id?: string;
  includeOnly?: ["year" | "month" | "day"] | undefined;
  maxDate?: Date;
  minDate?: Date;
  value: Date;
  label?: string;
  onChange: (
    value: Date | null,
    keyboardInputValue?: string | undefined
  ) => void;
  disabled?: boolean;
};

export const DatePicker = ({
  id,
  includeOnly,
  maxDate,
  minDate,
  value,
  onChange,
  label,
  disabled
}: Props) => {
  const format = useFormat(includeOnly);

  return (
    <React.Fragment>
      <div className="date-picker-container" id={id}>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          localeText={{ start: "Od", end: "kraj" }}
        >
          <DP
            className="dp"
            views={includeOnly}
            inputFormat={format}
            value={dayjs(value).startOf("day").utc().format()}
            onChange={onChange}
            PopperProps={{
              placement: "bottom-end",
            }}
            label={label}
            renderInput={(params) => (
              <TextField {...params} />
            )}
            maxDate={maxDate}
            minDate={minDate}
            disabled={disabled}
          />
        </LocalizationProvider>
      </div>
    </React.Fragment>
  );
};
