import React, { useState, useEffect } from "react";
import { Control, Controller, FieldValues, Path } from "react-hook-form";
import { TextArea } from "../TextArea/TextArea";
import "./TextAreaForm.scss";

type Props<T extends FieldValues> = {
  id?: string;
  placeholder?: string;
  name: Path<T>;
  control?: Control<T, any> | undefined;
  rules?: {
    required?: boolean;
    maxLength?: number;
    minLength?: number;
    max?: number;
    min?: number;
  };
  rows?: number;
};

export const TextAreaForm = <T extends FieldValues>({
  id,
  placeholder,
  name,
  rows,
  control,
  rules,
}: Props<T>) => {
  return (
    <React.Fragment>
      <div className="text-area-form-container" id={id}>
        <Controller
          control={control}
          name={name}
          rules={rules}
          render={({ field: { onChange, value } }) => (
            <TextArea
              value={value}
              onChange={onChange}
              placeholder={placeholder}
              rows={rows}
            />
          )}
        />
      </div>
    </React.Fragment>
  );
};
