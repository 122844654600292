import "reflect-metadata";
import { Container } from "inversify";
import { IAdminService, IAmbulanceService, IAuthorizationService, IExaminationService, IFileStoreageService, IHospitalService, IMedReportService, IPatientService, IProfessionService, IUserService, IEngineerService, IProfilePictureService, IMessageService, IMalfunctionReportService } from "Services.Common/api";
import { AmbulanceService, AuthorizationService, EngineerService, ExaminationService, FileStoreageService, HospitalService, MalfunctionReportService, MedReportService, MessageService, PatientService, ProfessionService, ProfilePictureService, UserService } from "Services/api";
import { IStoreageService, IUtilityService } from "Services.Common/common";
import { StoreageService } from "Services/common";
import { UtilityService } from "Services/common/UtilityService";
import { IPageFactory, IResponseFactory } from "Services.Common/factory";
import { PageFactory, ResponseFactory } from "Services/factory";
import { TYPES } from "inversify.types";
import { AdminService } from "Services/api/AdminService";
import { MalfunctionReportForm } from "Features/MalfunctionReport";


let container = new Container();
container.bind<IStoreageService>(TYPES.IStoreageService).to(StoreageService);
container.bind<IUtilityService>(TYPES.IUtilityService).to(UtilityService);

container.bind<IPageFactory>(TYPES.IPageFactory).to(PageFactory);
container.bind<IResponseFactory>(TYPES.IResponseFactory).to(ResponseFactory);

container.bind<IAuthorizationService>(TYPES.IAuthorizationService).to(AuthorizationService);
container.bind<IMedReportService>(TYPES.IMedReportService).to(MedReportService);
container.bind<IAmbulanceService>(TYPES.IAmbulanceService).to(AmbulanceService);
container.bind<IHospitalService>(TYPES.IHospitalService).to(HospitalService);
container.bind<IUserService>(TYPES.IUserService).to(UserService);
container.bind<IAdminService>(TYPES.IAdminService).to(AdminService);
container.bind<IPatientService>(TYPES.IPatientService).to(PatientService);
container.bind<IExaminationService>(TYPES.IExaminationService).to(ExaminationService);
container.bind<IProfessionService>(TYPES.IProfessionService).to(ProfessionService);
container.bind<IFileStoreageService>(TYPES.IFileStoreageService).to(FileStoreageService);
container.bind<IEngineerService>(TYPES.IEngineerService).to(EngineerService);
container.bind<IProfilePictureService>(TYPES.IProfilePictureService).to(ProfilePictureService);
container.bind<IMessageService>(TYPES.IMessageService).to(MessageService);
container.bind<IMalfunctionReportService>(TYPES.IMalfunctionReportService).to(MalfunctionReportService);


export { container };