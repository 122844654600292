import React, { CSSProperties, useEffect, useState } from "react";
import { MoonLoader, ClockLoader, PulseLoader } from "react-spinners";

import "./Spinner.scss";

type Props = {
  loading: boolean;
};

// const override: CSSProperties = {
//   position: "absolute",
//   left: "calc(50% - 12.5px)",
//   top: "calc(50% - 12.5px)",
// };

let spinnerTimeout: NodeJS.Timeout;

export const Spinner = ({ loading }: Props) => {
  const [spining, setSpinning] = useState<boolean>(false);

  useEffect(() => {
    if (!loading) {
      setSpinning(false);
      clearTimeout(spinnerTimeout);
    } else {
      spinnerTimeout = setTimeout(() => {
        setSpinning(true);
      }, 100);
    }
  }, [loading]);

  return (
    <React.Fragment>
      {spining && (
        <div className="spinner-container">
          <MoonLoader
            loading
            className="spinner-moon"
            size={30}
            aria-label="Učitavanje"
            data-testid="loader"
            speedMultiplier={1.5}
          />
        </div>
      )}
    </React.Fragment>
  );
};
