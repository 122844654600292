import { useEffect, useState } from "react";

export const useAppVersion = () => {
    const [version, setVersion] = useState<string>("");

    useEffect(() => {
        const versionNumber = require("../../../../package.json").version;
        setVersion(versionNumber);
    }, []);

    return version;
};