import { PageList } from "Models/Common";
import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import { v4 } from "uuid";
import { Column } from "../Table.types";

import "./TBody.scss";

type Props<T> = {
  columns: Column<T>[];
  data: PageList<T>;
  fill?: boolean;
  onSelectItem: (item: T) => void;
  onDoubleClick?: (item: T | undefined) => void;
  selectedRow: number;
  onSelectRow: Dispatch<SetStateAction<number>>;
  disableSelect?: boolean;
  isLoaded?: boolean;
};

let oneClickTimeout: NodeJS.Timeout;

const generateIds = (length: number) => {
  let ids: string[] = [];
  for (let i = 0; i < length; i++) {
    ids.push(v4());
  }
  return ids;
};

export const TBody = <T extends object>({
  data,
  columns,
  fill,
  onSelectItem,
  onDoubleClick,
  selectedRow,
  onSelectRow,
  disableSelect,
  isLoaded,
}: Props<T>) => {
  const [rowIds, setRowIds] = useState<string[]>(
    generateIds(data.items.length)
  );
  const [numberIds, setNumberIds] = useState<string[]>(
    generateIds(data.items.length)
  );
  const [columnIds, setColumnIds] = useState<string[]>(
    generateIds(columns.length)
  );
  const [grayed, setGrayed] = useState<boolean>(false);

  const unwantedElements = ["A", "BUTTON", "INPUT", "TEXTAREA"];

  useEffect(() => {
    if (data.items.length === 0) return;

    for (let i = 0; i < data.items.length; i++) {
      setRowIds((v) => [...v, v4()]);
      setNumberIds((v) => [...v, v4()]);
    }

    for (let i = 0; i < columns.length; i++) {
      setColumnIds((v) => [...v, v4()]);
    }
  }, [data.items]);

  if (data.items.length === 0) {
    return (
      <React.Fragment>
        <tbody style={{ height: 410 }}>
          <tr>
            <td colSpan={columns.length + 1}>
              <div className="no-data">
                {isLoaded && <h3>Nema podataka</h3>}
              </div>
            </td>
          </tr>
        </tbody>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <tbody className="t-body-container">
        {data.items?.map((x, i) => {
          const rowNumber = data.pageSize * (data.page - 1) + i + 1;
          const rowNumberLength = Math.log(rowNumber) * Math.LOG10E + 1 | 0;
          
          return (
          <tr
            key={rowIds[i]}
            className={
              "select-row-ligher " + (i === selectedRow ? "selected" : "")
            }
            onClick={(e) => {
              if (disableSelect) return;

              if (e.detail === 2) {
                clearTimeout(oneClickTimeout);
                onDoubleClick?.(data.items[i]);
              }

              if (e.detail === 1) {
                oneClickTimeout = setTimeout(() => {
                  onSelectRow((v) => (v === i ? -1 : i));
                  onSelectItem(data.items[i]);
                }, 0); // Change to 200 for triggering double click only after 200ms
              }
            }}
          >
            <td key={numberIds[i]} style={{width: rowNumberLength * 15}}>
              <span>{rowNumber}</span>
            </td>
            {columns.map((y, j) => (
              <>
                {!y.hide && (
                  <td key={columnIds[j]}>
                    {y.name ? x[y.name as keyof object] : y.element?.(x)}
                  </td>
                )}
              </>
              /* {y.element && <td key={v4()}>aaa</td>} */
            ))}
          </tr>
        )})}
        {fill &&
          [...Array(Math.abs(data.pageSize - data.items.length))].map(
            (x, i) => (
              <tr key={i}>
                <td key={v4()}>
                  {data.pageSize * (data.page > 0 ? data.page - 1 : 0) +
                    data.items.length +
                    i +
                    1}
                </td>
                <td colSpan={columns.length}></td>
              </tr>
            )
          )}
      </tbody>
    </React.Fragment>
  );
};
