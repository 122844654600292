import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./Button.scss";

type Props = {
  id?: string;
  bt: "primary" | "secondary" | "danger";
  children: JSX.Element | JSX.Element[] | string;
  icon?: IconProp;
  style?: React.CSSProperties;
  onClick?: () => void;
  route?: string;
  height?: string;
  disabled?: boolean;
};

export const Button = ({
  id,
  bt,
  children,
  icon,
  route,
  style,
  height,
  disabled,
  onClick,
}: Props) => {
  return (
    <React.Fragment>
      <div onClick={onClick} className="button-container" id={id}>
        <Link to={route ?? "#"}>
          <button
            type="button"
            className={bt}
            style={{ ...style, height: height }}
            disabled={disabled}
          >
            {icon && <FontAwesomeIcon icon={icon} />} {children}
          </button>
        </Link>
      </div>
    </React.Fragment>
  );
};
