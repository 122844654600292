import { container } from "inversify.config";
import { TYPES } from "inversify.types";
import { Response } from "Models/Common";
import { MedReport, MedReportPost } from "Models/Api/MedReport";
import { User } from "Models/Api/User";
import { useEffect, useState } from "react";
import {
    IExaminationService,
    IHospitalService,
    IMedReportService,
} from "Services.Common/api";
import { IPatientService } from "Services.Common/api/IPatientService";
import { IResponseFactory } from "Services.Common/factory";

import { toast } from "react-toastify";
import {
    notificationError,
    notificationMessage,
    savedMessage,
    sentToAdministatorMessage,
} from "Models/Common/NotificationMessages";
import dayjs from "dayjs";
import { Form } from "./MedReportEngineerForm.type";
import { useRecoilValue } from "recoil";
import { withEngineer } from "Recoil/User/atom";
import { usePost } from "Hooks/usePost";

//TODO: Change this
export const usePostMedReport = (form: Form) => {
    let patientService = container.get<IPatientService>(TYPES.IPatientService);
    let medReportService = container.get<IMedReportService>(
        TYPES.IMedReportService,
    );
    let responseFactory = container.get<IResponseFactory>(
        TYPES.IResponseFactory,
    );

    const [response, setResponse] = useState<Response<MedReport>>(
        responseFactory.create<MedReport>(),
    );
    const {
        firstName,
        lastName,
        year,
        contrast,
        examinationIds,
        ambulanceId,
        hospitalId,
        remarks,
        engineerId,
        hospitalExamination,
        urgentExamination,
    } = form;
    const engineer = useRecoilValue(withEngineer);

    const send = async (reset: (x?: MedReport) => void) => {
        const patientResponse = patientService.createAsync({
            firstName,
            lastName,
            year: year.year(),
        });
        const patient = await toast.promise(patientResponse, notificationError);

        if (patient.hasError || !patient.model?.id) {
            setResponse(responseFactory.maperror<MedReport>(patient));
            return;
        }

        const medReportResponse = medReportService.engineerCreateAsync({
            contrast,
            examinationIds,
            remarks,
            patientId: patient.model.id,
            ambulanceId,
            hospitalId: engineer.hospitalId,
            engineerId: engineer.id ?? "",
            hospitalExamination,
            urgentExamination,
        });
        const medReport = await toast.promise(medReportResponse, savedMessage);

        if (!medReport.hasError) reset(medReport.model);

        setResponse(medReport);
    };

    return [response, send] as const;
};

export const usePutMedReportEngineer = (form: Form) => {
    let patientService = container.get<IPatientService>(TYPES.IPatientService);
    let medReportService = container.get<IMedReportService>(
        TYPES.IMedReportService,
    );
    let responseFactory = container.get<IResponseFactory>(
        TYPES.IResponseFactory,
    );

    const [response, setResponse] = useState<Response<MedReport>>(
        responseFactory.create<MedReport>(),
    );
    const {
        id,
        patientId,
        firstName,
        lastName,
        year,
        contrast,
        examinationIds,
        ambulanceId,
        hospitalId,
        remarks,
        hospitalExamination,
        urgentExamination,
    } = form;

    const send = async (reset: () => void) => {
        const patientResponse = patientService.updateAsync({
            id: patientId,
            firstName,
            lastName,
            year: year.year(),
        });
        const patient = await toast.promise(patientResponse, notificationError);

        if (patient.hasError || !patient.model?.id) {
            setResponse(responseFactory.maperror<MedReport>(patient));
            return;
        }

        const medReportResponse = medReportService.engineerUpdateAsync({
            id,
            contrast,
            examinationIds,
            remarks,
            patientId: patient.model.id,
            ambulanceId,
            hospitalId,
            hospitalExamination,
            urgentExamination,
        });
        const medReport = await toast.promise(medReportResponse, savedMessage);

        if (!medReport.hasError) reset();

        setResponse(medReport);
    };

    return [response, send] as const;
};

export const useMedReportEngineerFinish = (id: string) => {
    let medReportService = container.get<IMedReportService>(
        TYPES.IMedReportService,
    );

    return usePost(
        () => medReportService.engineerFinishAsync({ id }),
        sentToAdministatorMessage,
    );
};
