import axios from "axios";
import { injectable } from "inversify";
import { PageList, Response } from "Models/Common";
import { PageParam } from "Models/Common";
import { SortParam } from "Models/Common/SortParam";

@injectable()
export class BaseService<T> {
    protected baseUrl = "";

    protected async fetchAsync<F>(pageParam: PageParam, sortParam?: SortParam, filter?: F, includeItems?: string[], route?: string) {
        return (await axios.get<Response<PageList<T>>>(this.baseUrl + (route ?? ""), { params: { ...pageParam, ...sortParam, ...filter, includeItems }, paramsSerializer: { indexes: true } })).data;
    }

    protected async fetchWithDifferentModelAsync<M>(pageParam: PageParam, sortParam?: SortParam, filter?: M, includeItems?: string[], route?: string) {
        return (await axios.get<Response<PageList<M>>>(this.baseUrl + (route ?? ""), { params: { ...pageParam, ...sortParam, ...filter, includeItems }, paramsSerializer: { indexes: true } })).data;
    }

    protected async fetchFromRouteAsync<M>(route?: string) {
        return (await axios.get<Response<M>>(this.baseUrl + (route ?? ""))).data;
    }

    protected async fetchByIdAsync(id: string) {
        return (await axios.get<Response<T>>(this.baseUrl + `/by/${id}`)).data
    }

    protected async postAsync<M>(model: M) {
        return (await axios.post<Response<T>>(this.baseUrl, model)).data;
    }

    protected async postAndReturnAsync<M, E>(model: M, route?: string) {
        return (await axios.post<Response<E>>(this.baseUrl + (route ?? ""), model)).data;
    }

    protected async putAsync<M>(model: M, route?: string) {
        return (await axios.put<Response<T>>(this.baseUrl + (route ?? ""), model)).data;
    }

    protected async putAndReturnAsync<M, E>(model: M, route?: string) {
        return (await axios.put<Response<E>>(this.baseUrl + (route ?? ""), model)).data;
    }

    protected async removeAsync<M>(id: string) {
        return (await axios.delete<Response<M>>(this.baseUrl + "/" + id)).data;
    }
}