import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Tooltip } from "@mui/material";
import { useRecoilState } from "recoil";
import { withSidebarOpen } from "../Recoil/atom";


import "./PageButton.scss";
type Params = {
  route: string;
  children: JSX.Element | JSX.Element[] | string;
  icon?: IconProp;
  iconElement?: JSX.Element;
};

export const PageButton = ({ route, children, icon, iconElement }: Params) => {
  const [indicator, setIndicator] = useState<string>("");
  const [path, setPath] = useState<string>("");
  const [sidebarOpen, setSidebarOpen] = useRecoilState(withSidebarOpen);
  const location = useLocation();

  useEffect(() => setPath("/" + location.pathname.split("/")[1]), [location]);

  useEffect(() => {
    if (path === route) setIndicator("indicator");
    else setIndicator("");
  }, [path]);

  return (
    <React.Fragment>
      <Link
        to={route}
        onClick={() => {
          setSidebarOpen(false);
        }}
        onMouseEnter={() => {
          if (path !== route) setIndicator("ind-hover");
        }}
        onMouseLeave={() => {
          if (path !== route) setIndicator("");
          else setIndicator("indicator");
        }}
      >
        <div className="page-button-container">
          <div className="indicator-container">
            <motion.div
              initial={{ opacity: 1 }}
              animate={{
                height: indicator === "indicator" ? 40 : 20,
                opacity:
                  indicator === "ind-hover" || indicator === "indicator"
                    ? 1
                    : 0,
              }}
              transition={{ delay: 0.1, duration: 0.2 }}
              className={indicator}
            />
          </div>

          <div className={`name-container ${path === route && "active"}`}>
            <motion.span className="big-button" id={route.slice(1)}>
              {iconElement && iconElement}
              {icon && <FontAwesomeIcon icon={icon} />} {children}
            </motion.span>

            <motion.span className="small-button">
              {icon && (
                <Tooltip title={children} placement="right">
                  <FontAwesomeIcon icon={icon} />
                </Tooltip>
              )}
              {iconElement && (
                <Tooltip title={children} placement="right">
                  {iconElement}
                </Tooltip>
              )}
            </motion.span>
          </div>
        </div>
      </Link>
    </React.Fragment>
  );
};
