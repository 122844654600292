import React, { useState, useEffect } from "react";
import "./Paper.scss";

type Props = {
  children: JSX.Element | JSX.Element[] | string | string[];
  width: "full" | "full-width" | "fit";
  height?: string | number;
  padding?: string;
};

export const Paper = ({ children, width, padding, height }: Props) => {
  return (
    <React.Fragment>
      <div
        className={`paper-container ${width}`}
        style={{ padding: padding ?? "10px", height }}
      >
        {children}
      </div>
    </React.Fragment>
  );
};
