import {
  faBan,
  faEye,
  faFilter,
  faPen,
  faPrint,
  faRotateRight,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "Components/FormComponents/Button/Button";
import { PageList } from "Models/Common";
import { PageParam } from "Models/Common/PageParam";
import React, { ReactElement, useEffect, useRef, useState } from "react";

import { v4 } from "uuid";
import { Paginator } from "./Paginator/Paginator";
import { Column } from "./Table.types";

import { useOutsideAlerter } from "Hooks/useOutsideAlerter";
import { FilterModal } from "./FilterModal/FilterModal";
import { TBody } from "./TBody/TBody";
import { Tooltip } from "@mui/material";
import { Spinner } from "./Spinner/Spinner";
import { FilterSidebar } from "./FilterSidebar/FilterSidebar";
import { BrowserView, MobileView } from "react-device-detect";
import { FloatingButton } from "Components/FormComponents/FloatingButton/FloatingButton";
import { RecoilState } from "recoil";

import "./Table.scss";
import { info } from "console";
import { useHasPrintSection } from "./Table.hooks";

type Props<T> = {
  title: string;
  columns: Column<T>[];
  data: PageList<T>;
  pageState: RecoilState<PageParam>;
  route?: string;
  actionButtonText?: string;
  children?: React.ReactNode;
  onAdd?: () => void;
  onEdit?: (item: T | undefined) => void;
  onDelete?: (item: T | undefined) => void;
  onView?: (item: T | undefined) => void;
  onBlock?: (item: T | undefined) => void;
  onRefresh?: () => void;
  onDoubleClick?: (item: T | undefined) => void;
  showActions: ("EDIT" | "DELETE" | "VIEW" | "BLOCK" | "PRINT")[];
  fill?: boolean;
  isLoaded?: boolean;
  disableSelect?: boolean;
  filterChanged?: boolean;
  onFilterClear?: () => void;
  disableAdd?: boolean;
};

export const Table = <T extends object>({
  columns,
  data,
  title,
  pageState,
  route,
  actionButtonText,
  children,
  onAdd,
  onEdit,
  onDelete,
  onView,
  onBlock,
  onRefresh,
  onDoubleClick,
  showActions,
  fill,
  isLoaded,
  disableSelect,
  filterChanged,
  onFilterClear,
  disableAdd,
}: Props<T>) => {
  const [selectedRow, setSelectedRow] = useState<number>(-1);
  const [selectedItem, setSelectedItem] = useState<T>();
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [showPrintSection, setShowPrintSection] = useState<boolean>(false);

  const ref = useRef<any>();
  useOutsideAlerter(
    ref,
    () => {
      setSelectedRow(-1);
    },
    () => {},
  );

  // const [width, height] = useContainerDimensions(ref);
  // const [tableWidth, contentWidth] = useTableDimensions(width);

  return (
    <React.Fragment>
      <div className="table-container" ref={ref}>
        <div className="content">
          <div className="table-header">
            <BrowserView className="title">
              <h3>{title}</h3>
            </BrowserView>
            <BrowserView className="line" />

            <div className="components">
              <Tooltip title="Osvježi">
                <button className="table-button" onClick={onRefresh}>
                  <FontAwesomeIcon icon={faRotateRight} />
                </button>
              </Tooltip>

              {children && (
                <>
                  <Tooltip title="Filter">
                    <button
                      onClick={() => {
                        if (showPrintSection)
                          setShowFilter(true);
                        else setShowFilter((val) => !val);

                        setShowPrintSection(false);
                      }}
                      className="filter-button table-button"
                    >
                      <FontAwesomeIcon icon={faFilter} />
                      {filterChanged && <div className="filter-indicator" />}
                    </button>
                  </Tooltip>

                  {showActions.includes("PRINT") && (
                    <Tooltip title="Printaj">
                      <button
                        className="table-button"
                        onClick={() => {
                          if (!showPrintSection)
                            setShowFilter(true);
                          else
                            setShowFilter((val) => !val);
                          setShowPrintSection(true);
                        }}
                      >
                        <FontAwesomeIcon icon={faPrint} />
                      </button>
                    </Tooltip>
                  )}
                </>
              )}
            </div>
            <div className="action">
              <div className="icons">
                {showActions.includes("VIEW") && selectedRow !== -1 && (
                  <div className="action-icon">
                    <Tooltip title="Pregledaj">
                      <button
                        className="table-button"
                        onClick={() => {
                          setSelectedRow(-1);
                          onView?.(selectedItem);
                        }}
                      >
                        <FontAwesomeIcon icon={faEye} />
                      </button>
                    </Tooltip>
                  </div>
                )}

                {showActions.includes("EDIT") && selectedRow !== -1 && (
                  <div className="action-icon">
                    <Tooltip title="Izmjeni">
                      <button
                        className="table-button"
                        onClick={() => {
                          setSelectedRow(-1);
                          onEdit?.(selectedItem);
                        }}
                      >
                        <FontAwesomeIcon icon={faPen} />
                      </button>
                    </Tooltip>
                  </div>
                )}

                {showActions.includes("DELETE") && selectedRow !== -1 && (
                  <div className="action-icon">
                    <Tooltip title="Obriši">
                      <button
                        className="table-button"
                        onClick={() => {
                          setSelectedRow(-1);
                          onDelete?.(selectedItem);
                        }}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </Tooltip>
                  </div>
                )}

                {showActions.includes("BLOCK") && selectedRow !== -1 && (
                  <div className="action-icon">
                    <Tooltip title="Blokiraj">
                      <button
                        className="table-button"
                        onClick={() => {
                          setSelectedRow(-1);
                          onBlock?.(selectedItem);
                        }}
                      >
                        <FontAwesomeIcon icon={faBan} />
                      </button>
                    </Tooltip>
                  </div>
                )}
              </div>

              <BrowserView>
                {!disableAdd && (route || onAdd) && (
                  <div id="table-add">
                    <Button route={route} onClick={onAdd} bt="primary">
                      {actionButtonText || "Dodaj"}
                    </Button>
                  </div>
                )}
              </BrowserView>
            </div>
          </div>
          <div className="table-grid">
            <div
              className="table-wrapper"
              style={{
                overflowX: isLoaded ? "auto" : "hidden",
              }}
            >
              <table>
                <thead>
                  <tr>
                    <td>
                      <span>#</span>
                    </td>
                    {columns.map((x, i) => (
                      <>{!x.hide && <td key={i}>{x.label}</td>}</>
                    ))}
                  </tr>
                </thead>

                <TBody
                  data={data}
                  columns={columns}
                  fill={fill}
                  onSelectItem={setSelectedItem}
                  selectedRow={selectedRow}
                  onSelectRow={setSelectedRow}
                  onDoubleClick={onDoubleClick}
                  disableSelect={disableSelect}
                  isLoaded={isLoaded}
                />
              </table>
              <Spinner loading={!isLoaded} />
              {/* <Spinner loading={true} /> */}
            </div>
            <FilterSidebar
              isOpen={showFilter}
              showPrintSection={showPrintSection}
              onClose={() => setShowFilter(false)}
              onFilterClear={onFilterClear}
            >
              {children}
            </FilterSidebar>
          </div>
        </div>
        {data.items.length > 0 && (
          <div className="table-footer">
            <Paginator pageState={pageState} total={data.totalPages} />
          </div>
        )}
      </div>

      <MobileView>
        <FloatingButton route={route} onClick={onAdd} />
      </MobileView>
    </React.Fragment>
  );
};
