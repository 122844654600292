import React, { useState, useEffect } from "react";
import {
  Control,
  Controller,
  FieldValues,
  Path,
  ValidationRule,
} from "react-hook-form";
import { ErrorLabel } from "../ErrorLabel/ErrorLabel";
import { Input } from "../Input/Input";
import "./InputForm.scss";

type Props<T extends FieldValues> = {
  id?: string;
  placeholder?: string;
  type?: string;
  name: Path<T>;
  control?: Control<T, any> | undefined;
  rules?: {
    required?: boolean;
    maxLength?: number;
    minLength?: number;
    max?: number;
    min?: number;
    pattern?: ValidationRule<RegExp>;
  };
};

export const InputForm = <T extends FieldValues>({
  id,
  placeholder,
  type,
  name,
  control,
  rules,
}: Props<T>) => {
  return (
    <React.Fragment>
      <div className="input-form-container" id={id}>
        <Controller
          control={control}
          name={name}
          rules={rules}
          render={({ field: { onChange, value } }) => {
            return (
              <>
                <Input
                  
                  placeholder={placeholder}
                  type={type}
                  value={value}
                  onChange={onChange}
                />
              </>
            );
          }}
        />
      </div>
    </React.Fragment>
  );
};
