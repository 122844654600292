import React, { useState, useEffect } from "react";
import "./ErrorLabel.scss";

type Props = {
  type?: string,
  required?: string;
  maxLength?: string;
  minLength?: string;
  max?: string;
  min?: string;
  pattern?: string;
};

export const ErrorLabel = ({
  type,
  required,
  maxLength,
  minLength,
  max,
  min,
  pattern,
}: Props) => {
  const messages = {
    required: "Polje je obavezno",
    maxLength: "Pređena je maximalna duljina",
    minLength: "Nije zadovoljena minimalna duljina",
    max: "Maximalan broj",
    min: "Minimalan broj",
    pattern: "Email nije u dobrom formatu",
  };

  return (
    <React.Fragment>
      <div className="error-label-container">
        {type === "required" && <p>{required ?? messages.required}</p>}
        {type === "maxLength" && <p>{maxLength ?? messages.maxLength}</p>}
        {type === "minLength" && <p>{minLength ?? messages.minLength}</p>}
        {type === "max" && <p>{max ?? messages.max}</p>}
        {type === "min" && <p>{min ?? messages.min}</p>}
        {type === "pattern" && <p>{pattern ?? messages.pattern}</p>}
      </div>
    </React.Fragment>
  );
};
