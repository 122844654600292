import { injectable } from "inversify";
import { Admin, AdminPut } from "Models/Api/Admin";
import { PageParam, Response } from "Models/Common";
import { SortParam } from "Models/Common/SortParam";
import { IAdminService } from "Services.Common/api";
import { BaseService } from "./BaseService";

@injectable()
export class AdminService extends BaseService<Admin> implements IAdminService {
    baseUrl = "/api/admin";

    getAsync(pageParam: PageParam, sortParam?: SortParam) {
        return this.fetchAsync(pageParam, sortParam);
    }

    getIdAsync(id: string): Promise<Response<Admin>> {
        return this.fetchByIdAsync(id);
    }

    updateAsync(admin: AdminPut): Promise<Response<Admin>> {
        return this.putAsync(admin);
    }
}