import dayjs from "dayjs";
import { MedReport } from "Models/Api/MedReport";
import React, { useState, useEffect } from "react";
import { MedReportTableModel } from "../MedReportTable/MedReportTable.type";
import "./MedReportStatus.scss";

type Props = {
  report: string;
  returned: boolean;
  deadline: Date;
  comment: string;
};

export const MedReportStatus = ({
  report,
  returned,
  deadline,
  comment,
}: Props) => {
  // console.log(dayjs(deadline).diff(dayjs(), "day", true));

  const label = () => {
    if (dayjs(deadline).isSame(dayjs("0001-01-01T00:00:00.000Z")))
      return { id: 1, status: "black", label: "nije dodjeljen" };
    if (comment) return { id: 2, status: "purple", label: "dopuna u tijeku" };
    if (returned) return { id: 3, status: "green", label: "završeno" };
    if (!report && dayjs(deadline).diff(dayjs(), "day") < 1)
      return { id: 4, status: "red", label: "prioritetno" };
    if (report && dayjs(deadline).diff(dayjs(), "day") < 1)
      return { id: 5, status: "red", label: "preliminarno" };
    if (report && dayjs(deadline).diff(dayjs(), "day") >= 1)
      return { id: 6, status: "gray", label: "preliminarno" };
    return { id: 7, status: "yellow", label: "u tijeku" };
  };

  return (
    <React.Fragment>
      <div className="med-report-status-container">
        <div className={`status-inicator ${label().status}`} />
        {label().id !== 1 && <p className="status-label">{label().label}</p>}
        {label().id === 1 && (
          <p
            style={{ fontStyle: "italic", color: "#f94144" }}
            className="status-label"
          >
            {label().label}
          </p>
        )}
      </div>
    </React.Fragment>
  );
};
