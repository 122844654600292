
import { BaseService } from "./BaseService";
import { PageList, PageParam, Response } from "Models/Common";
import { IFileStoreageService } from "Services.Common/api/IFileStoreageService";
import axios from "axios";
import { MedReportFilePost } from "Models/Api/MedReport/MedReportFilePost";
import { MedReportFile } from "Models/Api/MedReport/MedReportFile";
import { MedReportFileFilter } from "Models/Api/MedReport";
import { container } from "inversify.config";
import { TYPES } from "inversify.types";
import { IResponseFactory } from "Services.Common/factory";

export class FileStoreageService extends BaseService<MedReportFile> implements IFileStoreageService {
    baseUrl = "/api/storage";

    async uploadMedReportFileAsync(model: MedReportFilePost): Promise<Response<boolean>> {
        var responseService = container.get<IResponseFactory>(TYPES.IResponseFactory);
        var response = responseService.create<boolean>();

        if (model.files === undefined || model.files.length === 0) {
            response.model = false;

            return response;
        }

        const formData = new FormData();
        formData.append('medReportId', model.medReportId);
        const files = model.files;
        for (let i = 0; i < files.length; i++) {
            formData.append('files', files[i]);
        }

        console.log(formData, model, "formData model");

        return await axios.post<Response<boolean>>(this.baseUrl, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }).then(x => x.data);
    }

    getMedReportFilesAsync(pageParam: PageParam, filter: MedReportFileFilter): Promise<Response<PageList<MedReportFile>>> {
        return this.fetchAsync(pageParam, undefined, filter)
    }
}
