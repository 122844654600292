import React, { useState, useEffect } from "react";
import { useValidateRole } from "./Restrict.hooks";
import { Role } from "./Restrict.type";

type Props = {
  children?: React.ReactNode;
  roles: Role[];
};

export const Restrict = ({ children, roles }: Props) => {
  const isValid = useValidateRole(roles);

  if (!isValid) return <></>;

  return <React.Fragment>{children}</React.Fragment>;
};
