import { installedAppMessage, notificationError, notificationPending, updateMessage } from "Models/Common/NotificationMessages";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { usePWAInstall } from "react-use-pwa-install"

export const useDownloadApp = () => {
    const pwaInstall = usePWAInstall();
    const [isInstalled, setIsInstalled] = useState(false);

    const installApp = async () => {

        if (!pwaInstall) {
            toast.info("Ova opcija trenutno nije dostupna. Molimo pokušajte kasnije.");
            return;
        }

        const install = pwaInstall();

        if (!install) {
            toast.info("Ova opcija trenutno nije dostupna. Molimo pokušajte kasnije.");
            return;
        }

        await toast.promise(install, notificationPending);
    }

    return installApp;
}