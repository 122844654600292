import "reflect-metadata";
import { inject, injectable } from "inversify";
import { TYPES } from "inversify.types";
import { MedReportAssigmentPost, MedReportAssistantChat, MedReportEngineerFinished, MedReportEngineerPost, MedReportEngineerPut, MedReportFilter, MedReportFinish, MedReportPost, MedReportPut } from "Models/Api/MedReport";
import { MedReport } from "Models/Api/MedReport/MedReport";
import { MedReportComment } from "Models/Api/MedReport/MedReportComment";
import { PageList, Response } from "Models/Common";
import { PageParam } from "Models/Common/PageParam";
import { SortParam } from "Models/Common/SortParam";
import { IMedReportService } from "Services.Common/api/IMedReportService";
import { IUtilityService } from "Services.Common/common";
import { IPageFactory } from "Services.Common/factory";
import { BaseService } from "./BaseService";
import { MedReportReturn } from "Models/Api/MedReport/MedReportReturn";

@injectable()
export class MedReportService extends BaseService<MedReport> implements IMedReportService {
    baseUrl = "/api/medreport";

    @inject(TYPES.IUtilityService)
    private utilityService: IUtilityService;

    @inject(TYPES.IPageFactory)
    private pageFactory: IPageFactory;

    getAsync(pageParam: PageParam, sortParam?: SortParam, filter?: MedReportFilter, includeItems?: string[]) {
        return this.fetchAsync(pageParam, sortParam, filter, includeItems);
    }

    getByIdAsync(id: string) {
        return this.fetchByIdAsync(id);
    }

    createAsync(medReportPost: MedReportPost) {
        return this.postAsync<MedReportPost>(medReportPost);
    }

    assignAsync(model: MedReportAssigmentPost): Promise<Response<MedReport>> {
        return this.postAndReturnAsync<MedReportAssigmentPost, MedReport>(model, "/assign");
    }

    assignMedReportAsync(): Promise<Response<boolean>> {
        return this.fetchFromRouteAsync<boolean>("/assign-med-reports");
    }

    assignedMedReportAsync(pageParam: PageParam, filter?: MedReportFilter): Promise<Response<PageList<MedReport>>> {
        return this.fetchAsync(pageParam, undefined, filter, undefined, "/assigned-med-reports");
    }

    clearAssignedAsync(): Promise<Response<boolean>> {
        return this.fetchFromRouteAsync<boolean>("/clear-assigned");
    }

    engineerGetAsync(pageParam: PageParam, sortParam?: SortParam | undefined, filter?: MedReportFilter | undefined): Promise<Response<PageList<MedReport>>> {
        return this.fetchAsync(pageParam, sortParam, filter, undefined, "/engineer-get");
    }
    
    engineerCreateAsync(model: MedReportEngineerPost): Promise<Response<MedReport>> {
        return this.postAndReturnAsync<MedReportEngineerPost, MedReport>(model, "/engineer-create");
    }

    engineerUpdateAsync(model: MedReportEngineerPut): Promise<Response<MedReport>> {
        return this.putAndReturnAsync<MedReportEngineerPut, MedReport>(model, "/engineer-update");
    }

    engineerFinishAsync(id: MedReportEngineerFinished): Promise<Response<boolean>> {
        return this.postAndReturnAsync<MedReportEngineerFinished, boolean>(id, "/engineer-finished");
    }

    updateAsync(model: MedReportPut): Promise<Response<MedReport>> {
        return this.putAsync<MedReportPut>(model);
    }

    finishAsync(model: MedReportFinish) {
        return this.postAndReturnAsync<MedReportFinish, boolean>(model, "/finish");
    }

    commentAsync(model: MedReportComment): Promise<Response<MedReport>> {
        return this.postAndReturnAsync<MedReportComment, MedReport>(model, "/comment");
    }

    returnCommentedAsync(model: MedReportReturn): Promise<Response<MedReport>> {
        return this.postAndReturnAsync<MedReportReturn, MedReport>(model, "/return-commented");
    }

    returnAsync(model: MedReportReturn): Promise<Response<MedReport>> {
        return this.postAndReturnAsync<MedReportReturn, MedReport>(model, "/return");
    }

    deleteAsync(id: string) {
        return this.removeAsync<boolean>(id);
    }
    
    assistantChatAsync(model: MedReportAssistantChat): Promise<Response<string>> {
        return this.postAndReturnAsync<MedReportAssistantChat, string>(model, "/assistant-chat");
    }
}
