import React, { useState, useEffect } from "react";
import { Paper } from "Components";
import { useRecoilValue } from "recoil";
import { withSettings } from "Recoil/LocalStoreage/atom";

import "./FormPaper.scss";

type Props = {
  children: string | string[] | JSX.Element | JSX.Element[];
};

export const FormPaper = ({ children }: Props) => {
  const settings = useRecoilValue(withSettings);

  return (
    <React.Fragment>
      <div className="form">
        <Paper width={settings.wideForm ? "full-width" : "full"}>
          {children}
        </Paper>
      </div>
    </React.Fragment>
  );
};
