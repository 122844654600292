import dayjs from "dayjs";
import { useGetList } from "Hooks/useGet";
import { container } from "inversify.config";
import { TYPES } from "inversify.types";
import { MedReportFilter, MedReport } from "Models/Api/MedReport";
import { PageParam } from "Models/Common";
import { SortParam } from "Models/Common/SortParam";
import { IMedReportService } from "Services.Common/api";
import { MedReportTableModel } from "./MedReportTableHistory.type";

export const useMedReportHistoryTable = (pageParam: PageParam, sortParam: SortParam, filter: MedReportFilter) => {
    let medReportService = container.get<IMedReportService>(TYPES.IMedReportService);

    return useGetList<MedReport, MedReportTableModel>(() => medReportService.getAsync(pageParam, sortParam, filter, ["Patient", "User", "Hospital"]),
        x => {
            const model: MedReportTableModel = {
                id: x.id as string,
                patient: `${x.patient?.lastName} ${x.patient?.firstName}, ${x.patient?.year}`,
                doctor: x.user ? `${x.user.lastName} ${x.user.firstName}` : "",
                date: !(x.comment || dayjs(x.deadline).isSame(dayjs('0001-01-01T00:00:00.000Z'))) ? dayjs(x.deadline).format("DD.MM.YYYY.") : "",
                hospital: x.hospital?.name,
                examinations: x.examinations.map(y => y.name).join(" + "),
                dateCreated: dayjs(x.dateCreated).format("DD.MM.YYYY.")
            }

            return model;
        }, [pageParam, sortParam, filter]);
}