import { container } from "inversify.config";
import { MedReportTableUserModel } from "./MedReportUserTable.type";
import { TYPES } from "inversify.types";
import { PageParam } from "Models/Common/PageParam";
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { IMedReportService } from "Services.Common/api";
import { IStoreageService, IUtilityService } from "Services.Common/common";
import { IPageFactory } from "Services.Common/factory";
import { PageList } from "Models/Common";
import { SortParam } from "Models/Common/SortParam";
import { MedReport, MedReportFilter } from "Models/Api/MedReport";
import { notificationMessage } from "Models/Common/NotificationMessages";
import { toast } from "react-toastify";
import { useGet, useGetList } from "Hooks/useGet";
import { withUser } from "Recoil/User/atom";
import dayjs from "dayjs";

/*
    NOTE:
    THIS HOOK IS TEMPORARY FOR TESTING PURPOSES
    DO NOT USE IT IN PRODUCTION!!!!!!!!
*/

export const useMedReportUserTable = (pageParam: PageParam, sortParam: SortParam, filter: MedReportFilter) => {
    let medReportService = container.get<IMedReportService>(TYPES.IMedReportService);
    let utilityService = container.get<IUtilityService>(TYPES.IUtilityService);
    let storeageService = container.get<IStoreageService>(TYPES.IStoreageService);

    const user = useRecoilValue(withUser);


    return useGetList<MedReport, MedReportTableUserModel>(() => medReportService.getAsync(pageParam, sortParam, { ...filter, userId: user.id ?? "" }, ["Patient", "Ambulance", "Hospital"]),
        x => {
            const model: MedReportTableUserModel = {
                id: x.id as string,
                patient: `${x.patient?.lastName} ${x.patient?.firstName}, ${x.patient?.year}`,
                contrast: x.contrast ? "DA" : "NE",
                remarks: x.remarks,
                deadline: !x.comment ? utilityService.getTimeDifference(new Date(x.deadline)) : "",
                dateCreated: dayjs(x.dateCreated).format("DD.MM.YYYY."),
                deadlineValue: x.deadline,
                examinations: x.examinations.map(y => y.name).join(" + "),
                regionNum: x.examinations.length,
                report: x.report,
                ambulance: x.ambulance.name,
                hospital: x.hospital.name,
                returned: x.returned,
                comment: x.comment
            }

            return model;
        }, [pageParam, sortParam, filter]);
}