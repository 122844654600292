import React, { useState, useEffect } from "react";
import "./Grid.scss";

type Props = {
  children: JSX.Element | JSX.Element[] | React.ReactNode;
  gridTemplateColumns: string;
  gap?: string;
};

export const Grid = ({ children, gridTemplateColumns, gap }: Props) => {
  return (
    <React.Fragment>
      <div
        className="grid-container"
        style={{
          gridTemplateColumns,
          gap
        }}
      >
        {children}
      </div>
    </React.Fragment>
  );
};
