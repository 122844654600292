import { useGetList } from "Hooks/useGet";
import { MedReport, MedReportFilter } from "Models/Api/MedReport";
import { PageParam } from "Models/Common";
import { SortParam } from "Models/Common/SortParam";
import { withEngineer } from "Recoil/User/atom";
import { IMedReportService } from "Services.Common/api";
import { container } from "inversify.config";
import { TYPES } from "inversify.types";
import { useRecoilValue } from "recoil";
import { MedReportEngineerTableModel } from "./MedReportEngineerTable.type";
import dayjs from "dayjs";

export const useMedReportEngineerTable = (pageParams: PageParam, sortParam: SortParam, filter: MedReportFilter) => {
    let medReportService = container.get<IMedReportService>(TYPES.IMedReportService);

    const engineer = useRecoilValue(withEngineer);

    return useGetList<MedReport, MedReportEngineerTableModel>(() => medReportService.engineerGetAsync(pageParams, sortParam, { ...filter, engineerId: engineer.id ?? "" }),
        x => {
            const model: MedReportEngineerTableModel = {
                id: x.id as string,
                patient: `${x.patient?.lastName} ${x.patient?.firstName}, ${x.patient?.year}`,
                contrast: x.contrast ? "DA" : "NE",
                remarks: x.remarks,
                dateCreated: dayjs(x.dateCreated).format("DD.MM.YYYY."),
                examinations: x.examinations.map(y => y.name).join(" + "),
                regionNum: x.examinations.length,
                ambulance: x.ambulance.name,
                hospital: x.hospital.name,
                comment: x.comment,
            }

            return model;
        }, [pageParams, sortParam, filter]);
}
