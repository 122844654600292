import React, { useState, useEffect } from "react";
import "./Label.scss";

type Props = {
  id?: string;
  htmlFor: string;
  children: string;
};

export const Label = ({ id, htmlFor, children }: Props) => {
  return (
    <React.Fragment>
      <div className="label-container" id={id}>
        <label htmlFor={htmlFor}>{children}</label>
      </div>
    </React.Fragment>
  );
};