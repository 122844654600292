import { DropOption } from "Components/FormComponents/FormComponetns.type";
import { container } from "inversify.config";
import { TYPES } from "inversify.types";
import { Ambulance } from "Models/Api/Ambulacne";
import { Examination } from "Models/Api/Examination/Examination";
import { Hospital } from "Models/Api/Hospital";
import { Patient } from "Models/Api/Patient";
import { User } from "Models/Api/User";
import { PageParam } from "Models/Common";
import { SortParam } from "Models/Common/SortParam";
import {
    IAmbulanceService,
    IEngineerService,
    IExaminationService,
    IHospitalService,
    IPatientService,
    IUserService,
} from "Services.Common/api";
import { useGetList } from "./useGet";
import { Engineer } from "Models/Api/Engineer";

export const useAmbulances = () => {
    let ambulanceService = container.get<IAmbulanceService>(
        TYPES.IAmbulanceService,
    );

    return useGetList<Ambulance, DropOption<string>>(
        () => ambulanceService.getAsync({ page: 1, pageSize: 100 }),
        (x) => ({ value: x.id, label: x.name }),
        [],
    );
};

export const useHospitals = (blocked?: boolean) => {
    let hospitaleService = container.get<IHospitalService>(
        TYPES.IHospitalService,
    );

    return useGetList<Hospital, DropOption<string>>(
        () =>
            hospitaleService.getAsync(
                { page: 1, pageSize: 100 },
                { sortName: "Name", sortOrder: "asc" } as SortParam,
                { blocked },
            ),
        (x) => ({ value: x.id, label: x.name }),
        [],
    );
};

export const useExaminations = (blocked?: boolean) => {
    let examinationService = container.get<IExaminationService>(
        TYPES.IExaminationService,
    );

    return useGetList<Examination, DropOption<string>>(
        () =>
            examinationService.getAsync(
                { page: 1, pageSize: 100 },
                { sortName: "Name", sortOrder: "asc" } as SortParam,
                { blocked },
            ),
        (x) => ({ value: x.id, label: x.name }),
        [],
    );
};

export const useGetUsers = (hospitalId?: string, deadline?: Date) => {
    let userService = container.get<IUserService>(TYPES.IUserService);

    return useGetList<User, DropOption<string>>(
        () =>
            userService.getAsync(
                { page: 1, pageSize: 100 },
                { sortName: "Name", sortOrder: "asc" } as SortParam,
                { hospitalId, deadline },
            ),
        (x) => ({ value: x.id, label: `${x.lastName} ${x.firstName}` }),
        [hospitalId, JSON.stringify(deadline)],
    );
};

export const useGetEngineers = () => {
    let engineerService = container.get<IEngineerService>(
        TYPES.IEngineerService,
    );

    return useGetList<Engineer, DropOption<string>>(
        () =>
            engineerService.getAsync(
                { page: 1, pageSize: 100 },
                { sortName: "Name", sortOrder: "asc" } as SortParam,
            ),
        (x) => ({ value: x.id, label: `${x.lastName} ${x.firstName}` }),
        [],
    );
};

export const useGetPatients = (search?: string) => {
    let patientService = container.get<IPatientService>(TYPES.IPatientService);

    return useGetList<Patient, DropOption<string>>(
        () =>
            patientService.getAsync({ page: 1, pageSize: 5 }, {} as SortParam, {
                search,
            }),
        (x) => ({ value: x.id, label: `${x.lastName} ${x.firstName}` }),
        [search],
    );
};
