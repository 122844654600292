import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from "@mui/material";
import { container } from "inversify.config";
import { IStoreageService } from "Services.Common/common";
import { TYPES } from "inversify.types";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import dayjs from "dayjs";

import release_notes from "../../../release_notes.json";

import "./ReleaseNoteModal.scss";

export const ReleaseNoteModal = () => {
  let storeageService = container.get<IStoreageService>(TYPES.IStoreageService);
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState<DialogProps["scroll"]>("paper");

  const handleClose = () => {
    storeageService.setReleaseNoteDate(dayjs(release_notes[0].date).toDate());
    setOpen(false);
  };

  const descriptionElementRef = React.useRef<HTMLElement>(null);

  useEffect(() => {
    const savedDate = dayjs(storeageService.getReleaseNoteDate());
    const loadedDate = dayjs(release_notes[0].date);
    
    if (savedDate.isBefore(loadedDate, "minute") || savedDate === undefined)
      setOpen(true);
  }, []);

  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
      >
        <DialogTitle id="scroll-dialog-title">Nova verzija</DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <div className="release-note-modal-container">
              <Markdown remarkPlugins={[remarkGfm]}>
                {release_notes[0].notes}
              </Markdown>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Zatvori</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
