import { Settings } from "Models/Common/Settings";
import { atom } from "recoil";

export const withRole = atom({
    key: "withRole",
    default: ""
});

export const withToken = atom({
    key: "withToken",
    default: ""
});

export const withSettings = atom({
    key: "withSettings",
    default: {
        wideForm: false
    } as Settings
});