import { FileForm } from "Components/FormComponents/FileForm/FileForm";
import { MedReportFilePost } from "Models/Api/MedReport/MedReportFilePost";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { usePostMedReportFile } from "./MedReportFiles.hooks";
import "./MedReportFiles.scss";

type Props = {
  medReportId: string;
  submit: boolean;
  setSubmit: (val: boolean) => void;
  onUploadFinish?: () => void;
  onHasFiles?: (val: boolean) => void;
};

export const MedReportFiles = ({
  medReportId,
  submit,
  setSubmit,
  onUploadFinish,
  onHasFiles,
}: Props) => {
  const {
    reset,
    control,
    watch,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<MedReportFilePost>({
    defaultValues: {
      medReportId,
    },
  });

  const [response, send, onFinish] = usePostMedReportFile(watch());
  const id = watch("medReportId")[0];

  useEffect(() => {
    setValue("medReportId", medReportId);
  }, [medReportId]);

  useEffect(() => {
    handleSubmit(async (data) => {
      if (submit && medReportId && id) {
        setSubmit(false);

        if (data.files !== undefined && data.files.length > 0) {
          await send();
        }
      }
    })();
  }, [submit, id]);

  onFinish(() => {
    reset();
    onUploadFinish && onUploadFinish();
  });

  return (
    <React.Fragment>
      <div className="med-report-files-container">
        <FileForm control={control} name="files" multiple onHasFiles={onHasFiles} />
      </div>
    </React.Fragment>
  );
};
