import React, { useState, useEffect } from "react";
import Tour from "reactour";

import "./EngineerBasicGuide.scss";
import { useRecoilState } from "recoil";
import { withEngineerGuideStart, withEngineerGuideStep } from "../Recoil/atom";

type Props = {
  stage: 1 | 2 | 3;
};

export const EngineerBasicGuide = ({ stage }: Props) => {
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [showCloseButton, setShowCloseButton] = useState(true);
  const [engineerGuideStart, setEngineerGuideStart] = useRecoilState(
    withEngineerGuideStart
  );

  useEffect(() => {
    if (engineerGuideStart)
      setTimeout(() => {
        setIsTourOpen(true);
      }, 300);
  }, [engineerGuideStart]);

  const steps1 = [
    {
      selector: "#med-reports",
      content:
        "U čekaonici se nalaze svi pacijenti koje unesete u sustav, a nisu poslani administratoru.",
      stepInteraction: false,
    },
    {
      selector: ".med-report-engineer-table-container",
      content: "Spremljeni pacijenti će biti prikazani u ovoj tablici.",
      stepInteraction: false,
    },
    {
      selector: "#archive",
      content:
        "U arhivi se nalaze svi pacijenti koje ste poslali administratoru.",
      stepInteraction: false,
    },
    {
      selector: "#table-add",
      content: "Klikni na tipku dodaj kako bi dodao pacijenta.",
    },
  ];

  const steps2 = [
    {
      selector: "#form-inputs",
      content: "Unesi podatke o pacijentu u ovu formu.",
    },
    {
      selector: "#save-patient",
      content: "Klikni na tipku spremi kako bi spremio pacijenta.",
    },
  ];

  const steps3 = [
    {
      selector: "#send-patient",
      content: "Pritiskom na tipku pošalji šaljete pacijenta administratoru.",
      stepInteraction: false,
    },
    {
      selector: "#new-patient",
      content:
        'Pritiskom na tipku "novi pacijent" vraćate se na praznu formu za dodavanje pacijenta.',
      stepInteraction: false,
    },
  ];

  const getSteps = () => {
    if (stage === 1) return steps1;
    if (stage === 2) return steps2;
    if (stage === 3) return steps3;

    return [];
  };

  return (
    <React.Fragment>
      <div className="engineer-basic-guide-container">
        <Tour
          steps={getSteps()}
          isOpen={isTourOpen}
          onRequestClose={() => {
            setIsTourOpen(false);
            setEngineerGuideStart(false);
          }}
          showCloseButton={showCloseButton}
          showNumber={false}
          rounded={5}
          disableFocusLock
        />
      </div>
    </React.Fragment>
  );
};
