import React, { useState, useEffect } from "react";
import "./Input.scss";

type Props = {
  id?: string;
  placeholder?: string;
  type?: string;
  value?: string | number;
  disabled?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
};

export const Input = ({
  id,
  placeholder,
  type,
  value,
  disabled,
  onChange,
}: Props) => {
  return (
    <React.Fragment>
      <div className="input-container" id={id}>
        <input
          className={disabled ? "disabled" : ""}
          type={type ?? "text"}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          disabled={disabled}
        />
      </div>
    </React.Fragment>
  );
};
