import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@mui/material";
import { Button } from "Components";
import React, { useState, useEffect } from "react";
import { PrintForm } from "./BuildRecordModal/BuildRecordModal.types";
import { BuildRecordModal } from "./BuildRecordModal/BuildRecordModal";

import "./MedReportPrintButton.scss";
type Props = {
  form: PrintForm;
  ambulanceId?: string;
  hospitalId?: string;
  fromDateCreated?: Date;
  toDateCreated?: Date;
};

export const MedReportPrintButton = ({
  form,
  ambulanceId,
  hospitalId,
  fromDateCreated,
  toDateCreated,
}: Props) => {
  const [print, setPrint] = useState(false);

  return (
    <React.Fragment>
      <div className="med-report-print-button-container">
        <Tooltip title="Printaj" placement="bottom">
          <Button
            bt="secondary"
            style={{ fontSize: "0.9em" }}
            icon={faPrint}
            onClick={() => setPrint(true)}
          >
            Printaj
          </Button>
          {/* <button onClick={() => setPrint(true)}>
            <FontAwesomeIcon icon={faPrint} />
          </button> */}
        </Tooltip>
      </div>

      <BuildRecordModal
        open={print}
        handleClose={() => setPrint(false)}
        form={form}
        ambulanceId={ambulanceId}
        hospitalId={hospitalId}
        fromDateCreated={fromDateCreated}
        toDateCreated={toDateCreated}
      />
    </React.Fragment>
  );
};
