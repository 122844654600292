import { injectable } from "inversify";
import { Response } from "Models/Common";
import { IResponseFactory } from "Services.Common/factory";

@injectable()
export class ResponseFactory implements IResponseFactory {
    maperror<T>(response: Response<object>): Response<T> {
        return {
            errors: response.errors,
            hasError: response.hasError
        } as Response<T>;
    }

    create<T>(): Response<T> {
        return {
            errors: [],
            hasError: false
        }
    }
}