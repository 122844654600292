import React, { useState, useEffect } from "react";
import "./FormMargin.scss";

type Props = {
  id?: string;
  children?: React.ReactNode;
};

export const FormMargin = ({ id, children }: Props) => {
  return (
    <React.Fragment>
      <div className="form-margin-container" id={id}>{children}</div>
    </React.Fragment>
  );
};
