import { container } from "inversify.config";
import { TYPES } from "inversify.types";
import { useEffect, useState } from "react";
import { IStoreageService } from "Services.Common/common";
import { Role } from "Components/Restrict/Restrict.type";
import { useNavigate } from "react-router-dom";

export const useValidateRole = (roles: Role[]) => {
    let storeageService = container.get<IStoreageService>(TYPES.IStoreageService);
    const [result, setResult] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        storeageService.getRole().then(role => {
            if (roles.includes(role.toUpperCase() as Role))
                setResult(true);
            else
                navigate("/login");
        });
    }, [])

    return result;
}