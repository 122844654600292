import { useEffect } from "react";

export const useOutsideAlerter = (ref: any, onOutsideClick: () => void, onInsideClick: () => void) => {
    let outside = false;

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (ref.current && !ref.current.contains(event.target)) {
                if (outside) {
                    onOutsideClick();
                    outside = false;
                }
            }
            else {
                onInsideClick();
                outside = true;
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [ref]);
}

export const useMultipleOutsideAlerter = (refs: any[], onOutsideClick: () => void, onInsideClick: () => void) => {
    let outside = false;

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            for (const ref of refs) {
                if (ref.current && !ref.current.contains(event.target)) {
                    if (outside) {
                        onOutsideClick();
                        outside = false;
                    }
                }
                else {
                    onInsideClick();
                    outside = true;
                }
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [refs]);
}