import React, { useState, useEffect, useRef } from "react";
import { Outlet } from "react-router-dom";
import { Logout } from "./Logout/Logout";
import { Sidebar } from "./Sidebar/Sidebar";
import { View } from "./View/View";
import { Offline, Online } from "react-detect-offline";
import { SupportChat } from "Components/SupportChat/SupportChat";
import { NoInternet } from "./NoInternet/NoInternet";
import { DevelopmentTag } from "./DevelopmentTag/DevelopmentTag";
import { ReleaseNoteModal } from "Components/Modals/ReleaseNoteModal/ReleaseNoteModal";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import { motion } from "framer-motion";
import { Navbar } from "./Navbar/Navbar";
import { useRecoilState } from "recoil";
import { withSidebarOpen } from "./Recoil/atom";

import "./Layout.scss";
import {
  useMultipleOutsideAlerter,
  useOutsideAlerter,
} from "Hooks/useOutsideAlerter";
import { Restrict } from "Components/Restrict/Restrict";

type Props = {
  // children: JSX.Element | JSX.Element[];
};

export const Layout = ({}: Props) => {
  const [sidebarOpen, setSidebarOpen] = useRecoilState(withSidebarOpen);

  const navbarRef = useRef<any>();
  const sidebarRef = useRef<any>();
  useMultipleOutsideAlerter(
    [navbarRef, sidebarRef],
    () => {
      setSidebarOpen(false);
      console.log("outside");
    },
    () => {}
  );

  return (
    <React.Fragment>
      <BrowserView>
        <div className="layout-container">
          <Sidebar />

          <Offline>
            <NoInternet />
          </Offline> 

          <View>
            <DevelopmentTag />
            <Outlet />
          </View>
        </div>
      </BrowserView>

      <MobileView>
        <Navbar />
        <div className="layout-container-mobile" ref={navbarRef}>
          <motion.div
            initial={{ left: "-250px" }}
            animate={{
              left: sidebarOpen ? 0 : "-250px",
              transition: { opacity: sidebarOpen ? 1 : 0 },
            }}
            exit={{ left: "-250px", transitionEnd: { opacity: 0 } }}
            className="floating-sidebar-container"
            ref={sidebarRef}
          >
            <Sidebar />
          </motion.div>

          <View>
            <DevelopmentTag />
            <Outlet />
          </View>
        </div>
      </MobileView>

      <ReleaseNoteModal />
      {/* <SupportChat /> */}
    </React.Fragment>
  );
};
