import React, { useState, useEffect } from "react";
import { useConfig } from "Hooks/useConfig";
import "./DevelopmentTag.scss";

export const DevelopmentTag = () => {
  const config = useConfig();

  if (config.stage === "prod") return <></>;

  return (
    <React.Fragment>
      <div className="development-tag-container">
        <p>Test</p>
      </div>
    </React.Fragment>
  );
};
