import { Response } from "Models/Common";
import { container } from "inversify.config";
import { TYPES } from "inversify.types";
import { MedReportAssistantChat } from "Models/Api/MedReport";
import { useState } from "react";
import { MedReportService } from "Services/api";
import { ResponseFactory } from "Services/factory";
import { useGet } from "Hooks/useGet";

export const useAssistantChat = () => {
    let resposneFactory = container.get<ResponseFactory>(TYPES.IResponseFactory);
    const [response, setResponse] = useState<Response<string>>();
    let medReportService = container.get<MedReportService>(TYPES.IMedReportService);

    const send = async (model: MedReportAssistantChat) => {
        try {
            const res = await medReportService.assistantChatAsync(model);
            setResponse(res);
        }
        catch (e) {
            const response = resposneFactory.create<string>();
            response.model = "Oprostite, došlo je do greške prilikom komunikacije sa serverom. Molimo pokušajte ponovo."
            setResponse(response);
        }
    }

    return [response, send] as const;
}

export const useClearAssigned = () => {
    let medReportService = container.get<MedReportService>(TYPES.IMedReportService);

    return  useGet(() => medReportService.clearAssignedAsync());
}
