import React, { useState, useEffect } from "react";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { useConfig } from "Hooks/useConfig";
import { useUserId } from "Hooks/useUserId";

import "./UserActivity.scss";

let sendInterval: NodeJS.Timeout;
let status = "active";

export const UserActivity = () => {
  const [connection, setConnection] = useState<HubConnection>();
  const userId = useUserId();

  const config = useConfig();

  const onFocusWindow = () => {
    status = "active";
  };

  const onBlurWindow = () => {
    status = "inactive";
  };

  useEffect(() => {
    let connection = new HubConnectionBuilder()
      .withUrl(config.apiURL + "/user-activity-hub")
      .build();

    setConnection(connection);

    window.addEventListener("focus", onFocusWindow);
    window.addEventListener("blur", onBlurWindow);

    return () => {
      window.removeEventListener("focus", onFocusWindow);
      window.removeEventListener("blur", onBlurWindow);
    };
  }, []);

  useEffect(() => {
    connection?.start();

    sendInterval = setInterval(() => {
      connection?.invoke("UserActiveAsync", userId, status);
    }, 10000);

    return () => {
      clearInterval(sendInterval);
    };
  }, [connection]);

  return (
    <React.Fragment />
  );
};