import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  withMedReportsPage,
  withMedReportsSort,
  withMedReportsSortEngineer,
} from "../Recoil/atom";
import { useFilter } from "Hooks/useFilter";
import { MedReportFilter } from "Models/Api/MedReport";
import { withEngineer } from "Recoil/User/atom";
import { Link, useNavigate } from "react-router-dom";
import { useAmbulances, useGetPatients, useHospitals } from "Hooks/useLookup";
import { useMedReportEngineerTable } from "./MedReportEngineerTable.hook";
import {
  Button,
  DatePicker,
  FormMargin,
  Grid,
  Input,
  MUIDropdown,
  Table,
  TruncateText,
} from "Components";
import dayjs from "dayjs";
import { withEngineerGuideStep } from "Features/Guides";
import { MedReportPrintButton } from "../MedReportPrintButton/MedReportPrintButton";
import { FilterSection } from "Components/Table/FilterSection/FilterSection";
import { PrintSection } from "Components/Table/PrintSection/PrintSection";

import "./MedReportEngineerTable.scss";

type Props = {
  type: "unfinished" | "finished";
};

export const MedReportEngineerTable = ({ type }: Props) => {
  const [pageParams, setPageParams] = useRecoilState(withMedReportsPage);
  const [sortParams, setSortParams] = useRecoilState(
    withMedReportsSortEngineer,
  );

  const [filter, setFilter, filterChanged, clearFilter] = useFilter({
    engineerFinished: type === "finished" ? true : false,
    fromDateCreated: dayjs()
      .utc()
      .subtract(1, "month")
      .startOf("day")
      .add(1, "minute")
      .toDate(),
    toDateCreated: dayjs().utc().startOf("day").add(1, "day").toDate(),
    patientId: {},
  } as MedReportFilter);

  const [patientSearch, setPatientSearch] = useState<string>("");

  const navigate = useNavigate();
  const [patients] = useGetPatients(patientSearch);
  const [ambulance] = useAmbulances();

  const [medReportsTable, fetchData, isLoaded] = useMedReportEngineerTable(
    pageParams,
    sortParams,
    filter,
  );

  useEffect(() => {
    if (!filterChanged) return;

    setFilter({
      toDateCreated: dayjs(filter.fromDateCreated).add(1, "day").toDate(),
    });
  }, [filter.fromDateCreated]);

  return (
    <React.Fragment>
      <div className="med-report-engineer-table-container">
        <Table
          title="Popis pacijenata"
          data={medReportsTable}
          columns={[
            { name: "patient", label: "Pacijent" },
            { name: "dateCreated", label: "Datum pregleda" },
            { name: "ambulance", label: "Ambulanta" },
            {
              element: (x) => {
                return (
                  <TruncateText text={x?.examinations ?? ""} maxLength={15} />
                );
              },
              label: "Pregled",
            },
            { name: "regionNum", label: "Broj regija" },
            { name: "contrast", label: "Kontrast" },
            {
              element: (x) => (
                <Button
                  bt="danger"
                  route={`/med-reports/engineer-edit/${x?.id}`}
                >
                  Pošalji
                </Button>
              ),
              label: "",
              hide: type === "finished",
            },
          ]}
          fill
          isLoaded={isLoaded}
          pageState={withMedReportsPage}
          route="/med-reports/engineer-add"
          disableAdd={type === "finished"}
          showActions={type === "unfinished" ? ["EDIT"] : ["PRINT"]}
          onRefresh={fetchData}
          filterChanged={filterChanged}
          onFilterClear={clearFilter}
          onEdit={(x) => {
            navigate(`/med-reports/engineer-edit/${x?.id}`);
          }}
          onDoubleClick={(x) => {
            if (type === "unfinished") {
              navigate(`/med-reports/engineer-edit/${x?.id}`);
            }
          }}
        >
          <FilterSection>
            <FormMargin>
              <Input
                placeholder="Pacijent"
                value={filter.patient}
                onChange={(x) => setFilter({ patient: x.target.value })}
              />
            </FormMargin>

            <FormMargin>
              <MUIDropdown
                type="editable"
                options={ambulance.items}
                value={filter.ambulanceId}
                onSelect={(x) => setFilter({ ambulanceId: x })}
                label="Ambulanta"
              />
            </FormMargin>

            <FormMargin>
              <Grid gridTemplateColumns="1fr 1fr">
                <DatePicker
                  label="od"
                  value={filter.fromDateCreated}
                  onChange={(x) =>
                    setFilter({
                      fromDateCreated: dayjs(x).toDate(),
                    })}
                  maxDate={filter.toDateCreated}
                />
                <DatePicker
                  label="do"
                  value={filter.toDateCreated}
                  onChange={(x) =>
                    setFilter({ toDateCreated: dayjs(x).toDate() })}
                  minDate={filter.fromDateCreated}
                />
              </Grid>
            </FormMargin>
          </FilterSection>

          {type === "finished" && (
            <>
              <PrintSection>
                <FormMargin>
                  <MUIDropdown
                    type="editable"
                    options={ambulance.items}
                    value={filter.ambulanceId}
                    onSelect={(x) => setFilter({ ambulanceId: x })}
                    label="Ambulanta"
                  />
                </FormMargin>

                <FormMargin>
                  <DatePicker
                    label="od"
                    value={filter.fromDateCreated}
                    onChange={(x) =>
                      setFilter({
                        fromDateCreated: dayjs(x).toDate(),
                      })}
                    maxDate={dayjs().toDate()}
                  />
                </FormMargin>

                <FormMargin>
                  <MedReportPrintButton
                    form="EngineerMedReport"
                    ambulanceId={filter.ambulanceId}
                    fromDateCreated={filter.fromDateCreated}
                  />
                </FormMargin>
              </PrintSection>
            </>
          )}
        </Table>
      </div>
    </React.Fragment>
  );
};
