import React, { useState, useEffect } from "react";
import "./PrintSection.scss";

type Props = {
  children?: React.ReactNode;
};

export const PrintSection = ({ children }: Props) => {
  return (
    <React.Fragment>
      <div className="print-section-container">{children}</div>
    </React.Fragment>
  );
};
