import { useEffect, useState } from "react"

export const useFormat = (includeOnly: ["year" | "month" | "day"] | undefined) => {
    const [result, setResult] = useState("DD.MM.YYYY.");

    useEffect(() => {
        let form = "";

        if (includeOnly === undefined) return;

        if (includeOnly.includes("day"))
            form += "DD."

        if (includeOnly.includes("month"))
            form += "MM."

        if (includeOnly.includes("year"))
            form += "YYYY."

        setResult(form);
    }, [includeOnly])

    return result;
}