import { useMatches } from "react-router-dom"
import { Handle, Path } from "./Breadcrumbs.types";

export const useBreadcrumbs = () => {
    const matches = useMatches()
        .filter(match => Boolean(match.handle))
        .filter(match => (match.handle as Handle).crumb)
        .map(match => ({ crumb: (match.handle as Handle).crumb, to: match.pathname } as Path))

    return matches;
}