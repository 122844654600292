import dayjs from "dayjs";
import { MedReportFilter } from "Models/Api/MedReport";
import { MedReport } from "Models/Api/MedReport/MedReport";
import { PageList, Response } from "Models/Common";
import { PageParam } from "Models/Common/PageParam";
import { SortParam } from "Models/Common/SortParam";
import { atom } from "recoil";

export const withMedReports = atom({
    key: 'withMedReports',
    default: {
        errors: [],
        hasError: false,
        model: undefined
    } as Response<PageList<MedReport>>
})

export const withMedReportsFiles = atom({
    key: 'withMedReportsFiles',
    default: {
        errors: [],
        hasError: false,
        model: undefined
    } as Response<PageList<MedReport>>
})

export const withMedReportsPage = atom({
    key: 'withMedReportsPage',
    default: {
        page: 1,
        pageSize: 10
    } as PageParam
})

export const withMedReportsPageHistory = atom({
    key: 'withMedReportsPageHistory',
    default: {
        page: 1,
        pageSize: 10
    } as PageParam
})

export const withMedReportsPageFinished = atom({
    key: 'withMedReportsPageFinished',
    default: {
        page: 1,
        pageSize: 10
    } as PageParam
})

export const withMedReportsPageFiles = atom({
    key: 'withMedReportsPageFiles',
    default: {
        page: 1,
        pageSize: 10
    } as PageParam
})

export const withMedReportsSort = atom({
    key: 'withMedReportsSort',
    default: {
        sortName: "Deadline",
        sortOrder: "asc"
    } as SortParam
})

export const withMedReportsSortFinished = atom({
    key: 'withMedReportsSortFinished',
    default: {
        sortName: "DateUpdated",
        sortOrder: "desc"
    } as SortParam
})

export const withMedReportsFilter = atom({
    key: 'withMedReportsFilter',
    default: {
        deadline: "inprocess",
        fromDate: dayjs().startOf("date").toDate(),
        toDate: dayjs().add(1, "month").startOf("date").toDate(),
        status: "notreturned",
        patientId: "",
        hospitalId: "",
        userId: "",
        ambulanceId: "",
    } as MedReportFilter
});

export const withMedReportsFilterFinished = atom({
    key: 'withMedReportsFilterFinished',
    default: {
        status: "returned",
        // fromDate: dayjs().startOf("date").toDate(),
        toDate: dayjs().add(1, "month").startOf("date").toDate(),
        hospitalId: {},
        userId: {},
    } as MedReportFilter
});

export const withMedReportsSortEngineer = atom({
    key: 'withMedReportsSortEngineer',
    default: {
        sortName: "DateCreated",
        sortOrder: "desc"
    } as SortParam
})
