import FileUploader from "Components/DragDropFiles/FileUploader";
import React, { useState, useEffect, useRef } from "react";
import "./File.scss";

type Props = {
  id?: string;
  name: string;
  onChange: ((arg0: File | File[] | FileList) => void) | undefined;
  multiple?: boolean;
};

export const File = ({ id, name, onChange, multiple }: Props) => {
  return (
    <React.Fragment>
      <div className="file-container" id={id}>
        {/* <input
          type="file"
          name={name}
          onChange={onChange}
          multiple={multiple}
        /> */}
        <FileUploader
          handleChange={onChange}
          classes="file-uploader file-uploader-zone"
          name={name}
          multiple={multiple}
          types={["JPG", "JPEG", "PDF", "PNG"]}
          label="Povuci datoteke ovdje ili klikni za odabir"
          hoverTitle="Povuci datoteke ovdje"
        />
      </div>
    </React.Fragment>
  );
};
