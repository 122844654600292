import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import "./FilterSidebar.scss";
import { Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid } from "Components";
import {
  faArrowRightToBracket,
  faCircleXmark,
  faFilterCircleXmark,
} from "@fortawesome/free-solid-svg-icons";

type Props = {
  children?: React.ReactNode;
  isOpen: boolean;
  showPrintSection?: boolean;
  onClose: () => void;
  onFilterClear?: () => void;
  onHasPrinter?: (hasPrinter: boolean) => void;
};

export const FilterSidebar = ({
  children,
  isOpen,
  onClose,
  showPrintSection,
  onFilterClear,
  onHasPrinter,
}: Props) => {
  const [printSection, setPrintSection] = useState<HTMLDivElement>();
  const [filterSection, setFilterSection] = useState<HTMLDivElement>();
  const filterRef = useRef<HTMLDivElement>(null);

  const manageSections = () => {
    if (!printSection || !filterSection) return;

    if (printSection) printSection?.remove();
    if (filterSection) filterSection?.remove();
    
    if (!showPrintSection) {
      printSection?.remove();
      filterRef.current?.appendChild(filterSection as HTMLDivElement);
    } else {
      filterSection?.remove();
      filterRef.current?.appendChild(printSection as HTMLDivElement);
    }
  };

  const setHasPrinter = () => {
    const hasPrinter = filterRef.current?.getElementsByClassName(
      "print-section-container"
    ).length ?? 0;

    if (onHasPrinter) onHasPrinter(hasPrinter > 0);
  };

  useEffect(() => {
    const PrintSection = filterRef.current?.getElementsByClassName(
      "print-section-container"
    )[0] as HTMLDivElement;
    const FilterSection = filterRef.current?.getElementsByClassName(
      "filter-section-container"
    )[0] as HTMLDivElement;

    const printSectionLength = filterRef.current?.getElementsByClassName(
      "print-section-container"
    ).length ?? 0;
    const filterSectionLength = filterRef.current?.getElementsByClassName(
      "filter-section-container"
    ).length ?? 0;
    
    for (let i = 0; i < printSectionLength; i++) {
      filterRef.current?.getElementsByClassName(
        "print-section-container"
      )[i]?.remove();
    }

    for (let i = 0; i < filterSectionLength; i++) {
      filterRef.current?.getElementsByClassName(
        "filter-section-container"
      )[i]?.remove();
    }

    if (PrintSection && !printSection) setPrintSection(PrintSection);

    if (FilterSection && !filterSection) setFilterSection(FilterSection);

    setHasPrinter();
    manageSections();
  }, [isOpen]);

  useEffect(() => {
   setHasPrinter(); 
  }, []);

  useEffect(() => {
    manageSections();
    setHasPrinter();
  }, [showPrintSection, filterSection, printSection]);

  return (
    <React.Fragment>
      {isOpen && (
        <motion.div
          initial={{ width: 0 }}
          animate={{ width: "300px", transitionEnd: { opacity: 1 } }}
          exit={{ width: 0, transitionEnd: { opacity: 0 } }}
          transition={{ duration: 0.2, opacity: 0 }}
          className="filter-sidebar-container"
        >
          <div className="filter-title-container">
            <h3 className="filter-title">{!showPrintSection ? "Odaberi filter" : "Odaberi filter i printaj"}</h3>
            <Tooltip title="Poništi sve filtere" placement="left">
              <button onClick={onFilterClear}>
                <FontAwesomeIcon icon={faFilterCircleXmark} />
              </button>
            </Tooltip>
            <Tooltip title="Zatvori filter" placement="bottom-start">
              <button onClick={onClose}>
                <FontAwesomeIcon icon={faArrowRightToBracket} />
              </button>
            </Tooltip>
          </div>
          <div ref={filterRef}>{children}</div>
        </motion.div>
      )}
    </React.Fragment>
  );
};
