import { usePost } from "Hooks/usePost";
import { MedReportAssigmentPost } from "Models/Api/MedReport";

import { IMedReportService } from "Services.Common/api";
import { container } from "inversify.config";
import { TYPES } from "inversify.types";
import { Form } from "./MedReportAssign.type";
import dayjs from "dayjs";

export const useAssignPost = (form: Form) => {
    let medReportService = container.get<IMedReportService>(TYPES.IMedReportService);
    const { id, userId, deadline } = form;

    return usePost(() => medReportService.assignAsync({ id, userId, deadline: dayjs().endOf("date").add(deadline + 1, "day").utc().toDate() }));
}
