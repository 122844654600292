import React, { useState, useEffect } from "react";
import "./MedReportAssignUser.scss";
import { useParams } from "react-router-dom";
import { useMedReport } from "Features/MedReport/MedReport.hooks";
import { Breadcrumbs, ViewTitle } from "Components";
import { MedReportAssign } from "Features/MedReport";
import { AuthRoute } from "Features/Auth/AuthRoute/AuthRoute";

export const MedReportAssignUser = () => {
  const { id } = useParams();
  const [medReport, fetch] = useMedReport(id ?? "");

  return (
    <React.Fragment>
      <AuthRoute roles={["SUPERADMIN", "ADMIN"]}>
        <div className="med-report-assign-user-container">
          <ViewTitle title="Dodijeli nalaz" />
          <Breadcrumbs />
          {medReport?.model && (
            <MedReportAssign
              id={medReport.model.id ?? ""}
              hospitalId={medReport.model.hospitalId}
            />
          )}
        </div>
      </AuthRoute>
    </React.Fragment>
  );
};
