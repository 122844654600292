import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import "./Logout.scss";
import { useLogout } from "./Logout.hook";
import { Tooltip } from "@mui/material";

type Prop = {};

export const Logout = ({}: Prop) => {
  const logout = useLogout();

  return (
    <React.Fragment>
      <div className="logout-container">
        <div onClick={logout} className="logout-btn-big">
          <FontAwesomeIcon icon={faRightFromBracket} /> Odjavi se
        </div>
        <div onClick={logout} className="logout-btn-small">
          <Tooltip title="Odjavi se" placement="right">
            <FontAwesomeIcon icon={faRightFromBracket} />
          </Tooltip>
        </div>
      </div>
    </React.Fragment>
  );
};
