import { useGet } from "Hooks/useGet";
import { container } from "inversify.config";
import { TYPES } from "inversify.types";
import { IHospitalService, IMedReportService } from "Services.Common/api";

export const useHospital = (hospitalId: string) => {
    let hospitalService = container.get<IHospitalService>(TYPES.IHospitalService);

    return useGet(() => hospitalService.getIdAsync(hospitalId), [hospitalId]);
}

export const useMedReport = (medReportId: string) => {
    let medReportService = container.get<IMedReportService>(TYPES.IMedReportService);

    return useGet(() => medReportService.getByIdAsync(medReportId), [medReportId]);
}