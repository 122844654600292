import {
  Button,
  DropdownForm,
  ErrorLabel,
  FormMargin,
  FormPaper,
  Grid,
  InputForm,
  Label,
  Paper,
} from "Components";
import { DatePickerForm } from "Components/FormComponents/DatePickerForm/DatePickerForm";
import dayjs from "dayjs";
import React, { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { usePostMedReport } from "./MedReportForm.hook";
import { Form } from "./MedReportForm.types";

import {
  useAmbulances,
  useExaminations,
  useGetUsers,
  useHospitals,
} from "Hooks/useLookup";
import { TextAreaForm } from "Components/FormComponents/TextAreaForm/TextAreaForm";
import { useHospital } from "../MedReport.hooks";
import { MedReportFiles } from "../MedReportFiles/MedReportFiles";

import "./MedReportForm.scss";
import { BrowserView, MobileView, isMobile } from "react-device-detect";

export const MedReportForm = () => {
  const {
    reset,
    control,
    watch,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<Form>({
    defaultValues: {
      contrast: false,
      userId: undefined,
      deadline: 0,
      firstName: "",
      lastName: "",
      year: dayjs(),
      remarks: "",
      examinationIds: [],
      hospitalId: "",
      ambulanceId: "",
    },
  });

  const deadline = watch(["deadline"])[0];

  const [submit, setSubmit] = React.useState(false);

  const [users] = useGetUsers(
    watch(["hospitalId"])[0],
    dayjs().endOf("date").add(deadline, "day").toDate()
  );
  const [ambulance] = useAmbulances();
  const [hospitals] = useHospitals(false);
  const [hospital] = useHospital(watch(["hospitalId"])[0]);
  const [examinations] = useExaminations(false);
  const [response, send] = usePostMedReport(watch());

  useEffect(() => {
    const amb = ambulance.items.find(
      (x) => x.value === watch("ambulanceId")
    )?.label;
    if (amb === "MMG") {
      const examinationLabel = "DOJKA";
      const examinationId = examinations.items.find(
        (x) => x.label === examinationLabel
      )?.value;

      setValue("examinationIds", [examinationId ?? ""]);
    }
  }, [watch("ambulanceId")]);

  return (
    <React.Fragment>
      <div className="med-report-form-container">
        <FormPaper>
          <FormMargin>
            <Grid gridTemplateColumns={isMobile ? "1fr 1fr" : "1fr 1fr 1fr"}>
              <div>
                <Label htmlFor="lastName">Prezime</Label>
                <InputForm
                  control={control}
                  name="lastName"
                  placeholder="Prezime"
                  rules={{ required: true }}
                />
                <ErrorLabel
                  type={errors.lastName?.type}
                  required="Prezime je obavezno polje"
                />
              </div>

              <div>
                <Label htmlFor="firstName">Ime</Label>
                <InputForm
                  control={control}
                  name="firstName"
                  placeholder="Ime"
                  rules={{
                    required: true,
                    // maxLength: 3,
                  }}
                />
                <ErrorLabel
                  type={errors.firstName?.type}
                  required="Ime je obavezno polje"
                />
              </div>

              <BrowserView>
                <div>
                  <Label htmlFor="year">Godina rođenja</Label>
                  <DatePickerForm
                    control={control}
                    name="year"
                    includeOnly={["year"]}
                    maxDate={dayjs().toDate()}
                  />
                  <ErrorLabel
                    type={errors.firstName?.type}
                    required="Godina rođenja je obavezno polje"
                  />
                </div>
              </BrowserView>
            </Grid>
          </FormMargin>

          <MobileView>
            <FormMargin>
              <Label htmlFor="year">Godina rođenja</Label>
              <DatePickerForm
                control={control}
                name="year"
                includeOnly={["year"]}
                maxDate={dayjs().toDate()}
              />
              <ErrorLabel
                type={errors.firstName?.type}
                required="Godina rođenja je obavezno polje"
              />
            </FormMargin>
          </MobileView>

          <Grid gridTemplateColumns="1fr 1fr">
            <FormMargin>
              <Label htmlFor="contrast">Kontrast</Label>
              <DropdownForm
                control={control}
                name="contrast"
                type="normal"
                defaultValue={false}
                options={[
                  { label: "NE", value: false },
                  { label: "DA", value: true },
                ]}
              />
              <ErrorLabel type={errors.contrast?.type} />
            </FormMargin>

            <FormMargin>
              <Label htmlFor="ambulanceId">Ambulanta</Label>
              <DropdownForm
                control={control}
                name="ambulanceId"
                type="normal"
                defaultValue=""
                options={ambulance.items}
                rules={{ required: true }}
              />
              <ErrorLabel type={errors.ambulanceId?.type} />
            </FormMargin>
          </Grid>

          <Grid gridTemplateColumns="1fr 1fr">
            <FormMargin>
              <Label htmlFor="hospital">Bolnica</Label>
              <DropdownForm
                control={control}
                name="hospitalId"
                type="editable"
                defaultValue=""
                options={hospitals.items}
                rules={{ required: true }}
              />
              <ErrorLabel type={errors.hospitalId?.type} />
            </FormMargin>

            <FormMargin>
              <Label htmlFor="deadline">Rok</Label>
              {/* <DatePickerForm
                control={control}
                name="deadline"
                minDate={dayjs()}
              /> */}

              <DropdownForm
                control={control}
                name="deadline"
                type="normal"
                disabled={hospital.model === undefined}
                defaultValue={0}
                options={
                  hospital.model
                    ? [
                        {
                          value: hospital.model.a,
                          label: `A (${hospital.model?.a}d)`,
                        },
                        {
                          value: hospital.model.b,
                          label: `B (${hospital.model?.b}d)`,
                        },
                        {
                          value: hospital.model.db,
                          label: `DB (${hospital.model?.db}d)`,
                        },
                        {
                          value: 1,
                          label: `Ž (1d)`,
                        },
                      ]
                    : []
                }
                rules={{ required: true, min: 1 }}
              />
              <ErrorLabel
                type={errors.deadline?.type}
                min="Polje je obavezno"
              />
            </FormMargin>
          </Grid>

          <Grid gridTemplateColumns="1fr 1fr">
            <FormMargin>
              <Label htmlFor="user">Radiolog</Label>
              <DropdownForm
                control={control}
                name="userId"
                rules={{ required: true }}
                type="editable"
                disabled={hospital.model === undefined || deadline === 0}
                options={users.items}
              />
              <ErrorLabel type={errors.userId?.type} />
            </FormMargin>

            <FormMargin>
              <Label htmlFor="examinations">Pregled</Label>
              <DropdownForm
                control={control}
                name="examinationIds"
                rules={{ required: true }}
                type="tag"
                options={examinations.items}
              />
              <ErrorLabel type={errors.examinationIds?.type} />
            </FormMargin>
          </Grid>

          <FormMargin>
            <Label htmlFor="remarks">Napomena</Label>
            <TextAreaForm
              control={control}
              placeholder="Unesi napomenu"
              name="remarks"
            />
          </FormMargin>

          <FormMargin>
            <MedReportFiles
              medReportId={response.model?.id ?? ""}
              submit={submit}
              setSubmit={setSubmit}
            />
          </FormMargin>

          <Button
            bt="primary"
            onClick={() =>
              handleSubmit(() =>
                send(() => {
                  reset();
                  setSubmit(true);
                })
              )()
            }
          >
            Potvrdi
          </Button>
        </FormPaper>
      </div>
    </React.Fragment>
  );
};
