import { Settings } from "Models/Common/Settings";
import { injectable } from "inversify";
import { Admin } from "Models/Api/Admin";
import { Engineer } from "Models/Api/Engineer";
import { User } from "Models/Api/User";
import { IStoreageService } from "Services.Common/common";

@injectable()
export class StoreageService implements IStoreageService {
    private readonly storeUser = "user";
    private readonly storeAdmin = "admin";
    private readonly storeEngineer = "engineer";
    private readonly storeToken = "token";
    private readonly storeRole = "role";
    private readonly storeSettings = "settings";

    async logout(): Promise<void> {
        localStorage.removeItem(this.storeUser);
        localStorage.removeItem(this.storeToken);
        localStorage.removeItem(this.storeRole);
        localStorage.removeItem(this.storeAdmin);
        localStorage.removeItem(this.storeEngineer);
    }

    async getUser(): Promise<User> {
        // const user = {
        //     firstName: "Tamer",
        //     lastName: "Salha",
        //     email: "tamer.salha@gmail.com",
        //     professionId: "91eac867-ed66-40d0-9380-90962fc56085",
        //     id: "52678120-124a-4d11-a9b1-b719d99f8061",
        //     dateCreated: new Date("2023-01-11T23:10:32.983516"),
        //     dateUpdated: new Date("2023-01-11T23:10:32.983516")
        // }

        const userData = JSON.parse(localStorage.getItem(this.storeUser) ?? "") as User;

        return userData;
    }

    async setUser(user: User) {
        await Promise.resolve().then(() => {
            localStorage.setItem(this.storeUser, JSON.stringify(user));
        });
    }

    async getAdmin(): Promise<Admin> {
        const adminData = JSON.parse(localStorage.getItem(this.storeAdmin) ?? "") as Admin;

        return adminData;
    }

    async setAdmin(user: Admin): Promise<void> {
        await Promise.resolve().then(() => {
            localStorage.setItem(this.storeAdmin, JSON.stringify(user));
        });
    }

    async getEngineer(): Promise<Engineer> {
        const engineerData = JSON.parse(localStorage.getItem(this.storeEngineer) ?? "") as Engineer;

        return engineerData;
    }

    async setEngineer(user: Engineer): Promise<void> {
        await Promise.resolve().then(() => {
            localStorage.setItem(this.storeEngineer, JSON.stringify(user));
        });
    }

    async getToken(): Promise<string> {
        return localStorage.getItem(this.storeToken) ?? "";
    }

    async setToken(token: string) {
        await Promise.resolve().then(() => {
            localStorage.setItem(this.storeToken, token);
        });
    }

    async getRole(): Promise<string> {
        return localStorage.getItem(this.storeRole) ?? "";
    }

    async setRole(role: string): Promise<void> {
        await Promise.resolve().then(() => {
            localStorage.setItem(this.storeRole, role);
        });
    }

    async setReleaseNoteDate(date: Date): Promise<void> {
        await Promise.resolve().then(() => {
            localStorage.setItem("releaseNoteDate", date.toString());
        });
    }

    getReleaseNoteDate(): Date {
        const date = localStorage.getItem("releaseNoteDate");

        if (!date) {
            return new Date(-8640000000000000);
        }

        return new Date(localStorage.getItem("releaseNoteDate") ?? "");
    }

    async setSettings(settings: Settings): Promise<void> {
        await Promise.resolve().then(() => {
            localStorage.setItem(this.storeSettings, JSON.stringify(settings));
        });
    }

    async getSettings(): Promise<Settings> {
        if (!localStorage.getItem(this.storeSettings)) {
            const settings = {
                wideForm: false
            } as Settings

            await Promise.resolve().then(() => {
                localStorage.setItem(this.storeSettings, JSON.stringify(settings));
            });
            return Promise.resolve(settings);
        }

        const settings = JSON.parse(localStorage.getItem(this.storeSettings) ?? "") as Settings;

        return Promise.resolve(settings);
    }
}