import { IEngineerService } from "Services.Common/api/IEngineerService";
import { BaseService } from "./BaseService";
import { Engineer, EngineerFilter, EngineerPut } from "Models/Api/Engineer";
import { injectable } from "inversify";
import { PageParam, Response, PageList } from "Models/Common";
import { SortParam } from "Models/Common/SortParam";

@injectable()
export class EngineerService extends BaseService<Engineer> implements IEngineerService{
    baseUrl = "/api/engineer";

    getAsync(pageParam: PageParam, sortParam?: SortParam, filter?: EngineerFilter, includeItems?: string[]): Promise<Response<PageList<Engineer>>> {
        return this.fetchAsync(pageParam, sortParam, filter, includeItems);
    }

    updateAsync(engineer: EngineerPut): Promise<Response<Engineer>> {
        return this.putAsync(engineer);
    }

    getIdAsync(id: string): Promise<Response<Engineer>> {
        return this.fetchByIdAsync(id);
    }
}