import { injectable } from "inversify";
import { PageList } from "Models/Common";
import { IPageFactory } from "Services.Common/factory";

@injectable()
export class PageFactory implements IPageFactory {
    create<T>(): PageList<T> {
        return {
            items: [],
            page: 0,
            pageSize: 0,
            totalPages: 0
        }
    }

    mapPage<D, T>(source: PageList<D>, mapFunc: (items: D) => T): PageList<T> {
        let page = this.create<T>();
        page.items = source.items.map(x => mapFunc(x))
        page.page = source.page;
        page.pageSize = source.pageSize;
        page.totalPages = source.totalPages;

        return page;
    }
}