export const notificationMessage = {
    pending: 'Molim pričekati',
    success: 'Uspješno dodano',
    error: 'Došlo je do pogreške'
}

export const updateMessage = {
    ...notificationMessage,
    success: "Uspješno ažurirano"
}

export const notificationError = {
    error: 'Došlo je do pogreške',
}

export const notificationPending = {
    pending: 'Molim pričekati',
}

export const loginMessage = {
    ...notificationMessage,
    success: "Uspješno logirano",
    error: "Pogrešno korisničko ime ili lozinka"
}

export const reportMessage = {
    ...notificationMessage,
    success: "Uspješno vraćen nalaz"
}

export const deleteMessage = {
    ...notificationMessage,
    success: "Uspješno obrisano"
}

export const blockedMessage = {
    ...notificationMessage,
    success: "Uspješno blokirano"
}

export const unblockedMessage = {
    ...notificationMessage,
    success: "Uspješno odblokirano"
}

export const installedAppMessage = {
    ...notificationMessage,
    success: "Aplikacija je uspješno instalirana"
}

export const filesMessage = {
    ...notificationMessage,
    success: "Datoteke su uspješno dodane",
    error: "Došlo je do pogreške prilikom dodavanja datoteka"
}

export const lockUserMessage = {
    ...notificationMessage,
    success: "Korisnik je uspješno blokiran"
}

export const unlockUserMessage = {
    ...notificationMessage,
    success: "Korisnik je uspješno odblokiran"
}

export const savedMessage = {
    ...notificationMessage,
    success: "Uspješno spremljeno"
}

export const sentToAdministatorMessage = {
    ...notificationMessage,
    success: "Uspješno poslano"
}

export const updatedProfilePictureMessage = {
    ...notificationMessage,
    success: "Profilna slika je uspješno ažurirana"
}

export const updatedProfileMessage = {
    ...notificationMessage,
    success: "Profil je uspješno ažuriran"
}

export const messageSentMessage = {
    ...notificationMessage,
    success: "Poruka je uspješno poslana"
}