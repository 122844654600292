import React, { useState, useEffect } from "react";
import Badge, { BadgeProps } from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import MailIcon from "@mui/icons-material/Mail";
import { useRecoilState } from "recoil";
import { withMessageCount } from "Recoil/Message/atom";

import "./MessageNotificationIcon.scss";
import { useMessageCount } from "Features/Message/Message.hooks";

export const MessageNotificationIcon = () => {
  const [messageCount, setMessageCount] = useRecoilState(withMessageCount);
  const [messageCountResponse, fetchMessageCount] = useMessageCount();

  useEffect(() => {
    fetchMessageCount();
  }, []);

  useEffect(() => {
    setMessageCount(messageCountResponse.model ?? 0);
  }, [messageCountResponse]);

  const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    "& .MuiBadge-badge": {
      right: 21,
      top: 1,
      padding: 0,
    },
  }));

  return (
    <React.Fragment>
      <StyledBadge color="info" badgeContent={messageCount} max={10}>
        <MailIcon style={{ fontSize: 20 }} />
      </StyledBadge>
    </React.Fragment>
  );
};
