import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  useTheme,
} from "@mui/material";
import { DropdownType, DropOption, Val } from "../FormComponetns.type";
import { v4 } from "uuid";
import { SingleValue } from "./MUIDropdown.type";
import { Cancel } from "@mui/icons-material";
import "./MUIDropdown.scss";

type Props<T extends Val> = {
  id?: string;
  options: DropOption<T>[];
  value: T;
  onSelect: (value: T) => void;
  type?: DropdownType;
  label?: string;
  width?: number;
  height?: number;
  disabled?: boolean;
  defaultValue?: T;
  disableNotSelected?: boolean;
  filterOptions?: (options: DropOption<T>[], state: any) => DropOption<T>[];
  onElement?: (option: DropOption<T>) => React.ReactNode;
};

export const MUIDropdown = <T extends Val>({
  id,
  options,
  value,
  onSelect,
  label,
  type,
  width,
  height,
  disabled,
  defaultValue,
  disableNotSelected,
  filterOptions,
  onElement,
}: Props<T>) => {
  const labelId = v4();

  const ITEM_HEIGHT = 250;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT,
        width: 250,
      },
    },
  };

  const [selectedTag, setSelectedTag] = useState<T[]>(
    value ? (JSON.parse(JSON.stringify(value)) as T[]) : [],
  );

  useEffect(() => {
    setSelectedTag(value ? (JSON.parse(JSON.stringify(value)) as T[]) : []);
  }, [value]);

  const isJson = (item: any) => {
    if (typeof item !== "string") {
      return false;
    }

    try {
      JSON.parse(item);
      return true;
    } catch (e) {
      return false;
    }
  };

  const isListOfJson = (item: any[]) => {
    if (item.length === 0) {
      return false;
    }

    for (const i of item) {
      if (!isJson(i)) {
        return false;
      }
    }

    return true;
  };

  const parseValue = (val: any) => {
    if (isJson(val)) {
      return JSON.parse(val as string);
    } else if (isListOfJson(val)) {
      return val.map((v: any) => JSON.parse(v as string));
    }

    return val;
  };

  const parseOption = (option: DropOption<T>): DropOption<T> => {
    return {
      label: option.label,
      value: parseValue(option.value),
    };
  };

  if (type === "tag") {
    return (
      <div className="mui-tag-container" id={id}>
        <FormControl fullWidth={width === undefined}>
          <InputLabel id={labelId}>{label}</InputLabel>
          <Select
            labelId={labelId}
            className="mui-tag"
            multiple
            sx={{ width, padding: "0px", minHeight: height ?? 43 }}
            // value={(value as T[]).map(v => JSON.stringify(v)) ?? []}
            value={value ?? []}
            disabled={disabled}
            onChange={(x) => {
              const val = x.target.value;
              const vals = JSON.parse(JSON.stringify(val)) as T[];

              onSelect(parseValue(vals) as T);
              setSelectedTag(vals);
            }}
            input={<OutlinedInput label={label} />}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selectedTag.map((val) => {
                  const lab = options.find((x) =>
                    x.value === val
                  );

                  if (!lab) {
                    return <></>;
                  }

                  return (
                    <Chip
                      sx={{}}
                      key={v4()}
                      label={lab?.label}
                      onMouseDown={(e) => e.stopPropagation()}
                      deleteIcon={
                        <button className="delete-tag">
                          <Cancel />
                        </button>
                      }
                      // onDelete={(e) => {
                      //   e.stopPropagation();
                      //   setSelectedTag(v => v.filter(x => {
                      //     console.log(x, val);
                      //     return x !== val
                      //   }));
                      // }}
                    />
                  );
                })}
              </Box>
            )}
            MenuProps={MenuProps}
          >
            {
              /* <MenuItem value={defaultValue} disabled>
            <em>Nije odabrano</em>
          </MenuItem> */
            }
            {options.map((val, i) => (
                <MenuItem
                  className="menu-item"
                  key={i}
                  value={val.value as SingleValue}
                >
                  <Checkbox
                    style={{ marginRight: "8px" }}
                    checked={selectedTag.find((x) => x === val.value) !==
                        undefined &&
                      Array.isArray(value) &&
                      value.length > 0}
                  />
                  {val.label}
                  <div className="custom-component">
                    {onElement && onElement(parseOption(val))}
                  </div>
                </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  }

  if (type === "editable") {
    return (
      <div className="mui-editable-container" id={id}>
        <Autocomplete
          size="small"
          disablePortal
          value={options.find((x) => x.value === value) || null}
          defaultValue={null}
          options={options}
          noOptionsText="Nema rezultata"
          sx={{ width, height: height ?? 43 }}
          className="mui-editable"
          disabled={disabled}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              InputLabelProps={{ shrink: true }}
              placeholder="Pretraži.."
            />
          )}
          onChange={(event: any, newValue: DropOption<T> | null) => {
            onSelect(newValue?.value ?? ({} as T));
          }}
          filterOptions={filterOptions}
        />
      </div>
    );
  }

  return (
    <div className="mui-normal-container" id={id}>
      <FormControl fullWidth={width === undefined}>
        {label && <InputLabel id={labelId}>{label}</InputLabel>}
        <Select
          size="small"
          className="mui-normal"
          labelId={labelId}
          value={value}
          sx={{ width, height: height ?? 43 }}
          disabled={disabled}
          displayEmpty
          label={label}
          // defaultValue={defaultValue ?? value}
          onChange={(x) => onSelect(x.target.value as T)}
        >
          {!disableNotSelected && (
            <MenuItem value={defaultValue as SingleValue} disabled>
              <em className="none-item">Nije odabrano</em>
            </MenuItem>
          )}
          {options.map((x, i) => (
            <MenuItem key={i} value={x.value as SingleValue}>
              {x.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
