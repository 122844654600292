import { PageParam } from "Models/Common/PageParam";
import React, { useState, useEffect } from "react";
import Pagination from "react-responsive-pagination";
import { useSearchParams } from "react-router-dom";
import { RecoilState, useRecoilState } from "recoil";

import "./Paginator.scss";

type Props = {
  pageState: RecoilState<PageParam>;
  total: number;
};

export const Paginator = ({ pageState, total }: Props) => {
  const [pageParams, setPageParams] = useRecoilState(pageState);
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <React.Fragment>
      <div className="paginator-container">
        <Pagination
          current={pageParams.page}
          total={total ?? 0}
          onPageChange={(x) => {
            const model = {
              ...pageParams,
              page: x
            } as PageParam;

            setPageParams(model);
            setSearchParams({
              page: model.page.toString(),
              pageSize: model.pageSize.toString(),
            });
          }}
          maxWidth={5}
        />
      </div>
    </React.Fragment>
  );
};
