import { atom } from "recoil";

export const withGuidess = atom({
    key: 'withGuidess',
    default: ""
})

export const withEngineerGuideStep = atom({
    key: 'withEngineerGuideStep',
    default: 1 as number
})

export const withEngineerGuideStart = atom({
    key: 'withEngineerGuideStart',
    default: false as boolean
})