import { container } from "inversify.config";
import { TYPES } from "inversify.types";
import { IStoreageService } from "Services.Common/common";
import { useNavigate } from "react-router-dom";
import { IAuthorizationService } from "Services.Common/api";

export const useLogout = () => {
    const navigate = useNavigate();

    const logout = () => {
        let storeageService = container.get<IStoreageService>(
            TYPES.IStoreageService,
        );
        let authorizationService = container.get<IAuthorizationService>(
            TYPES.IAuthorizationService,
        );

        authorizationService.logoutAsync().then(() => {
            storeageService.logout();
            navigate("/login");
        });
    };

    return logout;
};
