import { container } from "inversify.config";
import { TYPES } from "inversify.types";
import { useEffect, useState } from "react";
import { PageList, Response } from "Models/Common";
import { IPageFactory, IResponseFactory } from "Services.Common/factory";

export const useGetList = <T, M>(promise: () => Promise<Response<PageList<T>>>, mapper: (x: T) => M, dependancy: any[], dissable?: boolean) => {
    let pageFactory = container.get<IPageFactory>(TYPES.IPageFactory);

    const [response, setResponse] = useState(pageFactory.create<M>());
    const [isLoaded, setIsLoaded] = useState(false);

    const dataFetch = async () => {
        if (dissable) return;

        setIsLoaded(false);
        const res = (await promise()).model;

        if (!res) return pageFactory.create();

        const resPage = pageFactory.mapPage(res, mapper);
        
        setResponse(resPage);
        setIsLoaded(true);
    }

    useEffect(() => { dataFetch(); }, dependancy);
    useEffect(() => { dataFetch(); }, [])

    return [response, dataFetch, isLoaded] as const;
}

export const useGet = <T>(promise: () => Promise<Response<T>>, dependancy?: any[]) => {
    let responseFactory = container.get<IResponseFactory>(TYPES.IResponseFactory);

    const [response, setResponse] = useState<Response<T>>(responseFactory.create());

    const dataFetch = async () => {
        try {
            const res = await promise();

            if (res) {
                setResponse(res);
            }
        } catch (e) {
            console.log(e);
        }
    }
    
    useEffect(() => {
        if (dependancy) {
            dataFetch();
        }
    }, dependancy);

    return [response, dataFetch] as const;
}
