import React, { useState, useEffect } from "react";
import "./TruncateText.scss";
import { Tooltip } from "@mui/material";

type Props = {
  text: string;
  maxLength: number;
};

export const TruncateText = ({ text, maxLength }: Props) => {
  return (
    <React.Fragment>
      <div className="truncate-text-container">
        <Tooltip title={text} placement="top-start" disableHoverListener={text.length <= maxLength}>
          <p>{text.length > maxLength ? text.substring(0, maxLength) + "..." : text}</p>
        </Tooltip>
      </div>
    </React.Fragment>
  );
};
