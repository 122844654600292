type Config = {
    apiURL: string;
    apiHttpURL: string;
    stage?: "dev" | "test" | "prod";
}

export const configDev = {
    apiURL: 'http://localhost:5081',
    apiHttpURL: 'http://localhost:5081',
    stage: "dev"
} as Config;

export const configProd = {
    apiURL: 'https://teleradiology-salha.com',
    apiHttpURL: 'http://teleradiology-salha.com',
    stage: "prod"
    //apiURL: 'https://localhost:44362'
} as Config;

export const configTest = {
    apiURL: 'https://test.teleradiology-salha.com',
    apiHttpURL: 'http://test.teleradiology-salha.com',
    stage: "test"
} as Config;

export const useConfig = (): Config => {
    let config: Config = configProd;

    if (process.env.NODE_ENV === 'development') {
        config = configDev;
    }
    else if (process.env.REACT_APP_ENVIRONMENT === 'test') {
        config = configTest;
    }

    return config;
}
