import { MUIDropdown, Table, Restrict, FormMargin, Grid, TruncateText } from "Components";
import { DatePicker } from "Components/FormComponents/DatePicker/DatePicker";
import dayjs from "dayjs";
import { useFilter } from "Hooks/useFilter";
import { useAmbulances, useGetPatients, useHospitals } from "Hooks/useLookup";
import { MedReportFilter } from "Models/Api/MedReport";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import { MedReportStatus } from "../MedReportStatus/MedReportStatus";
import {
  withMedReportsFilter,
  withMedReportsPage,
  withMedReportsSort,
} from "../Recoil/atom";
import { useMedReportUserTable } from "./MedReportUserTable.hook";
import utc from "dayjs/plugin/utc";
import "./MedReportUserTable.scss";

export const MedReportUserTable = () => {
  const [pageParams, setPageParams] = useRecoilState(withMedReportsPage);
  const [sortParams, setSortParams] = useRecoilState(withMedReportsSort);
  const [filter, setFilter, filterChanged, clearFilter] = useFilter({
    deadline: "inprocess",
    fromDateCreated: dayjs().utc().subtract(1, "month").startOf("date").toDate(),
    toDateCreated: dayjs().utc().add(1, "day").startOf("date").toDate(),
    status: "notreturned",
    patientId: {},
    hospitalId: {},
    userId: {},
    ambulanceId: {},
  } as MedReportFilter);

  const [patientSearch, setPatientSearch] = useState<string>("");

  const navigate = useNavigate();
  const [patients] = useGetPatients(patientSearch);
  const [hospitals] = useHospitals();
  const [ambulance] = useAmbulances();

  const [medReportsTable, fetchData, isLoaded] = useMedReportUserTable(
    pageParams,
    sortParams,
    filter
  );

  return (
    <React.Fragment>
      <Table
        title="Popis pacijenata"
        data={medReportsTable}
        columns={[
          { name: "patient", label: "Pacijent" },
          { name: "dateCreated", label: "Datum pregleda" },
          { name: "ambulance", label: "Ambulanta" },
          { element: (x) => {
            return <TruncateText text={x?.examinations ?? ""} maxLength={15} />;
          }, label: "Pregled" },
          { name: "regionNum", label: "Broj regija" },
          { name: "contrast", label: "Kontrast" },
          { name: "hospital", label: "Bolnica" },
          // { name: "remarks", label: "Napomena" },
          { name: "deadline", label: "Rok" },
          {
            element: (x) => (
              <MedReportStatus
                report={x.report}
                returned={x.returned}
                deadline={x.deadlineValue}
                comment={x.comment}
              />
            ),
            label: "Status",
          },
        ]}
        fill
        isLoaded={isLoaded}
        pageState={withMedReportsPage}
        showActions={["VIEW"]}
        onView={(x) => {
          if (x?.comment)
            return toast.info("Molim pričekajte da se izvrši dopuna pregleda");
          navigate(`/med-reports/finish/${x?.id}`);
        }}
        onRefresh={fetchData}
        onDoubleClick={(x) => {
          if (x?.comment)
            return toast.info("Molim pričekajte da se izvrši dopuna pregleda");
          navigate(`/med-reports/finish/${x?.id}`);
        }}
        filterChanged={filterChanged}
        onFilterClear={clearFilter}
      >
        <FormMargin>
          <MUIDropdown
            type="editable"
            options={patients.items}
            value={filter.patientId}
            onSelect={(x) => setFilter({ patientId: x })}
            filterOptions={(options, state) => {
              setPatientSearch(state.inputValue);

              return patients.items;
            }}
            label="Pacijent"
          />
        </FormMargin>

        <FormMargin>
          <MUIDropdown
            type="editable"
            options={ambulance.items}
            value={filter.ambulanceId}
            onSelect={(x) => setFilter({ ambulanceId: x })}
            label="Ambulanta"
          />
        </FormMargin>

        <FormMargin>
          <MUIDropdown
            type="editable"
            options={hospitals.items}
            value={filter.hospitalId}
            onSelect={(x) => setFilter({ hospitalId: x })}
            label="Bolnice"
          />
        </FormMargin>

        <FormMargin>
          <MUIDropdown
            disableNotSelected
            options={[
              { value: "notreturned", label: "Sve" },
              { value: "inprogress", label: "U tijeku" },
              { value: "commented", label: "Dopuna u tijeku" },
            ]}
            value={filter.status}
            onSelect={(x) => setFilter({ status: x })}
            label="Status"
          />
        </FormMargin>

        <FormMargin>
          <Grid gridTemplateColumns="1fr 1fr">
            <DatePicker
              label="od"
              value={filter.fromDateCreated}
              onChange={(x) =>
                setFilter({ fromDateCreated: dayjs(x).toDate() })
              }
              maxDate={filter.toDateCreated}
            />
            <DatePicker
              label="do"
              value={filter.toDateCreated}
              onChange={(x) => setFilter({ toDateCreated: dayjs(x).toDate() })}
              minDate={filter.fromDateCreated}
            />
          </Grid>
        </FormMargin>
      </Table>
    </React.Fragment>
  );
};
