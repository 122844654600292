import { Button, Dialog, FormControlLabel, Switch } from "@mui/material";
import {
  FormMargin,
  DropdownForm,
  Label,
  Grid,
  ErrorLabel,
  Dropdown,
} from "Components";
import { DatePickerForm } from "Components/FormComponents/DatePickerForm/DatePickerForm";
import dayjs from "dayjs";
import { useAmbulances, useGetEngineers, useHospitals } from "Hooks/useLookup";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import "./BuildRecordModal.scss";
import { PrintForm, RecordFilterModel } from "./BuildRecordModal.types";
import { container } from "inversify.config";
import { IStoreageService } from "Services.Common/common";
import { TYPES } from "inversify.types";
import { Engineer } from "Models/Api/Engineer";
import { toast } from "react-toastify";

type Props = {
  open: boolean;
  handleClose: () => void;
  form: PrintForm;
  ambulanceId?: string;
  hospitalId?: string;
  fromDateCreated?: Date;
  toDateCreated?: Date;
};

export const BuildRecordModal = ({
  open,
  handleClose,
  form,
  ambulanceId,
  hospitalId,
  fromDateCreated,
  toDateCreated,
}: Props) => {
  const [engineer, setEngineer] = useState<Engineer>();
  const [defaultValues, setDefaultValue] = useState<RecordFilterModel>(
    {} as RecordFilterModel
  );

  let storeageService = container.get<IStoreageService>(TYPES.IStoreageService);

  useEffect(() => {
    storeageService.getEngineer().then(setEngineer).catch(console.error);
  }, []);

  const getDefaultValues = () => {
    if (form === "MedReportRecord")
      return {
        ambulanceId,
        hospitalId,
        fromDateCreated,
        toDateCreated,
      } as RecordFilterModel;

    if (form === "HistoryMedReport") {
      return {
        ambulanceId,
        hospitalId,
        fromDateCreated,
        toDateCreated: dayjs().add(1, "day").startOf("date").toDate(),
      } as RecordFilterModel;
    }

    return {
      ambulanceId,
      hospitalId: "",
      fromDateCreated,
      toDateCreated: dayjs().add(1, "day").startOf("date").toDate(),
    } as RecordFilterModel;
  };

  useEffect(() => {
    setValue("ambulanceId", ambulanceId ?? "");
    setValue("hospitalId", hospitalId ?? "");
    setValue(
      "fromDateCreated",
      fromDateCreated ?? getDefaultValues().fromDateCreated
    );
    setValue(
      "toDateCreated",
      toDateCreated ?? getDefaultValues().toDateCreated
    );
  }, [ambulanceId, hospitalId, fromDateCreated, toDateCreated]);

  const {
    reset,
    control,
    watch,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<RecordFilterModel>({
    defaultValues: getDefaultValues(),
  });

  const navigate = useNavigate();
  const [includeUser, setIncludeUser] = useState(true);
  const [hospitals] = useHospitals();
  const [ambulance] = useAmbulances();

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="comment-modal"
      >
        <div
          className="build-record-modal-container"
          style={{ width: "500px" }}
        >
          {form === "MedReportRecord" && (
            <FormControlLabel
              control={
                <Switch
                  onChange={(val) => setIncludeUser(val.target.checked)}
                  checked={includeUser}
                />
              }
              label={includeUser ? "Uključi radiologa" : "Uključi FDMZ"}
            />
          )}

          <FormMargin>
            <Label htmlFor="ambulanceId">Ambulanta</Label>
            <h5>
              {ambulance.items.find((x) => x.value === watch().ambulanceId)
                ?.label ?? "Nije odabrana ambulanta"}
            </h5>
            <ErrorLabel type={errors.ambulanceId?.type} />
          </FormMargin>

          {(form === "MedReportRecord" || form === "HistoryMedReport") && (
            <FormMargin>
              <Label htmlFor="hospital">Bolnica</Label>
              <h5>
                {hospitals.items.find((x) => x.value === watch().hospitalId)
                  ?.label ?? "Nije odabrana bolnica"}
              </h5>
              <ErrorLabel type={errors.hospitalId?.type} />
            </FormMargin>
          )}

          {form === "MedReportRecord" && (
            <FormMargin>
              <Label htmlFor="date">Datum</Label>
              <h5>
                {dayjs(fromDateCreated).format("DD.MM.YYYY")} -{" "}
                {dayjs(toDateCreated).format("DD.MM.YYYY")}
              </h5>
            </FormMargin>
          )}

          {(form === "HistoryMedReport" || form === "EngineerMedReport") && (
            <FormMargin>
              <Label htmlFor="fromDateCreated">Datum pregleda</Label>
              <h5>{dayjs(fromDateCreated).format("DD.MM.YYYY")}</h5>
            </FormMargin>
          )}

          <FormMargin>
            <Button
              onClick={() => {
                handleSubmit(() => {
                  const urlStringReport =
                    "/print-med-reports?" +
                    `ambulanceId=${watch().ambulanceId}&hospitalId=${
                      watch().hospitalId
                    }&fromDateCreated=${
                      watch().fromDateCreated
                    }&toDateCreated=${watch().toDateCreated}&includeUser=${
                      includeUser ? "true" : "false"
                    }`;

                  const urlStringEngineerList =
                    "/print-engineer-list?" +
                    `ambulanceId=${watch().ambulanceId}&hospitalId=${
                      watch().hospitalId
                    }&dateCreated=${watch().fromDateCreated}`;

                  const urlStringEngineer =
                    "/print-engineer-med-reports?" +
                    `ambulanceId=${watch("ambulanceId")}&engineerId=${
                      engineer?.id
                    }&dateCreated=${watch("fromDateCreated")}`;

                  if (ambulanceId === undefined || ambulanceId === "") {
                    toast.error("Nije odabrana ambulanta");
                    return;
                  }

                  if (
                    (form === "MedReportRecord" ||
                      form === "HistoryMedReport") &&
                    (hospitalId === undefined || hospitalId === "")
                  ) {
                    toast.error("Nije odabrana bolnica");
                    return;
                  }

                  if (form === "MedReportRecord")
                    window.open(urlStringReport, "_blank", "noreferrer");
                  if (form === "EngineerMedReport")
                    window.open(urlStringEngineer, "_blank", "noreferrer");
                  if (form === "HistoryMedReport")
                    navigate(urlStringEngineerList);
                  handleClose();
                })();
              }}
              color="primary"
              autoFocus
            >
              Printaj
            </Button>
            <Button onClick={handleClose} color="warning">
              Odbaci
            </Button>
          </FormMargin>
        </div>
      </Dialog>
    </React.Fragment>
  );
};
