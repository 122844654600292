import dayjs from "dayjs";
import { useDelete } from "Hooks/useDelete";
import { useGet, useGetList } from "Hooks/useGet";
import { container } from "inversify.config";
import { TYPES } from "inversify.types";
import { MedReport, MedReportFilter } from "Models/Api/MedReport";
import { PageParam } from "Models/Common/PageParam";
import { SortParam } from "Models/Common/SortParam";
import { IMedReportService } from "Services.Common/api";
import { IUtilityService } from "Services.Common/common";
import { MedReportTableModel } from "./MedReportTable.type";

export const useMedReportTable = (
    pageParam: PageParam,
    sortParam: SortParam,
    filter: MedReportFilter,
    type: string,
) => {
    let medReportService = container.get<IMedReportService>(
        TYPES.IMedReportService,
    );
    let utilityService = container.get<IUtilityService>(TYPES.IUtilityService);

    const getDeadline = (
        comment: string,
        deadline: Date,
        hospitalExamination?: boolean,
        urgentExamination?: boolean,
    ) => {
        if (
            !(comment ||
                dayjs(deadline).isSame(dayjs("0001-01-01T00:00:00.000Z")))
        ) {
            return utilityService.getTimeDifference(new Date(deadline));
        } else if (urgentExamination) {
            return "Žurno";
        } else if (hospitalExamination) {
            return "Bolnički";
        } else {
            return "";
        }
    };

    return useGetList<MedReport, MedReportTableModel>(
        () =>
            medReportService.getAsync(pageParam, sortParam, filter, [
                "Patient",
                "User",
                "Ambulance",
                "Hospital",
            ]),
        (x) => {
            const model: MedReportTableModel = {
                id: x.id as string,
                patient:
                    `${x.patient?.lastName} ${x.patient?.firstName}, ${x.patient?.year}`,
                contrast: x.contrast ? "DA" : "NE",
                remarks: x.remarks,
                doctor: x.user ? `${x.user.lastName} ${x.user.firstName}` : "",
                deadline: getDeadline(
                    x.comment,
                    x.deadline,
                    x.hospitalExamination,
                    x.urgentExamination,
                ),
                dateCreated: dayjs(x.dateCreated).format("DD.MM.YYYY."),
                deadlineValue: x.deadline,
                examinations: x.examinations.map((y) => y.name).join(" + "),
                regionNum: x.examinations.length,
                ambulance: x.ambulance.name,
                hospital: x.hospital.name,
                report: x.report,
                returned: x.returned,
                comment: x.comment,
                userId: x.userId,
            };

            return model;
        },
        [pageParam, sortParam, filter],
        type !== "medReport",
    );
};

export const useUnasignedMedReportTable = (
    pageParam: PageParam,
    filter: MedReportFilter,
    type: string,
) => {
    let medReportService = container.get<IMedReportService>(
        TYPES.IMedReportService,
    );
    let utilityService = container.get<IUtilityService>(TYPES.IUtilityService);
    
    const getDeadline = (
        comment: string,
        deadline: Date,
        hospitalExamination?: boolean,
        urgentExamination?: boolean,
    ) => {
        if (
            !(comment ||
                dayjs(deadline).isSame(dayjs("0001-01-01T00:00:00.000Z")))
        ) {
            return utilityService.getTimeDifference(new Date(deadline));
        } else if (urgentExamination) {
            return "Žurno";
        } else if (hospitalExamination) {
            return "Bolnički";
        } else {
            return "";
        }
    };

    return useGetList<MedReport, MedReportTableModel>(
        () => medReportService.assignedMedReportAsync(pageParam, filter),
        (x) => {
            const model: MedReportTableModel = {
                id: x.id as string,
                patient:
                    `${x.patient?.lastName} ${x.patient?.firstName}, ${x.patient?.year}`,
                contrast: x.contrast ? "DA" : "NE",
                remarks: x.remarks,
                doctor: x.user ? `${x.user.lastName} ${x.user.firstName}` : "",
                deadline: getDeadline(
                    x.comment,
                    x.deadline,
                    x.hospitalExamination,
                    x.urgentExamination,
                ),
                dateCreated: dayjs(x.dateCreated).format("DD.MM.YYYY."),
                deadlineValue: x.deadline,
                examinations: x.examinations.map((y) => y.name).join(" + "),
                regionNum: x.examinations.length,
                ambulance: x.ambulance.name,
                hospital: x.hospital.name,
                report: x.report,
                returned: x.returned,
                comment: x.comment,
                userId: x.userId,
            };

            return model;
        },
        [pageParam, filter],
        type !== "medReportUnassigned",
    );
};

export const useMedReportDelete = (id: string) => {
    let medReportService = container.get<IMedReportService>(
        TYPES.IMedReportService,
    );

    return useDelete(() => medReportService.deleteAsync(id), id);
};

export const useAssignMedReports = () => {
    let medReportService = container.get<IMedReportService>(
        TYPES.IMedReportService,
    );

    return useGet<boolean>(() => medReportService.assignMedReportAsync()) 
}
