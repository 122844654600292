import React, { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { withAdmin, withEngineer, withUser } from "Recoil/User/atom";
import { DownloadApp } from "Components/DownloadApp/DownloadApp";
import { Avatar, Badge, Tooltip } from "@mui/material";
import { useConfig } from "Hooks/useConfig";
import { Settings } from "Components/Layout/Settings/Settings";

import "./Profile.scss";

export const Profile = () => {
  const user = useRecoilValue(withUser);
  const admin = useRecoilValue(withAdmin);
  const engineer = useRecoilValue(withEngineer);
  const [userId, setUserId] = useState<string>("");
  const config = useConfig();

  const [fullName, setFullName] = useState<string>("");
  const [initials, setInitials] = useState<string>("");

  useEffect(() => {
    if (user) {
      setFullName(`${user.lastName} ${user.firstName}`);
      setInitials(`${user.lastName[0]}${user.firstName[0]}`);
      setUserId(user.id ?? "");
    } else if (admin) {
      setFullName(`${admin.lastName} ${admin.firstName}`);
      setInitials(`${admin.lastName[0]}${admin.firstName[0]}`);
      setUserId(admin.id ?? "");
    } else if (engineer) {
      setFullName(`${engineer.lastName} ${engineer.firstName}`);
      setInitials(`${engineer.lastName[0]}${engineer.firstName[0]}`);
      setUserId(engineer.id ?? "");
    }
  }, [user, admin, engineer]);

  return (
    <React.Fragment>
      <div className="profile-container">
        <div className="top-section">
          <div className="avatar-container">
            <Tooltip title={fullName} placement="right">
              {/* <div className="avatar">{initials}</div> */}
              <Avatar
                alt={initials}
                className="profile-image"
                src={`${config.apiURL}/api/ProfileImage/${userId}`}
              >
                {initials}
              </Avatar>
            </Tooltip>
          </div>

          <div className="name-container">
            <div>
              <p className="degree">{user?.degree && `${user.degree} `}</p>
              <p className="full-name">
                {fullName}
                {user?.academicDegree && ","}
                {engineer && ","}
              </p>
              <p className="degree">
                {user?.academicDegree && `${user.academicDegree}`}
                {(!user && engineer) && "bacc. rad. tech."}
              </p>
            </div>
          </div>
        </div>

        <div className="bottom-section">
          <Settings />
          <DownloadApp />
        </div>

        {/* <p className="email">{user?.email ?? admin?.email}</p> */}
      </div>
    </React.Fragment>
  );
};
