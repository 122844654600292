import { Base } from "react-detect-offline";
import { BaseService } from "./BaseService";
import { MalfunctionReportSend } from "Models/Api/MalfunctionReport";
import { IMalfunctionReportService } from "Services.Common/api";
import { Response } from "Models/Common";
import { injectable } from "inversify";
import axios from "axios";

@injectable()
export class MalfunctionReportService implements IMalfunctionReportService {
    baseUrl = "/api/malfunctionReport";

    async sendAsync(form: MalfunctionReportSend): Promise<Response<boolean>> {
        return (await axios.post<Response<boolean>>(this.baseUrl, form)).data;
    }
}