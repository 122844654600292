import React, { useEffect, useState } from "react";
import format from "date-fns/format";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  withGreedeAlgorithmMessage,
  withHospitalPriorityMessage,
} from "../Recoil/atom";

import "./InitialMessage.scss";

type Props = {
  onButtonClicked?: (value: string) => void;
};

export const InitialMessage = ({ onButtonClicked }: Props) => {
  const greedeAlgorithmMessage = useRecoilValue(withGreedeAlgorithmMessage);
  const hospitalPriorityMessage = useRecoilValue(withHospitalPriorityMessage);

  return (
    <React.Fragment>
      <div className="initial-message-container">
        <div className="rcw-message">
          <div className="rcw-response">
            <div className="rcw-message-text">
              <p>
                Pozdrav! Ja sam vaš asistent. Kako vam mogu pomoći?
              </p>
              <br />
              <button onClick={() => onButtonClicked?.("greede")} className="reply-button">{greedeAlgorithmMessage}</button>
              <button onClick={() => onButtonClicked?.("hospitalPriority")} className="reply-button">
                {hospitalPriorityMessage}
              </button>
            </div>
            <span className="rcw-timestamp">
              {format(new Date(), "hh:mm")}
            </span>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
