import React, { useState, useEffect } from "react";
import "./PageButtonList.scss";

type Props = {
  children: JSX.Element | JSX.Element[];
};

export const PageButtonList = ({ children }: Props) => {
  return (
    <React.Fragment>
      <div className="page-button-list-container">{children}</div>
    </React.Fragment>
  );
};
