import "reflect-metadata";
import { injectable } from "inversify";
import { Hospital, HospitalBlock, HospitalFilter, HospitalPost, HospitalPut } from "Models/Api/Hospital";
import { IHospitalService } from "Services.Common/api";
import { BaseService } from "./BaseService";
import { Response, PageList, PageParam } from "Models/Common";
import { SortParam } from "Models/Common/SortParam";

@injectable()
export class HospitalService extends BaseService<Hospital> implements IHospitalService {
    baseUrl = "/api/hospital"

    async getAsync(pageParam: PageParam, sortParam?: SortParam, filter?: HospitalFilter, includeItems?: string[]): Promise<Response<PageList<Hospital>>> {
        return this.fetchAsync(pageParam, sortParam, filter, includeItems);
    }

    async getIdAsync(id: string): Promise<Response<Hospital>> {
        return this.fetchByIdAsync(id);
    }

    async createAsync(medReportPost: HospitalPost): Promise<Response<Hospital>> {
        return this.postAsync(medReportPost);
    }

    async updateAsync(medReportPut: HospitalPut): Promise<Response<Hospital>> {
        return this.putAsync(medReportPut);
    }

    async deleteAsync(id: string): Promise<Response<boolean>> {
        return this.removeAsync(id);
    }

    async blockAsync(hospitalBlock: HospitalBlock): Promise<Response<Hospital>> {
        return this.postAndReturnAsync<HospitalBlock, Hospital>(hospitalBlock, "/block");
    }
}