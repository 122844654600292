import React, { useState, useEffect } from "react";
import "./TextArea.scss";

type Props = {
  id?: string;
  placeholder?: string;
  value?: string;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement> | undefined;
  rows?: number;
};

export const TextArea = ({ id, placeholder, value, rows, onChange }: Props) => {
  return (
    <React.Fragment>
      <div className="text-area-container" id={id}>
        <textarea
          rows={rows ?? 4}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
        />
      </div>
    </React.Fragment>
  );
};
