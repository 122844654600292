import { Dayjs } from "dayjs";
import React, { useState, useEffect } from "react";
import { Control, Controller, FieldValues, Path } from "react-hook-form";
import { DatePicker } from "Components/FormComponents/DatePicker/DatePicker";

import "./DatePickerForm.scss";

type Props<T extends FieldValues> = {
  id?: string;
  name: Path<T>;
  control?: Control<T, any> | undefined;
  includeOnly?: ["year" | "month" | "day"] | undefined;
  maxDate?: Date;
  minDate?: Date;
  disabled?: boolean;
};

export const DatePickerForm = <T extends FieldValues>({
  id,
  name,
  control,
  includeOnly,
  maxDate,
  minDate,
  disabled,
}: Props<T>) => {
  return (
    <React.Fragment>
      <div className="date-picker-form-container" id={id}>
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, value } }) => (
            <DatePicker
              includeOnly={includeOnly}
              maxDate={maxDate}
              minDate={minDate}
              value={value}
              onChange={onChange}
              disabled={disabled}
            />
          )}
        />
      </div>
    </React.Fragment>
  );
};
