import axios from "axios";
import { injectable } from "inversify";
import { User, UserFilter, UserProfilePut, UserPut } from "Models/Api/User";
import { UserHospital } from "Models/Api/User/UserHospital";
import { UserHospitalFilter } from "Models/Api/User/UserHospitalFilter";
import { PageParam, Response, PageList } from "Models/Common";
import { SortParam } from "Models/Common/SortParam";
import { IUserService } from "Services.Common/api/IUserService";
import { BaseService } from "./BaseService";

@injectable()
export class UserService extends BaseService<User> implements IUserService {
    baseUrl = "/api/user";

    getAsync(pageParam: PageParam, sortParam?: SortParam, filter?: UserFilter) {
        return this.fetchAsync(pageParam, sortParam, filter);
    }

    getNotAvailableAsync(id: string, pageParam: PageParam, filter?: UserHospitalFilter): Promise<Response<PageList<UserHospital>>> {
        return axios.get<Response<PageList<UserHospital>>>(this.baseUrl + `/not-available/${id}`, { params: { ...pageParam, ...filter }, paramsSerializer: { indexes: true } }).then(x => x.data);
    }

    availableAsync(model: UserHospital): Promise<Response<boolean>> {
        return this.postAndReturnAsync<UserHospital, boolean>(model, "/availability");
    }

    removeAvailableAsync(userId: string, hospitalId: string): Promise<Response<boolean>> {
        return axios.delete<Response<boolean>>(this.baseUrl + `/remove-available/${userId}/${hospitalId}`).then(x => x.data);
    }

    updateAsync(user: UserPut): Promise<Response<User>> {
        return this.putAsync(user);
    }

    updateProfileAsync(user: UserProfilePut): Promise<Response<User>> {
        return this.putAsync(user, "/profile");
    }

    getIdAdync(id: string): Promise<Response<User>> {
        return this.fetchByIdAsync(id);
    }
}
