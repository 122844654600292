import { Response } from "Models/Common";
import { notificationMessage } from "Models/Common/NotificationMessages";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

export const usePost = <T>(promise: () => Promise<Response<T>>, message?: object) => {
    const [response, setResponse] = useState<Response<T>>();
    let finishFunc: () => Promise<void> | void | undefined;

    useEffect(() => {
        const execute = async () => {
            if (response !== undefined && !response?.hasError) {
                await finishFunc?.();
            }
        }

        execute();
    }, [response])

    const send = async () => {
        const resPromise = promise();
        const res = await toast.promise(resPromise, message ?? notificationMessage);
        setResponse(res);
    }

    const onFinish = async (finish: () => Promise<void> | void | undefined) => {
        finishFunc = finish;
    }

    return [response, send, onFinish] as const;
}

export const usePostAll = <T>(promises: (() => Promise<Response<T>>)[], message?: object) => {
    const [responses, setResponses] = useState<Response<T>[]>([]);
    let finishFunc: () => Promise<void> | void;

    useEffect(() => {
        const execute = async () => {
            if (responses.length > 0 && !responses.some(res => res.hasError)) {
                await finishFunc?.();
            }
        }

        execute();
    }, [responses])

    const send = async () => {
        const resPromises = promises.map(promise => promise());
        const res = await toast.promise(Promise.all(resPromises), message ?? notificationMessage);
        setResponses(res);
    }

    const onFinish = async (finish: () => Promise<void> | void) => {
        finishFunc = finish;
    }

    return [responses, send, onFinish] as const;
}
