import React, { useState, useEffect } from "react";
import "./Margin.scss";

type Props = {
  children?: React.ReactNode;
  size?: "sm" | "md" | "lg";
};

export const Margin = ({ children, size }: Props) => {
  const getMargin = () => {
    if (size === "sm") {
      return "5px";
    }
    if (size === "md") {
      return "10px";
    }
    if (size === "lg") {
      return "20px";
    }

    return "10px";
  };

  return (
    <React.Fragment>
      <div className="margin-container" style={{ margin: getMargin() }}>
        {children}
      </div>
    </React.Fragment>
  );
};
