import { withAdmin, withEngineer, withUser } from "Recoil/User/atom";
import { useRecoilValue } from "recoil"

export const useUserId = () => {
    const user = useRecoilValue(withUser);
    const admin = useRecoilValue(withAdmin);
    const engineer = useRecoilValue(withEngineer);

    if (user) {
        return user.id;
    }

    if (admin) {
        return admin.id;
    }

    if (engineer) {
        return engineer.id;
    }

    return "";
}