import dayjs from "dayjs";
import { injectable } from "inversify";
import { IUtilityService } from "Services.Common/common";

@injectable()
export class UtilityService implements IUtilityService {
    getTimeDifference(date: Date): string {
        const hours = dayjs(date).diff(dayjs(), 'hours');
        const days = dayjs(date).diff(dayjs(), 'days');
        let result = "";

        if (hours <= 24 && hours > -24)
            result = hours + 'h';
        else
            result = `${days}d ${hours - days * 24}h`

        return result;
    }
}
