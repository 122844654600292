import { container } from "inversify.config";
import { TYPES } from "inversify.types";
import { Response } from "Models/Common";
import { MedReport, MedReportPost } from "Models/Api/MedReport";
import { User } from "Models/Api/User";
import { useEffect, useState } from "react";
import { IExaminationService, IHospitalService, IMedReportService } from "Services.Common/api";
import { IPatientService } from "Services.Common/api/IPatientService";
import { IUserService } from "Services.Common/api/IUserService";
import { IResponseFactory } from "Services.Common/factory";
import { Patient, PatientPost } from "Models/Api/Patient";
import { Form } from "./MedReportForm.types";
import { toast } from "react-toastify";
import { notificationError, notificationMessage } from "Models/Common/NotificationMessages";
import { Examination } from "Models/Api/Examination/Examination";
import { useGet } from "Hooks/useGet";
import dayjs from "dayjs";


//TODO: Change this
export const usePostMedReport = (form: Form) => {
    let patientService = container.get<IPatientService>(TYPES.IPatientService);
    let medReportService = container.get<IMedReportService>(TYPES.IMedReportService);
    let responseFactory = container.get<IResponseFactory>(TYPES.IResponseFactory);

    const [response, setResponse] = useState<Response<MedReport>>(responseFactory.create<MedReport>());
    const { firstName, lastName, year, contrast, userId, deadline, examinationIds, ambulanceId, hospitalId, remarks } = form;

    const send = async (reset: () => void) => {
        const patientResponse = patientService.createAsync({ firstName, lastName, year: year.year() });
        const patient = await toast.promise(patientResponse, notificationError);

        if (patient.hasError || !patient.model?.id) {
            setResponse(responseFactory.maperror<MedReport>(patient));
            return;
        }

        const medReportResponse = medReportService.createAsync({ contrast, userId, examinationIds, remarks, deadline: dayjs().endOf("date").add(deadline + 1, "day").utc().toDate(), patientId: patient.model.id, ambulanceId, hospitalId });
        const medReport = await toast.promise(medReportResponse, notificationMessage);
        
        if (!medReport.hasError) reset();

        setResponse(medReport);
    }

    return [response, send] as const;
}
