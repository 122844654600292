import { IMessageService } from "Services.Common/api";
import { BaseService } from "./BaseService";
import { Message, MessageFilter, MessagePost, UserMessage } from "Models/Api/Messages";
import { PageParam, Response, PageList } from "Models/Common";
import { SortParam } from "Models/Common/SortParam";
import { injectable } from "inversify";
import { AllUsers } from "Models/Common/AllUsers";

@injectable()
export class MessageService extends BaseService<Message> implements IMessageService{
    baseUrl = "/api/message";

    getAsync(pageParam: PageParam, sortParam?: SortParam | undefined, filter?: MessageFilter | undefined, includeItems?: string[] | undefined): Promise<Response<PageList<Message>>> {
        return this.fetchAsync(pageParam, sortParam, filter, includeItems);
    }

    getByIdAsync(id: string): Promise<Response<Message>> {
        return this.fetchByIdAsync(id);
    }

    allUsersAsync(id: string): Promise<Response<AllUsers>> {
        return this.fetchFromRouteAsync<AllUsers>(`/all-users/${id}`);
    }

    createAsync(model: MessagePost): Promise<Response<Message>> {
        return this.postAsync<MessagePost>(model);
    }

    deleteAsync(id: string): Promise<Response<boolean>> {
        return this.removeAsync<boolean>(id);
    }

    getUserMessagesAsync(pageParam: PageParam, sortParam?: SortParam, filter?: MessageFilter): Promise<Response<PageList<UserMessage>>> {
        return this.fetchWithDifferentModelAsync<UserMessage>(pageParam, sortParam, undefined, undefined, "/user-messages");
    }

    readMessageAsync(id: string): Promise<Response<Message>> {
        return this.fetchFromRouteAsync<Message>(`/read-message/${id}`);
    }

    unreadMessageCountAsync(): Promise<Response<number>> {
        return this.fetchFromRouteAsync<number>("/unread-message-count");
    }
}
