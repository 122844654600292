import React, { useState, useEffect } from "react";
import "./ViewTitle.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "@mui/material";

type Prop = {
  title: string;
  subTitle?: string;
  onInfoClick?: () => void;
};

export const ViewTitle = ({ title, subTitle, onInfoClick }: Prop) => {
  return (
    <React.Fragment>
      <div className="view-title-container">
        <div>
          <h1>{title}</h1>
          {subTitle && <p>{subTitle}</p>}
        </div>
        {onInfoClick && <Tooltip title="Vodič" placement="left">
          <button onClick={onInfoClick} className="info-button">
            <FontAwesomeIcon icon={faInfo} />
          </button>
        </Tooltip>}
      </div>
    </React.Fragment>
  );
};
