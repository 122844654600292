import React, { useState, useEffect } from "react";
import { Control, Controller, FieldValues, Path, ValidationRule } from "react-hook-form";
import { Dropdown } from "Components/FormComponents/Dropdown/Dropdown";
import { DropdownOption, DropdownType, DropOption, Val, Value } from "../FormComponetns.type";

import "./DropdownForm.scss";
import { MUIDropdown } from "../MUIDropdown/MUIDropdown";

type Props<T extends FieldValues, V extends Val> = {
  id?: string;
  options: DropOption<V>[];
  name: Path<T>;
  control?: Control<T, any> | undefined;
  rules?: {
    required?: boolean;
    maxLength?: number;
    minLength?: number;
    max?: number;
    min?: number;
  };
  type: DropdownType;
  disabled?: boolean;
  defaultValue?: V;
  onElement?: (option: DropOption<V>) => React.ReactNode;
};

const modifyOptions = (options: DropOption<Val>[]): DropOption<Val>[] => {
  return options.map((option) => {
    return {
      label: option.label,
      value: typeof option.value == "object" ? JSON.stringify(option.value) : option.value,
    };
  });
}

const modifyValue = (value: Val): Val => {
  if (Array.isArray(value)) {
    return value.map((v) => {
      return typeof v == "object" ? JSON.stringify(v) : v;
    }) as Val;
  }

  return value;
}

export const DropdownForm = <T extends FieldValues, V extends Val>({
  id,
  name,
  options,
  control,
  rules,
  type,
  disabled,
  defaultValue,
  onElement,
}: Props<T, V>) => {
  return (
    <React.Fragment>
      <div className="dropdown-form-container" id={id}>
        <Controller
          control={control}
          name={name}
          rules={rules}
          render={({ field: { onChange, value } }) => (
            // <Dropdown options={options} onSelect={onChange}SDFKJASDFK
            //   value={value}
            //   type={type}
            // />

            <MUIDropdown
              options={modifyOptions(options)}
              onSelect={onChange}
              onElement={onElement}
              value={modifyValue(value)}
              type={type}
              height={43}
              disabled={disabled}
              defaultValue={defaultValue}
            />
          )}
        />
      </div>
    </React.Fragment>
  );
};
