import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Modal,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import "./ConfirmationModal.scss";

type Props = {
  title: string;
  description: string;
  confirm?: string;
  decline?: string;
  onConfirm: () => void;
  onDecline: () => void;
  open: boolean;
};

export const ConfirmationModal = ({
  title,
  description,
  confirm,
  decline,
  onConfirm,
  onDecline,
  open,
}: Props) => {
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    // border: "2px solid #000",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
  };

  return (
    <React.Fragment>
      <div className="confirmation-modal-container">
        {/* <Modal
          open={open}
          onClose={onDecline}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h3">
              {title}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {description}
            </Typography>
          </Box>
        </Modal> */}

        <Dialog
          open={open}
          onClose={onDecline}
          style={{ borderRadius: "10px" }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {description}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={onConfirm} color="warning">
              {confirm ?? "Potvrdi"}
            </Button>
            <Button onClick={onDecline} autoFocus>
              {decline ?? "Odbaci"}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </React.Fragment>
  );
};
