import React, { useState, useEffect } from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { FormControlLabel, FormGroup, Switch, Tooltip } from "@mui/material";
import { useRecoilState } from "recoil";
import { withSettings } from "Recoil/LocalStoreage/atom";
import { container } from "inversify.config";
import { IStoreageService } from "Services.Common/common";
import { TYPES } from "inversify.types";

import "./Settings.scss";

export const Settings = () => {
  let storeageService = container.get<IStoreageService>(TYPES.IStoreageService);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [settings, setSettings] = useRecoilState(withSettings);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <React.Fragment>
      <div className="settings-container">
        <Tooltip title="Postavke" placement="right">
          <button onClick={handleClick}>
            <FontAwesomeIcon icon={faGear} />
          </button>
        </Tooltip>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Typography sx={{ p: 2 }}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={settings.wideForm}
                    onChange={() => {
                      setSettings({
                        ...settings,
                        wideForm: !settings.wideForm,
                      });

                      storeageService
                        .setSettings({
                          ...settings,
                          wideForm: !settings.wideForm,
                        })
                        .catch(console.log);
                    }}
                  />
                }
                label="Široke forme"
              />
            </FormGroup>
          </Typography>
        </Popover>
      </div>
    </React.Fragment>
  );
};
