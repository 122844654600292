import React, { useState, useEffect } from "react";
import { container } from "inversify.config";
import { useSetRecoilState } from "recoil";
import { IStoreageService } from "Services.Common/common";
import { withAdmin, withEngineer, withUser } from "Recoil/User/atom";
import { TYPES } from "inversify.types";
import { withRole, withSettings, withToken } from "Recoil/LocalStoreage/atom";

import "./InitialLoad.scss";

export const InitialLoad = () => {
  let storeageService = container.get<IStoreageService>(TYPES.IStoreageService);
  const setUser = useSetRecoilState(withUser);
  const setAdmin = useSetRecoilState(withAdmin);
  const setEngineer = useSetRecoilState(withEngineer);
  const setRole = useSetRecoilState(withRole);
  const setToken = useSetRecoilState(withToken);
  const setSettings = useSetRecoilState(withSettings);

  useEffect(() => {
    storeageService.getUser().then(setUser).catch(console.log);
    storeageService.getAdmin().then(setAdmin).catch(console.log);
    storeageService.getEngineer().then(setEngineer).catch(console.log);
    storeageService.getRole().then(setRole).catch(console.log);
    storeageService.getToken().then(setToken).catch(console.log);
    storeageService.getSettings().then(setSettings).catch(console.log);
  }, []);

  return (<></>);
};