import { injectable } from "inversify";
import { Ambulance } from "Models/Api/Ambulacne";
import { Response, PageList, PageParam } from "Models/Common";
import { IAmbulanceService } from "Services.Common/api/IAmbulanceService";
import { BaseService } from "./BaseService";

@injectable()
export class AmbulanceService extends BaseService<Ambulance> implements IAmbulanceService {
    baseUrl = "/api/ambulance"

    async getAsync(pageParam: PageParam): Promise<Response<PageList<Ambulance>>> {
        return this.fetchAsync(pageParam);
    }
}