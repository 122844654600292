import {
  faBookMedical,
  faBoxArchive,
  faBug,
  faClipboardUser,
  faFile,
  faFileExcel,
  faGear,
  faGraduationCap,
  faHospital,
  faNotesMedical,
  faStethoscope,
  faUser,
  faUserDoctor,
  faUserNurse,
} from "@fortawesome/free-solid-svg-icons";
import { Restrict } from "Components/Restrict/Restrict";
import React, { useRef } from "react";
import { Logout } from "../Logout/Logout";
import { PageButton } from "../PageButton/PageButton";
import { PageButtonList } from "../PageButtonList/PageButtonList";
import { Profile } from "../Profile/Profile";
import { VersionNumber } from "../VersionNumber/VersionNumber";
import { MessageNotificationIcon } from "Components/MessageNotificationIcon/MessageNotificationIcon";
import { useRecoilState } from "recoil";
import { withSidebarOpen } from "../Recoil/atom";

import "./Sidebar.scss";

export const Sidebar = () => {
  const [sidebarOpen, setSidebarOpen] = useRecoilState(withSidebarOpen);

  return (
    <React.Fragment>
      <div className="sidebar-container">
        <Profile />
        <PageButtonList>
          <Restrict roles={["ADMIN", "SUPERADMIN", "MED"]}>
            <PageButton icon={faNotesMedical} route="/med-reports">
              Pacijenti
            </PageButton>
          </Restrict>

          <Restrict roles={["ENGINEER"]}>
            <PageButton icon={faNotesMedical} route="/med-reports">
              Čekaonica
            </PageButton>
          </Restrict>

          <Restrict roles={["ADMIN", "SUPERADMIN"]}>
            <PageButton icon={faClipboardUser} route="/unassigned-med-reports">
              Admin. asist.
            </PageButton>
          </Restrict>

          <Restrict roles={["ADMIN", "SUPERADMIN", "MED", "ENGINEER"]}>
            <PageButton
              iconElement={
                <>
                  {
                    /* <Badge color="primary" badgeContent={2} max={10}>
                    <button>
                      <MailIcon style={{ color: "#ffffff", fontSize: 20 }} />
                    </button>
                  </Badge> */
                  }
                  <MessageNotificationIcon />
                </>
              }
              route="/messages"
            >
              Obavijesti
            </PageButton>
          </Restrict>

          <Restrict roles={["SUPERADMIN", "MED", "ENGINEER"]}>
            <PageButton icon={faGraduationCap} route="/profile">
              Profil
            </PageButton>
          </Restrict>

          <Restrict roles={["SUPERADMIN"]}>
            <PageButton icon={faUser} route="/admins">
              Admin
            </PageButton>
          </Restrict>

          <Restrict roles={["SUPERADMIN", "ADMIN"]}>
            <PageButton icon={faUserDoctor} route="/users">
              Radiolozi
            </PageButton>
          </Restrict>

          <Restrict roles={["SUPERADMIN", "ADMIN"]}>
            <PageButton icon={faUserNurse} route="/engineers">
              ing.med.rad.
            </PageButton>
          </Restrict>

          <Restrict roles={["SUPERADMIN", "ADMIN"]}>
            <PageButton icon={faHospital} route="/hospitals">
              Bolnice
            </PageButton>
          </Restrict>

          <Restrict roles={["SUPERADMIN", "ADMIN"]}>
            <PageButton icon={faStethoscope} route="/examinations">
              Pregledi
            </PageButton>
          </Restrict>

          <Restrict roles={["SUPERADMIN", "ADMIN"]}>
            <PageButton icon={faBookMedical} route="/history">
              Povijest
            </PageButton>
          </Restrict>

          <Restrict roles={["SUPERADMIN", "ADMIN", "MED", "ENGINEER"]}>
            <PageButton icon={faBoxArchive} route="/archive">
              Arhiva
            </PageButton>
          </Restrict>

          {
            /* <Restrict roles={["SUPERADMIN", "MED", "ENGINEER"]}>
            <PageButton icon={faGear} route="/settings">
              Postavke
            </PageButton>
          </Restrict> */
          }

          <Restrict roles={["MED", "ENGINEER"]}>
            <PageButton icon={faBug} route="/malfunction-report">
              Tehnička podrška
            </PageButton>
          </Restrict>
        </PageButtonList>
        <Logout />
        <VersionNumber />
      </div>
    </React.Fragment>
  );
};
