import { Response } from "Models/Common";
import { deleteMessage } from "Models/Common/NotificationMessages";
import { toast } from "react-toastify";

export const useDelete = <T>(promise: () => Promise<Response<T>>, ...ids: string[]) => {
    const deleteItem = async (onExecuted?: () => void) => {
        if (!ids || ids.length <= 0) return;

        const deleteResponse = promise();
        const deleteResult = await toast.promise(deleteResponse, deleteMessage);
        if (!deleteResult.hasError) onExecuted?.();
    }

    return deleteItem;
}    