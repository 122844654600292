import React, { useEffect, useState } from "react";
import "./MedReportEngineerForm.scss";
import { useForm } from "react-hook-form";
import { Form } from "./MedReportEngineerForm.type";
import dayjs from "dayjs";
import { useAmbulances, useExaminations } from "Hooks/useLookup";
import {
  useMedReportEngineerFinish,
  usePostMedReport,
  usePutMedReportEngineer,
} from "./MedReportEngineerForm.hook";
import {
  Button,
  CheckboxForm,
  DropdownForm,
  ErrorLabel,
  FormMargin,
  FormPaper,
  Grid,
  InputForm,
  Label,
  Margin,
  Paper,
} from "Components";
import { DatePickerForm } from "Components/FormComponents/DatePickerForm/DatePickerForm";
import { useHospital } from "../MedReport.hooks";
import { TextAreaForm } from "Components/FormComponents/TextAreaForm/TextAreaForm";
import { MedReportFiles } from "../MedReportFiles/MedReportFiles";
import { MedReport } from "Models/Api/MedReport";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { m } from "framer-motion";
import { useRecoilState } from "recoil";
import { withEngineerGuideStep } from "Features/Guides";
import { BrowserView, isMobile, MobileView } from "react-device-detect";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

type Props = {
  medReport?: MedReport;
};

export const MedReportEngineerForm = ({ medReport }: Props) => {
  const {
    reset,
    control,
    watch,
    formState: { errors, isDirty },
    handleSubmit,
    setValue,
  } = useForm<Form>({
    defaultValues: {
      id: medReport?.id ?? "",
      contrast: medReport?.contrast ?? false,
      patientId: medReport?.patientId ?? "",
      remarks: medReport?.remarks ?? "",
      examinationIds: medReport?.examinations.map((e) => e.id) ?? [],
      ambulanceId: medReport?.ambulanceId ?? "",
      hospitalId: medReport?.hospitalId ?? "",
      firstName: medReport?.patient?.firstName ?? "",
      lastName: medReport?.patient?.lastName ?? "",
      year: medReport
        ? dayjs().year(medReport?.patient?.year ?? 1920)
        : dayjs(),
      hospitalExamination: medReport?.hospitalExamination ?? false,
      urgentExamination: medReport?.urgentExamination ?? false,
    },
  });

  const handleChangeExamination = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setExamination(event.target.value);
  };

  const navigate = useNavigate();
  const [submit, setSubmit] = useState(false);
  const [examination, setExamination] = useState("ambulance");
  const [engineerGuideStep, setEngineerGuideStep] = useRecoilState(
    withEngineerGuideStep,
  );
  const [ambulance] = useAmbulances();
  // const [hospital] = useHospital(watch(["hospitalId"])[0]);
  const [examinations] = useExaminations(false);
  const [responsePost, sendPost] = usePostMedReport(watch());
  const [responsePut, sendPut] = usePutMedReportEngineer(watch());
  const [responseFinished, sendFinished, onFinished] =
    useMedReportEngineerFinish(watch(["id"])[0]);
  const [hasFiles, setHasFiles] = useState(false);

  onFinished(() => {
    navigate("/med-reports");
  });

  useEffect(() => {
    const amb = ambulance.items.find(
      (x) => x.value === watch("ambulanceId"),
    )?.label;
    if (amb === "MMG") {
      const examinationLabel = "DOJKA";
      const examinationId = examinations.items.find(
        (x) => x.label === examinationLabel,
      )?.value;

      setValue("examinationIds", [examinationId ?? ""]);
    }
  }, [watch("ambulanceId")]);

  useEffect(() => {
    if (examination === "hospital") {
      setValue("hospitalExamination", true, { shouldDirty: true });
      setValue("urgentExamination", false, { shouldDirty: true });
    } else if (examination === "urgent") {
      setValue("urgentExamination", true, { shouldDirty: true });
      setValue("hospitalExamination", false, { shouldDirty: true });
    }
    else {
      setValue("urgentExamination", false, { shouldDirty: true });
      setValue("hospitalExamination", false, { shouldDirty: true });
    }
  }, [examination]);

  useEffect(() => {
    if (medReport?.urgentExamination) {
      setValue("urgentExamination", true, { shouldDirty: true });
      setExamination("urgent");
    } else if (medReport?.hospitalExamination) {
      setValue("hospitalExamination", true, { shouldDirty: true });
      setExamination("hospital");
    }
  }, []);

  return (
    <React.Fragment>
      <div className="med-report-engineer-form-container">
        <Margin>
          <FormPaper>
            <div id="form-inputs">
              <FormMargin>
                <Grid
                  gridTemplateColumns={isMobile ? "1fr 1fr" : "1fr 1fr 1fr"}
                >
                  <div>
                    <Label htmlFor="lastName">Prezime</Label>
                    <InputForm
                      control={control}
                      name="lastName"
                      placeholder="Prezime"
                      rules={{ required: true }}
                    />
                    <ErrorLabel
                      type={errors.lastName?.type}
                      required="Prezime je obavezno polje"
                    />
                  </div>

                  <div>
                    <Label htmlFor="firstName">Ime</Label>
                    <InputForm
                      control={control}
                      name="firstName"
                      placeholder="Ime"
                      rules={{
                        required: true,
                        // maxLength: 3,
                      }}
                    />
                    <ErrorLabel
                      type={errors.firstName?.type}
                      required="Ime je obavezno polje"
                    />
                  </div>

                  <BrowserView>
                    <div>
                      <Label htmlFor="year">Godina rođenja</Label>
                      <DatePickerForm
                        control={control}
                        name="year"
                        includeOnly={["year"]}
                        maxDate={dayjs().toDate()}
                      />
                      <ErrorLabel
                        type={errors.firstName?.type}
                        required="Godina rođenja je obavezno polje"
                      />
                    </div>
                  </BrowserView>
                </Grid>
              </FormMargin>

              <MobileView>
                <FormMargin>
                  <Label htmlFor="year">Godina rođenja</Label>
                  <DatePickerForm
                    control={control}
                    name="year"
                    includeOnly={["year"]}
                    maxDate={dayjs().toDate()}
                  />
                  <ErrorLabel
                    type={errors.firstName?.type}
                    required="Godina rođenja je obavezno polje"
                  />
                </FormMargin>
              </MobileView>

              <Grid gridTemplateColumns="1fr 1fr">
                <FormMargin>
                  <Label htmlFor="contrast">Kontrast</Label>
                  <DropdownForm
                    control={control}
                    name="contrast"
                    type="normal"
                    defaultValue={false}
                    options={[
                      { label: "NE", value: false },
                      { label: "DA", value: true },
                    ]}
                  />
                  <ErrorLabel type={errors.contrast?.type} />
                </FormMargin>

                <FormMargin>
                  <Label htmlFor="ambulanceId">Ambulanta</Label>
                  <DropdownForm
                    control={control}
                    name="ambulanceId"
                    type="normal"
                    defaultValue=""
                    options={ambulance.items}
                    rules={{ required: true }}
                  />
                  <ErrorLabel type={errors.ambulanceId?.type} />
                </FormMargin>
              </Grid>

              <FormMargin>
                <Label htmlFor="examinations">Pregled</Label>
                <DropdownForm
                  control={control}
                  name="examinationIds"
                  rules={{ required: true }}
                  type="tag"
                  options={examinations.items}
                />
                <ErrorLabel type={errors.examinationIds?.type} />
              </FormMargin>

              <FormMargin>
                <RadioGroup row onChange={handleChangeExamination}>
                  <FormControlLabel
                    value="ambulance"
                    control={<Radio checked={examination === "ambulance"} />}
                    label="Ambulantni"
                  />
                  <FormControlLabel
                    value="hospital"
                    control={<Radio checked={examination === "hospital"} />}
                    label="Bolnički"
                  />
                  <FormControlLabel
                    value="urgent"
                    control={<Radio checked={examination === "urgent"} />}
                    label="Žurni"
                  />
                </RadioGroup>

                {
                  /* <TextAreaForm
                  control={control}
                  placeholder="Obrazloženje"
                  name="examinationReason"
                /> */
                }
              </FormMargin>

              <FormMargin>
                <Label htmlFor="remarks">Napomena</Label>
                <TextAreaForm
                  control={control}
                  placeholder="Unesi napomenu"
                  name="remarks"
                />
              </FormMargin>

              <FormMargin>
                <MedReportFiles
                  medReportId={medReport
                    ? responsePut.model?.id ?? ""
                    : responsePost.model?.id ?? ""}
                  submit={submit}
                  setSubmit={setSubmit}
                  onUploadFinish={() =>
                    navigate(
                      `/med-reports/engineer-edit/${responsePost.model?.id}`,
                    )}
                  onHasFiles={(val) => setHasFiles(val)}
                />
              </FormMargin>
            </div>

            <Grid gridTemplateColumns="max-content max-content">
              {(!medReport || isDirty) && (
                <Button
                  bt="secondary"
                  id="save-patient"
                  onClick={() =>
                    handleSubmit(() => {
                      if (medReport) {
                        sendPut(() => {
                          setSubmit(true);
                          reset();
                        });
                      } else {
                        sendPost((x) => {
                          setSubmit(true);
                          if (!hasFiles) {
                            navigate(`/med-reports/engineer-edit/${x?.id}`);
                          }
                        });
                      }
                    })()}
                >
                  Spremi
                </Button>
              )}

              {medReport && !isDirty && (
                <Button
                  bt="primary"
                  id="send-patient"
                  onClick={() => sendFinished()}
                >
                  Pošalji
                </Button>
              )}
            </Grid>
          </FormPaper>
        </Margin>

        <Margin>
          <div className="med-report-engineer-form-paper">
            {medReport && (
              <Button
                bt="primary"
                id="new-patient"
                onClick={() => navigate("/med-reports/engineer-add")}
              >
                Novi pacijent
              </Button>
            )}
          </div>
        </Margin>
      </div>
    </React.Fragment>
  );
};
