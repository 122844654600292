import React, { useState, useEffect } from "react";
import { useAppVersion } from "./VersionNumber.hooks";
import "./VersionNumber.scss";

export const VersionNumber = () => {
  const appVersion = useAppVersion();

  return (
    <React.Fragment>
      <div className="version-number-container">
        <p>{appVersion} v</p>
      </div>
    </React.Fragment>
  );
};