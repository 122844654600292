import React, { useState, useEffect, useRef, forwardRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

import "./Navbar.scss";
import { useRecoilState } from "recoil";
import { withSidebarOpen } from "../Recoil/atom";
import { useOutsideAlerter } from "Hooks/useOutsideAlerter";

type Props = {};

type Ref = HTMLButtonElement;

export const Navbar = forwardRef<Ref, Props>(({}, ref) => {
  const [sidebarOpen, setSidebarOpen] = useRecoilState(withSidebarOpen);

  return (
    <React.Fragment>
      <div
        className="navbar-container"
        style={{
          boxShadow: !sidebarOpen ? "0px 0px 21px -5px rgba(0,0,0,0.75)" : "",
        }}
      >
        <button
          className="navbar-menu"
          onClick={() => {
            setSidebarOpen(!sidebarOpen);
          }}
          ref={ref}
        >
          <FontAwesomeIcon icon={faBars} />
        </button>
        <div className="navbar-logo-name">
          <div>POSEIDON</div>
        </div>
      </div>
    </React.Fragment>
  );
});
