import axios from "axios";
import { injectable } from "inversify";
import { Login, LoginResponse, RegisterAdmin, RegisterAdminResponse, RegisterEngineer, RegisterEngineerResponse } from "Models/Api/Auth";
import { RegisterUser } from "Models/Api/Auth/RegisterUser";
import { RegisterUserResponse } from "Models/Api/Auth/RegisterUserResponse";
import { Response } from "Models/Common";
import { IAuthorizationService } from "Services.Common/api";
import { BaseService } from "./BaseService";

@injectable()
export class AuthorizationService implements IAuthorizationService {
    baseUrl = "/api/authentication";

    async registerUserAsync(register: RegisterUser): Promise<Response<RegisterUserResponse>> {
        return (await axios.post<Response<RegisterUserResponse>>(this.baseUrl + "/register-user", register)).data
    }

    async registerAdminAsync(register: RegisterAdmin): Promise<Response<RegisterAdminResponse>> {
        return (await axios.post<Response<RegisterAdminResponse>>(this.baseUrl + "/register-admin", register)).data
    }

    async registerEngineerAsync(register: RegisterEngineer): Promise<Response<RegisterEngineerResponse>> {
        return (await axios.post<Response<RegisterEngineerResponse>>(this.baseUrl + "/register-engineer", register)).data
    }

    async loginAsync(login: Login): Promise<Response<LoginResponse>> {
        return (await axios.post<Response<LoginResponse>>(this.baseUrl + "/login", login)).data
    }
    
    async logoutAsync(): Promise<boolean> {
        return (await axios.get<boolean>(this.baseUrl + "/logout", {headers: {"Clear-Site-Data": '*'}})).data
    }

    async lockUserAsync(email: string): Promise<Response<boolean>> {
        return (await axios.post<Response<boolean>>(this.baseUrl + "/lock", { email })).data
    }

    async unlockUserAsync(email: string): Promise<Response<boolean>> {
        return (await axios.post<Response<boolean>>(this.baseUrl + "/unlock", { email })).data
    }

    async isLockedAsync(email: string): Promise<Response<boolean>> {
        return (await axios.get<Response<boolean>>(this.baseUrl + "/is-locked", { params: { email } })).data
    }
}
