import { Restrict } from "Components/Restrict/Restrict";
import { Role } from "Components/Restrict/Restrict.type";
import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useValidateRole } from "./AuthRoute.hook";
import "./AuthRoute.scss";

type Props = {
  children?: React.ReactNode;
  roles: Role[];
};

export const AuthRoute = ({ children, roles }: Props) => {
  const isValid = useValidateRole(roles);

  if (!isValid) return <></>;

  return (
    <React.Fragment>
      <Restrict roles={roles}>{children}</Restrict>
    </React.Fragment>
  );
};
