import React, { useState, useEffect } from "react";
import { useConfig } from "Hooks/useConfig";

import "./Dev.scss";

type Props = {
  children?: React.ReactNode;
};

export const Dev = ({ children }: Props) => {
  const config = useConfig();

  if (config.stage === "prod") return <></>;

  return <React.Fragment>{children}</React.Fragment>;
};
