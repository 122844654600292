export const TYPES = {
    //api

    IAmbulanceService: Symbol.for("IAmbulanceService"),
    IHospitalService: Symbol.for("IHospitalService"),
    IMedReportService: Symbol.for("IMedReportService"),
    IUserService: Symbol.for("IUserService"),
    IPatientService: Symbol.for("IPatientService"),
    IExaminationService: Symbol.for("IExaminationService"),
    IProfessionService: Symbol.for("IProfessionService"),
    IAuthorizationService: Symbol.for("IAuthorizationService"),
    IAdminService: Symbol.for("IAdminService"),
    IFileStoreageService: Symbol.for("IFileStoreageService"),
    IEngineerService: Symbol.for("IEngineerService"),
    IProfilePictureService: Symbol.for("IProfilePictureService"),
    IMessageService: Symbol.for("IMessageService"),
    IMalfunctionReportService: Symbol.for("IMalfunctionReportService"),

    //common
    IStoreageService: Symbol.for("IStoreageService"),
    IUtilityService: Symbol.for("IUtilityService"),

    //factory
    IPageFactory: Symbol.for("IPageFactory"),
    IResponseFactory: Symbol.for("IResponseFactory")
}