import { atom } from "recoil";

export const withAssistants = atom({
    key: 'withAssistants',
    default: ""
})

export const withGreedeAlgorithmMessage = atom({
    key: 'withGreedeAlgorithmMessage ',
    default: "Dodijeli pacijente asistentima na pohlepan način."
})

export const withHospitalPriorityMessage = atom({
    key: 'withHospitalPriorityMessage ',
    default: "Dodijeli pacijente asistentima na osnovu prioriteta bolnice."
})
