import React, { useState, useEffect } from "react";
import "./View.scss";

type Props = {
  children: JSX.Element | JSX.Element[];
};

export const View = ({ children }: Props) => {
  return (
    <React.Fragment>
      <div className="view-container">
        <div className="view-container-content">{children}</div>
      </div>
    </React.Fragment>
  );
};
