import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

import "./FloatingButton.scss";

type Props = {
  onClick?: () => void;
  route?: string;
};

export const FloatingButton = ({ onClick, route }: Props) => {
  return (
    <React.Fragment>
      <div className="floating-button-container">
        <Link to={route ?? "#"}>
          <button onClick={onClick}>
            <FontAwesomeIcon icon={faPlus} />
          </button>
        </Link>
      </div>
    </React.Fragment>
  );
};
