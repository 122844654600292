import {
  Button,
  ConfirmationModal,
  Dev,
  FormMargin,
  Grid,
  Input,
  MUIDropdown,
  Restrict,
  Table,
  TruncateText,
} from "Components";
import { DatePicker } from "Components/FormComponents/DatePicker/DatePicker";
import dayjs from "dayjs";
import {
  withMedReportsFilter,
  withMedReportsPage,
  withMedReportsSort,
} from "Features/MedReport/Recoil/atom";
import { useFilter } from "Hooks/useFilter";
import {
  useAmbulances,
  useGetPatients,
  useGetUsers,
  useHospitals,
} from "Hooks/useLookup";
import { MedReportFilter } from "Models/Api/MedReport";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { MedReportStatus } from "../MedReportStatus/MedReportStatus";
import {
  useAssignMedReports,
  useMedReportDelete,
  useMedReportTable,
  useUnasignedMedReportTable,
} from "./MedReportTable.hook";
import utc from "dayjs/plugin/utc";

import "./MedReportTable.scss";
import { AssistantChat } from "Features/Assistant/AssistantChat/AssistantChat";

type Props = {
  type: "medReport" | "medReportAssistant";
};

export const MedReportTable = ({ type }: Props) => {
  const [pageParams, setPageParams] = useRecoilState(withMedReportsPage);
  const [sortParams, setSortParams] = useRecoilState(withMedReportsSort);
  const [filter, setFilter, filterChanged, clearFilter] = useFilter({
    deadline: "inprocess",
    fromDateCreated: dayjs().utc().subtract(1, "month").startOf("date")
      .toDate(),
    toDateCreated: dayjs().utc().startOf("date").add(1, "day").toDate(),
    status: "notreturned",
    patientId: {},
    hospitalId: {},
    userId: {},
    ambulanceId: {},
  } as MedReportFilter);

  const [id, setId] = useState<string>("");
  const [modalOpen, setModalOpen] = useState(false);
  //TODO: Remove later
  const [isAssigned, setIsAssigned] = useState<boolean>(false);

  const deleteItem = useMedReportDelete(id);

  const navigate = useNavigate();
  const [users] = useGetUsers();
  const [hospitals] = useHospitals();
  const [ambulance] = useAmbulances();

  const [medReportsTable, fetchData, isLoaded] = useMedReportTable(
    pageParams,
    sortParams,
    filter,
    type,
  );

  const [unassignedTable, fetchUnassignedData, isUnassignedLoaded] =
    useUnasignedMedReportTable(pageParams, filter, "medReportUnassigned");

  //TODO: Remove later
  const [assigneReports, fetchAssignedData] = useAssignMedReports();

  //TODO: Remove later
  useEffect(() => {
    fetchUnassignedData();
    setIsAssigned(true);
  }, [assigneReports]);

  return (
    <React.Fragment>
     {type == "medReportAssistant" && (
        <FormMargin>
          <Button
            bt="primary"
            onClick={() => {
              fetchAssignedData();
              setIsAssigned(false);
            }}
          >
            Dodijeli radiologe
          </Button>
        </FormMargin>
      )}
      <Table
        title="Popis pacijenata"
        data={type == "medReport" ? medReportsTable : unassignedTable}
        columns={[
          { name: "patient", label: "Pacijent" },
          { name: "dateCreated", label: "Datum pregleda" },
          { name: "doctor", label: "Radiolog" },
          { name: "ambulance", label: "Ambulanta" },
          {
            element: (x) => {
              return (
                <TruncateText text={x?.examinations ?? ""} maxLength={15} />
              );
            },
            label: "Pregled",
          },
          { name: "regionNum", label: "Broj regija" },
          { name: "contrast", label: "Kontrast" },
          { name: "hospital", label: "Bolnica" },
          // { name: "remarks", label: "Napomena" },
          { name: "deadline", label: "Rok" },
          {
            element: (x) => (
              <MedReportStatus
                report={x.report}
                returned={x.returned}
                deadline={type == "medReport"
                  ? x.deadlineValue
                  : dayjs("0001-01-01T00:00:00.000Z").toDate()}
                comment={x.comment}
              />
            ),
            label: "Status",
          },
        ]}
        fill
        //TODO: Remove isAssigne:d
        isLoaded={type == "medReport" ? isLoaded : (isUnassignedLoaded && isAssigned)}
        pageState={withMedReportsPage}
        route="/med-reports/add"
        showActions={["EDIT", "DELETE", "VIEW"]}
        onEdit={(x) => {
          // if (x?.userId == null) return toast.error("Morate dodijeliti pacijenta prije uređivanja!");
          return navigate("/med-reports/edit/" + x?.id);
        }}
        onDelete={(x) => {
          setId(x?.id ?? "");
          setModalOpen(true);
        }}
        onView={(x) => {
          if (x?.userId == null) navigate(`/med-reports/assign/${x?.id}`);
          else navigate(`/med-reports/finish/${x?.id}`);
        }}
        onRefresh={fetchData}
        onDoubleClick={(x) => {
          if (x?.userId == null) navigate(`/med-reports/assign/${x?.id}`);
          else if (type === "medReportAssistant") {navigate(
              `/unassigned-med-reports/assign/${x?.id}?userId=${x?.userId}`,
            );} else navigate(`/med-reports/finish/${x?.id}`);
        }}
        filterChanged={filterChanged}
        onFilterClear={clearFilter}
      >
        <FormMargin>
          <Input
            placeholder="Pacijent"
            value={filter.patient}
            onChange={(x) => setFilter({ patient: x.target.value })}
          />
        </FormMargin>

        <FormMargin>
          <MUIDropdown
            type="editable"
            options={ambulance.items}
            value={filter.ambulanceId}
            onSelect={(x) => setFilter({ ambulanceId: x })}
            label="Ambulanta"
          />
        </FormMargin>

        <FormMargin>
          <MUIDropdown
            type="editable"
            options={hospitals.items}
            value={filter.hospitalId}
            onSelect={(x) => setFilter({ hospitalId: x })}
            label="Bolnice"
          />
        </FormMargin>

        <FormMargin>
          <MUIDropdown
            type="editable"
            options={users.items}
            value={filter.userId}
            onSelect={(x) => setFilter({ userId: x })}
            label="Radiolog"
          />
        </FormMargin>

        <FormMargin>
          <MUIDropdown
            disableNotSelected
            options={[
              { value: "notreturned", label: "Sve" },
              { value: "inprogress", label: "U tijeku" },
              { value: "commented", label: "Dopuna u tijeku" },
            ]}
            value={filter.status}
            onSelect={(x) => setFilter({ status: x })}
            label="Status"
          />
        </FormMargin>

        <FormMargin>
          <Grid gridTemplateColumns="1fr 1fr">
            <DatePicker
              label="od"
              value={filter.fromDateCreated}
              onChange={(x) =>
                setFilter({ fromDateCreated: dayjs(x).toDate() })}
              maxDate={filter.toDateCreated}
            />
            <DatePicker
              label="do"
              value={filter.toDateCreated}
              onChange={(x) => setFilter({ toDateCreated: dayjs(x).toDate() })}
              minDate={filter.fromDateCreated}
            />
          </Grid>
        </FormMargin>

        {
          /* <MUIDropdown
          options={[
            { value: true, label: "Zatražena izmjena" },
            { value: false, label: "Nema izmjene" },
          ]}
          defaultValue={false}
          value={filter.commented}
          onSelect={(x) => setFilter({ commented: x })}
          label="Izmjena"
        /> */
        }
      </Table>
      <Dev>
        <Restrict roles={["ADMIN", "SUPERADMIN"]}>
          <AssistantChat
            onMessageReceived={(message) => {
              //WARNING: This will fetch new data on every message received. Refactor later to fetch only when algorithm is applaied.
              if (message) fetchUnassignedData();
            }}
          />
        </Restrict>
      </Dev>

      <ConfirmationModal
        open={modalOpen}
        title="Obriši nalaz"
        description="Ovom radnjom nalaz će biti uklonjen iz tablice"
        onConfirm={() => {
          deleteItem(type == "medReport" ? fetchData : fetchUnassignedData);
          setModalOpen(false);
        }}
        onDecline={() => {
          setModalOpen(false);
        }}
      />
    </React.Fragment>
  );
};
