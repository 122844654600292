import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { MedReportAssigmentPost } from "Models/Api/MedReport";
import { useGetUsers } from "Hooks/useLookup";
import dayjs from "dayjs";
import { useAssignPost } from "./MedReportAssign.hook";
import {
  Button,
  DropdownForm,
  ErrorLabel,
  FormMargin,
  FormPaper,
  Label,
  Paper,
} from "Components";
import { useHospital, useMedReport } from "../MedReport.hooks";
import { Form } from "./MedReportAssign.type";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AssistantChat } from "Features/Assistant/AssistantChat/AssistantChat";
import "./MedReportAssign.scss";

type Props = {
  id: string;
  hospitalId: string;
};

export const MedReportAssign = ({ id, hospitalId }: Props) => {
  const {
    reset,
    control,
    watch,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm<Form>({
    defaultValues: {
      id,
      userId: undefined,
      deadline: 0,
    },
  });

  const navigate = useNavigate();
  const deadline = watch(["deadline"])[0];

  const [users] = useGetUsers(
    hospitalId,
    dayjs().endOf("date").add(deadline, "day").toDate(),
  );
  const [hospital] = useHospital(hospitalId);
  const [response, send, onFinish] = useAssignPost(watch());
  const [searchParams, setSearchParams] = useSearchParams();
  const [medReport, fetch] = useMedReport(id ?? "");
  const [userId, setUserId] = useState<string | undefined>(undefined);

  const userIdParam = searchParams.get("userId");

  useEffect(() => {
    if (userIdParam) {
      setUserId(userIdParam);
    }
  }, [userIdParam]);

  useEffect(() => {
    if (medReport?.model) {
      const { urgentExamination, hospitalExamination, hospital } =
        medReport.model;

      if (urgentExamination) {
        setValue("deadline", hospital.z);
        setValue("userId", userId ?? "");
      } else if (hospitalExamination) {
        setValue("deadline", hospital.b);
        setValue("userId", userId ?? "");
      } else {
        setValue("deadline", hospital.a);
        setValue("userId", userId ?? "");
      }
    }
  }, [medReport?.model, userId]);

  onFinish(() => {
    if (userId) {
      navigate("/unassigned-med-reports");
    } else {
      navigate("/med-reports");
    }
  });

  return (
    <React.Fragment>
      <div className="med-report-assign-container">
        <FormPaper>
          <FormMargin>
            <Label htmlFor="deadline">Rok</Label>
            {
              /* <DatePickerForm
                control={control}
                name="deadline"
                minDate={dayjs()}
              /> */
            }

            <DropdownForm
              control={control}
              name="deadline"
              type="normal"
              disabled={hospital.model === undefined}
              defaultValue={0}
              options={hospital.model
                ? [
                  {
                    value: hospital.model.a,
                    label: `A (${hospital.model?.a}d)`,
                  },
                  {
                    value: hospital.model.b,
                    label: `B (${hospital.model?.b}d)`,
                  },
                  {
                    value: hospital.model.db,
                    label: `DB (${hospital.model?.db}d)`,
                  },
                  {
                    value: 1,
                    label: `Ž (1d)`,
                  },
                ]
                : []}
              rules={{ required: true, min: 1 }}
            />
            <ErrorLabel type={errors.deadline?.type} min="Polje je obavezno" />
          </FormMargin>
          <FormMargin>
            <Label htmlFor="user">Radiolog</Label>
            <DropdownForm
              control={control}
              name="userId"
              rules={{ required: true }}
              type="editable"
              disabled={hospital.model === undefined || deadline === 0}
              options={users.items}
            />
            <ErrorLabel type={errors.userId?.type} />
          </FormMargin>

          <Button bt="primary" onClick={handleSubmit(send)}>
            Pošalji
          </Button>
        </FormPaper>
      </div>
    </React.Fragment>
  );
};
